import { useState, useEffect } from "react";
import { setKey } from "react-geocode";

import LeftSectionContainer from "../container/LeftSectionContainer";
import { Color, ColorV2, Input } from "@adoptaunabuelo/react-components";
import { Search } from "lucide-react";
import { User, UserScheme } from "client-v2";
import { State } from "../../redux";
import { useSelector } from "react-redux";

const LocationStep = (props: LocationProps) => {
	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const colors = {
		buttonBackground: ColorV2.surface.primaryHard,
		buttonColor: "white",
		animation: ["#00315C", "#005AA8"],
	};

	const [inputError, setInputError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState<LocationObjProps | undefined>(
		undefined
	);

	useEffect(() => {
		setKey(googleAPIKey);
	}, []);

	const onNextClick = () => {
		if (location) {
			setLoading(true);
			User.set(props.user.objectId, {
				...location,
			})
				.then((result) => {
					setLoading(false);
					props.onFinish({ data: result.data });
				})
				.catch((error) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		} else {
			setInputError(true);
		}
	};

	const onLocationChange = async (item: LocationObjProps) => {
		setInputError(false);
		setLocation(item);
	};

	return (
		<LeftSectionContainer
			title="Tu ubicación"
			subtitle="Conocer la zona en la que vives nos dará más pistas para realizar el mejor emparejamiento"
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				loading: loading,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<Input
				placeholder="Dirección aproximada"
				containerStyle={{ marginBottom: 0 }}
				type="location"
				design="secondary"
				googleAPIKey={googleAPIKey}
				error={inputError ? "Añade una dirección válida" : undefined}
				icon={
					<Search
						height={20}
						width={20}
						color={Color.text.high}
						style={{ marginRight: 8 }}
					/>
				}
				onLocationChange={onLocationChange}
			/>
		</LeftSectionContainer>
	);
};
export default LocationStep;
export interface LocationProps {
	user: UserScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}

interface LocationObjProps {
	address?: string;
	sortAddress?: string;
	route?: string;
	routeNumber?: string;
	routeInfo?: string;
	city?: string;
	province?: string;
	zipCode?: string;
	country?: string;
	location?: google.maps.LatLngLiteral;
	timeZone?: string;
}
