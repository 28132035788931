import { useState, useRef, useEffect } from "react";
import {
	ErrorScheme,
	ProductScheme,
	Subscription,
	SubscriptionScheme,
} from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import media from "styled-media-query";

import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Success from "../donation/steps/SuccessStep";
import SuccessRight from "../../components/section/SuccessCrossSell";
import Price from "../../components/section/donation/Price";
import PriceLeft from "../../components/section/donation/PriceInfo";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import { ChevronDown } from "lucide-react";
import SubscriptionSection from "../../components/section/donation/Subscription";
import { Modal, Text } from "@adoptaunabuelo/react-components";
import moment from "moment";

const ArrowAnimationView = styled.div`
	display: none;
	position: sticky;
	bottom: 0px;
	right: 0px;
	left: 0px;
	width: 100%;
	padding: 52px 0px 16px;
	background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
	align-items: flex-end;
	justify-content: center;
	${media.lessThan("medium")`
		display: flex;
    `}
`;

const Checkout = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError, setShowLoading } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "sb";
	const [product, setProduct] = useState<ProductScheme | undefined>(
		props.product
	);
	const [subscription, setSubscription] = useState<
		SubscriptionScheme | undefined
	>();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (currentUser) {
			setShowLoading(true);
			Subscription.get({
				userId: currentUser.objectId,
				descending: "createdAt",
				include: ["Product"],
				limit: 1,
				page: 0,
			})
				.then((result) => {
					if (result.data.length > 0) {
						setSubscription(result.data[0]);
					} else {
						wizard.current?.goNext();
					}
					setShowLoading(false);
				})
				.catch((e: ErrorScheme) => {
					setShowLoading(false);
					wizard.current?.goNext();
				});
		} else {
			wizard.current?.goNext();
		}
	}, [currentUser]);

	const onBackClick = (step: number) => {
		if (step === 6) {
			wizard.current?.goBack(1, step);
		}
	};

	const unpauseSubscription = () => {
		if (subscription && currentUser) {
			setLoading(true);
			Subscription.unpause({
				userId: currentUser.objectId,
			})
				.then((result) => {
					setLoading(false);
					setShowModal(false);
					wizard.current?.goNext(3);
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					setShowModal(false);
					setShowError({ show: true, message: e.message });
				});
		}
	};

	return (
		<>
			<Modal
				isVisible={showModal}
				title="Vas a reactivar tu donación"
				buttonProps={{
					children: "Estoy de acuerdo",
					loading: loading,
					onClick: unpauseSubscription,
				}}
				onClose={() => setShowModal(false)}
			>
				<Text type="p" style={{ marginBottom: 24 }}>
					{`Tu aportación a la Fundación Adopta Un Abuelo se volverá a activar y el siguiente recibo se emitirá el próximo día ${moment(
						new Date()
					).format("DD/MM/YYYY")}`}
				</Text>
			</Modal>
			<WizardContainer
				ref={wizard}
				onBackClick={onBackClick}
				views={[
					//Subscription view
					{
						leftView: <></>,
						centralView: subscription && (
							<SubscriptionSection
								subscription={subscription}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
										if (subscription.state === "cancel")
											wizard.current?.goNext();
										else if (
											subscription.state === "paused"
										)
											setShowModal(true);
										else wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: <></>,
					},
					//Select price view
					{
						containerStyle: { overflowY: "scroll" },
						leftView: (
							<Price
								options={["monthly", "annually"]}
								animated={true}
								view={view}
								defaultProduct={
									props.product
										? props.product
										: subscription?.Product
								}
								defaultGrowthbook={{
									title: "Ningún mayor debería sentirse solo. ¿Nos ayudas?",
									title_en:
										"No grandpa should feel alone. Can you help us?",
									subtitle:
										"Estás regalando {{data}} horas de compañía a una persona mayor",
									subtitle_en:
										"You are giving away {{data}} hours of company to a grandpa",
									type_to_eur: 0.602,
								}}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightViewStyle: { overflowY: "scroll" },
						rightView: isMobile ? null : <PriceLeft />,
					},
					//Select payment method
					{
						leftView: <></>,
						centralView:
							product && currentUser ? (
								<PaymentMethod
									animated={true}
									product={product}
									subscription={
										subscription?.state === "active"
											? subscription
											: undefined
									}
									user={currentUser}
									view={view}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											wizard.current?.goNext();
										}
									}}
									onProductChange={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
										}
									}}
								/>
							) : null,
						rightView: <></>,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						leftView: currentUser ? (
							<>
								<Success animated={true} user={currentUser}>
									{isMobile ? (
										<SuccessRight animated={true} />
									) : null}
								</Success>
								<ArrowAnimationView>
									<ChevronDown />
								</ArrowAnimationView>
							</>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight animated={true} />
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {
	adminId?: string;
	product?: ProductScheme;
}
