import styled from "styled-components";
import React, {
	ComponentPropsWithoutRef,
	CSSProperties,
	ReactNode,
	useEffect,
	useState,
} from "react";
import { Text, Button } from "@adoptaunabuelo/react-components";
import media from "styled-media-query";
import ReCaptchaButton from "../button/ReCaptchaButton";

const Container = styled.div<{ $hidden: boolean; $animated?: boolean }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	opacity: ${(props) => (!props.$animated ? 1 : props.$hidden ? 0 : 1)};
	transition: ${(props) =>
		!props.$animated
			? "unset"
			: "opacity 0.6s ease-in, transform 0.6s ease-out"};
	transform: ${(props) =>
		!props.$animated
			? "unset"
			: props.$hidden
			? "translateY(20px)"
			: "translateY(0px)"};
	padding: 32px 0px 0px;
	${media.lessThan("medium")`
        padding: 8px 24px 16px;
	`}
`;

const Title = styled(Text)`
	margin-top: 32px;
	${media.lessThan("medium")`
        margin-top: 8px;
	`}
`;

const BottomButtonView = styled.div`
	display: flex;
	align-items: flex-end;
	width: 100%;
	${media.lessThan("medium")`
		background-color: transparent;
		width: 100%;
	`}
`;

const BottomButton = styled(Button)`
	${media.lessThan("medium")`
		width: 100%;
	`}
`;

const ChildrenContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 32px 0px 48px;
	${media.lessThan("medium")`
		flex: 1;
	`}
`;

const SectionContainer = ({
	children,
	title,
	subtitle,
	buttonProps,
	secondaryButtonProps,
	...props
}: SectionContainerProps) => {
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		//Animation movement activation
		const timer = setTimeout(
			() => setShowContent(true),
			props.animationDelay ? props.animationDelay : 0
		);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Container $hidden={!showContent} $animated={props.animated} {...props}>
			{title && (
				<Title type="h3" weight="semibold">
					{title}
				</Title>
			)}
			{subtitle && (
				<Text type="p2" style={{ marginTop: 8 }}>
					{subtitle}
				</Text>
			)}
			<ChildrenContainer {...props.childrenProps}>
				{children}
			</ChildrenContainer>
			{buttonProps &&
				(buttonProps.action === "login" ? (
					<ReCaptchaButton action="login" {...buttonProps} />
				) : (
					<BottomButtonView
						style={{
							backgroundColor: buttonProps.backgroundColor,
							marginBottom: buttonProps.marginBottom,
							position: buttonProps.sticky ? "sticky" : "static",
							bottom: 0,
						}}
					>
						{buttonProps.LeftView}
						<BottomButton
							{...buttonProps}
							style={buttonProps.style}
						/>
					</BottomButtonView>
				))}
			{secondaryButtonProps && (
				<BottomButtonView
					style={{
						backgroundColor: secondaryButtonProps.backgroundColor,
						marginBottom: secondaryButtonProps.marginBottom,
					}}
				>
					{secondaryButtonProps.LeftView}
					<BottomButton
						design={"secondary"}
						{...secondaryButtonProps}
						style={{
							border: "none",
							marginTop: 0,
							...secondaryButtonProps.style,
						}}
					/>
				</BottomButtonView>
			)}
		</Container>
	);
};
export default SectionContainer;
export interface SectionContainerProps extends ComponentPropsWithoutRef<"div"> {
	title?: string;
	subtitle?: string;
	animated?: boolean;
	childrenProps?: ComponentPropsWithoutRef<"div">;
	buttonProps?: SectionContainerButtonProps;
	secondaryButtonProps?: SectionContainerButtonProps;
	animationDelay?: number;
}
export interface SectionContainerButtonProps {
	action?: "login";
	children: React.ReactNode;
	loading?: boolean;
	style?: CSSProperties;
	LeftView?: ReactNode;
	success?: boolean;
	disabled?: boolean;
	backgroundColor?: string;
	marginBottom?: string;
	sticky?: boolean;
	onSuccess?: () => void;
	onClick?: (token?: any) => void;
}
