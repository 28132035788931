import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import media from "styled-media-query";
import { ChallengeScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import ResumeStyled from "../../../components/container/ResumeContainer";
import {
	Text,
	Color,
	ProgressBar,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { Check, Clock, Flower, Users2 } from "lucide-react";

const screenSize2: any = "1024px";

const Section = styled.div`
	padding: 24px;
	${media.lessThan(screenSize2)`
        padding: 20px;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const IconCheckView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 60px;
	background-color: white;
	padding: 2px 0px;
	width: fit-content;
	height: fit-content;
	margin-bottom: 16px;
`;
const IconCheck = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	border-radius: 20px;
	background-color: ${ColorV2.surface.green};
	margin-right: 6px;
`;
const Image = styled.img`
	height: 220px;
	object-fit: cover;
`;

const Resume = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const [pastDate, setPastDate] = useState(false);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		//Check if past date
		const diff = moment(props.challenge.endDate.iso).diff(moment(), "days");
		if (diff <= 0) setPastDate(true);

		//Check if goal completed
		const restAmount = props.challenge.goal - props.challenge.amount;
		if (restAmount <= 0) setCompleted(true);
	}, [props.challenge]);

	const calculateAmount = (amount: number): string => {
		const tempAmount =
			amount / (appLocation ? appLocation.currency.conversion : 1);
		const tempCurrencyName = appLocation
			? appLocation.currency.name
			: "eur";
		const tempLanguage = appLocation ? appLocation.language : "es-ES";

		return new Intl.NumberFormat(tempLanguage, {
			style: "currency",
			currency: tempCurrencyName,
			maximumFractionDigits: 0,
		}).format(tempAmount);
	};

	return (
		<ResumeStyled
			style={{ flexDirection: "column", padding: 0, overflow: "hidden" }}
		>
			<Image src={props.challenge.image && props.challenge.image.url} />
			<Section>
				{completed ? (
					<IconCheckView>
						<IconCheck>
							<Check
								height={18}
								width={18}
								strokeWidth={2}
								color={"white"}
							/>
						</IconCheck>
						<Text type="p2" style={{ color: ColorV2.text.green }}>
							¡
							{props.challenge.type === "signature"
								? "Objetivo"
								: "Sueño"}{" "}
							cumplido!
						</Text>
					</IconCheckView>
				) : null}
				<Text type="h6" weight="medium">
					{props.challenge.title}
				</Text>
				<ProgressBar
					style={{ height: 8, marginBottom: 8, marginTop: 16 }}
					progress={[
						{
							value:
								(props.challenge.amount /
									props.challenge.goal) *
								100,
							color: completed
								? Color.status.color.success
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.text.primary,
						},
						{
							value: completed
								? 0
								: props.amount
								? (props.amount / props.challenge.goal) * 100
								: 0,
							color:
								props.challenge.type === "rose"
									? ColorV2.surface.redMedium
									: Color.text.primary,
						},
					]}
					animationTime={0.3}
					animationDelay={0.5}
				/>
				<Row style={{ alignItems: "flex-end", marginBottom: 24 }}>
					<Text
						type="h5"
						weight="medium"
						style={{
							color: completed
								? Color.status.color.success
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.text.primary,
						}}
					>
						{props.challenge.type === "signature"
							? props.challenge.amount
							: calculateAmount(props.challenge.amount)}
					</Text>
					<Text
						type="p2"
						style={{
							marginBottom: 1,
							marginLeft: 6,
							color: Color.text.high,
						}}
					>
						de{" "}
						{props.challenge.type === "signature"
							? props.challenge.goal + " firmas"
							: calculateAmount(props.challenge.goal)}
					</Text>
				</Row>
				<Col>
					{props.challenge.type === "rose" ? (
						<Row>
							<Users2
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text
								type="p"
								style={{
									color: ColorV2.text.neutralMedium,
									marginTop: 1,
								}}
							>
								<span
									style={{
										fontWeight: 600,
										color: ColorV2.text.neutralHard,
									}}
								>
									{props.challenge.supporters.toLocaleString() +
										" personas "}
								</span>
								han colaborado enviando una rosa
							</Text>
						</Row>
					) : (
						props.challenge.type !== "signature" && (
							<Row>
								<Users2
									style={{
										marginRight: 12,
										minHeight: 24,
										minWidth: 24,
									}}
									height={24}
									width={24}
									color={ColorV2.text.neutralHard}
								/>
								<Text
									type="p"
									style={{
										color: ColorV2.text.neutralMedium,
										marginTop: 1,
									}}
								>
									<span
										style={{
											fontWeight: 600,
											color: ColorV2.text.neutralHard,
										}}
									>
										{props.challenge.supporters}{" "}
										colaboradores
									</span>
									{
										" han participado para hacer este sueño realidad"
									}
								</Text>
							</Row>
						)
					)}
					{props.challenge.type === "rose" ? (
						<Row style={{ marginTop: 8 }}>
							<Flower
								style={{
									marginRight: 12,
									minHeight: 24,
									minWidth: 24,
								}}
								height={24}
								width={24}
								color={ColorV2.text.neutralHard}
							/>
							<Text type="p" style={{ marginTop: 1 }}>
								Enviar una rosa tiene un coste de 5€
							</Text>
						</Row>
					) : (
						!completed &&
						!pastDate && (
							<Row style={{ marginTop: 8 }}>
								<Clock
									style={{
										marginRight: 12,
										minHeight: 24,
										minWidth: 24,
									}}
									height={24}
									width={24}
									color={ColorV2.text.neutralHard}
								/>
								<Text
									type="p"
									style={{
										color: ColorV2.text.neutralMedium,
										marginTop: 1,
									}}
								>
									<span
										style={{
											fontWeight: 600,
											color: ColorV2.text.neutralHard,
										}}
									>
										{moment(
											props.challenge.endDate.iso
										).diff(moment(), "days") +
											"  días restantes "}
									</span>
									{props.challenge.type === "signature"
										? "para cerrar la campaña"
										: "para cumplir el sueño"}
								</Text>
							</Row>
						)
					)}
				</Col>
			</Section>
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	challenge: ChallengeScheme;
	amount?: number;
	onClick?: () => void;
}
