import styled from "styled-components";
import RightSectionContainer from "../../../components/container/RightSectionContainer";

const MockupImage = styled.img`
	width: 400px;
`;

const Success = (props: Props) => {
	return (
		<RightSectionContainer animated={props.animated}>
			<MockupImage
				src={require("../../../assets/images/mockup/register.webp")}
			/>
		</RightSectionContainer>
	);
};
export default Success;
export interface Props {
	animated?: boolean;
}
