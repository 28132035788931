import { useEffect, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import moment from "moment";
import { ChallengeScheme } from "client-v2";

import PriceBreakdownCell from "../../../components/cell/PriceBreakdownCell";
import {
	Text,
	Color,
	ProgressBar,
	FAQs,
	ColorV2,
	Label,
} from "@adoptaunabuelo/react-components";
import {
	Clock,
	Check,
	Users2,
	Flower,
	PartyPopper,
	Calendar,
} from "lucide-react";
import ReliefModal from "../../../components/modal/ReliefModal";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
	${media.lessThan("medium")`
        margin-top: 36px;
    `}
`;
const MobileSection = styled.div`
	display: none;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const DateView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 50px;
	min-width: 48px;
	border-radius: 6px;
`;
const Line = styled.div`
	display: flex;
	width: 1px;
	height: 100%;
	min-height: 20px;
	border-left: 2px solid ${Color.line.soft};
`;
const Image = styled.img`
	height: 225px;
	width: 400px;
	border-radius: 6px;
	margin-bottom: 12px;
	object-fit: cover;
	${media.lessThan("medium")`
        width: 100%;
    `}
`;
const SupporterImage = styled.img`
	height: 40px;
	width: 40px;
	min-height: 40px;
	min-width: 40px;
	border-radius: 40px;
	margin-right: 16px;
	object-fit: cover;
`;
const IconCheckView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 60px;
	background-color: white;
	padding: 2px 0px;
	width: fit-content;
	height: fit-content;
	gap: 12px;
`;
const IconCheck = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	border-radius: 48px;
	background-color: ${ColorV2.surface.background};
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Numbers = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${ColorV2.surface.neutralSoft};
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
`;
const SpanLink = styled.span`
	cursor: pointer;
	text-decoration: underline;
`;

const Info = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const [pastDate, setPastDate] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [showReliefModal, setShowReliefModal] = useState(false);

	useEffect(() => {
		//Check if past date
		const diff = moment(props.challenge.endDate.iso).diff(moment(), "days");
		if (diff <= 0) setPastDate(true);

		//Check if goal completed
		const restAmount = props.challenge.goal - props.challenge.amount;
		if (restAmount <= 0) setCompleted(true);
	}, [props.challenge]);

	const calculateAmount = (amount: number): string => {
		const tempAmount =
			amount / (appLocation ? appLocation.currency.conversion : 1);
		const tempCurrencyName = appLocation
			? appLocation.currency.name
			: "eur";
		const tempLanguage = appLocation ? appLocation.language : "es-ES";

		return new Intl.NumberFormat(tempLanguage, {
			style: "currency",
			currency: tempCurrencyName,
			maximumFractionDigits: 0,
		}).format(tempAmount);
	};

	const renderTimelineCell = (item: any, index: number) => (
		<Cell key={"timeline-cell-" + index}>
			<Column style={{ marginRight: 16, alignItems: "center" }}>
				<DateView
					style={{
						backgroundColor: item.color
							? item.color
							: ColorV2.surface.background,
					}}
				>
					<Text
						type="c1"
						style={{
							color: item.textColor
								? item.textColor
								: Color.text.high,
						}}
					>
						{moment(item.createdAt).format("MMM")}
					</Text>
					<Text
						type="h6"
						style={{
							color: item.textColor
								? item.textColor
								: Color.text.high,
						}}
					>
						{moment(item.createdAt).format("DD")}
					</Text>
				</DateView>
				{!(index + 1 === props.challenge.timeline.length) && <Line />}
			</Column>
			<Column style={{ paddingBottom: 26 }}>
				{item.image && <Image src={item.image} />}
				<Text type="h6" weight="medium" style={{ marginTop: 4 }}>
					{item.title}
				</Text>
				{item.subtitle && (
					<Text type="p" style={{ marginTop: 4 }}>
						{item.subtitle}
					</Text>
				)}
			</Column>
		</Cell>
	);

	return (
		<Container>
			<ReliefModal
				isVisible={showReliefModal}
				price={50}
				charge={"one"}
				onClose={() => setShowReliefModal(false)}
			/>
			<MobileSection style={{ marginTop: 24 }}>
				<ProgressBar
					style={{ height: 8, marginBottom: 12 }}
					progress={[
						{
							value:
								(props.challenge.amount /
									props.challenge.goal) *
								100,
							color: completed
								? Color.status.color.success
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.background.primary,
						},
					]}
					animationTime={0.3}
					animationDelay={0.5}
				/>
				<Row
					style={{
						justifyContent: "space-between",
						marginBottom: 24,
					}}
				>
					<Text
						type="p2"
						style={{
							color: completed
								? ColorV2.text.green
								: props.challenge.type === "rose"
								? ColorV2.surface.redMedium
								: Color.text.primary,
						}}
					>
						<Text
							type="h5"
							style={{
								color: completed
									? ColorV2.text.green
									: props.challenge.type === "rose"
									? ColorV2.surface.redMedium
									: Color.text.primary,
							}}
							weight="medium"
						>
							{props.challenge.type === "signature"
								? props.challenge.amount.toLocaleString()
								: calculateAmount(props.challenge.amount)}
						</Text>
						{props.challenge.type === "signature"
							? "Firmas"
							: "Recaudados"}
					</Text>
					<Text
						type="p2"
						style={{ color: Color.text.high, textAlign: "right" }}
					>
						<Text
							type="h5"
							style={{ color: Color.text.high }}
							weight="medium"
						>
							{props.challenge.type === "signature"
								? props.challenge.goal.toLocaleString()
								: calculateAmount(props.challenge.goal)}
						</Text>
						Objetivo
					</Text>
				</Row>
			</MobileSection>
			<MobileSection
				style={{
					margin: 0,
					padding: "24px 0px",
					borderTop: "1px solid " + ColorV2.border.neutralSoft,
					borderBottom: "1px solid " + ColorV2.border.neutralSoft,
				}}
			>
				{completed ? (
					<IconCheckView style={{ marginBottom: 12 }}>
						<IconCheck
							style={{
								backgroundColor: ColorV2.surface.greenSoft,
							}}
						>
							<PartyPopper
								height={24}
								width={24}
								strokeWidth={2}
								color={ColorV2.text.green}
							/>
						</IconCheck>
						<Column style={{ flex: 1 }}>
							<Text type="p" weight="medium">
								{props.challenge.type === "signature" ||
								props.challenge.type === "rose"
									? "¡Objetivo cumplido!"
									: "¡Sueño cumplido!"}
							</Text>
							<Text
								type="p2"
								style={{ color: ColorV2.text.neutralMedium }}
							>
								{props.challenge.type === "signature" ||
								props.challenge.type === "rose"
									? "Pero aun puedes seguir ayudando."
									: "Pero aun puedes ayudarnos a cumplir los sueños de otros abuelos."}
							</Text>
						</Column>
					</IconCheckView>
				) : null}
				{props.challenge.type === "rose" ? (
					<Row>
						<Users2
							style={{
								marginRight: 12,
								minHeight: 24,
								minWidth: 24,
							}}
							height={24}
							width={24}
							color={ColorV2.text.neutralHard}
						/>
						<Text
							type="p"
							style={{
								color: ColorV2.text.neutralMedium,
								marginTop: 1,
							}}
						>
							<span
								style={{
									fontWeight: 600,
									color: ColorV2.text.neutralHard,
								}}
							>
								{props.challenge.supporters.toLocaleString() +
									" personas "}
							</span>
							han colaborado enviando rosas
						</Text>
					</Row>
				) : (
					props.challenge.type !== "signature" && (
						<IconCheckView>
							<IconCheck>
								<Users2
									height={24}
									width={24}
									strokeWidth={2}
								/>
							</IconCheck>
							<Column style={{ flex: 1 }}>
								<Text type="p" weight="medium">
									{props.challenge.supporters.toLocaleString() +
										" personas han colaborado"}
								</Text>
								<Text
									type="p2"
									style={{
										color: ColorV2.text.neutralMedium,
									}}
								>
									para hacer realidad el sueño.
								</Text>
							</Column>
						</IconCheckView>
					)
				)}
				{props.challenge.type === "rose" ? (
					<Row style={{ marginTop: 8 }}>
						<Flower
							style={{
								marginRight: 12,
								minHeight: 24,
								minWidth: 24,
							}}
							height={24}
							width={24}
							color={ColorV2.text.neutralHard}
						/>
						<Text type="p" style={{ marginTop: 1 }}>
							Enviar cada rosa tiene un coste de 5€
						</Text>
					</Row>
				) : (
					!completed &&
					!pastDate && (
						<IconCheckView style={{ marginTop: 12 }}>
							<IconCheck>
								<Calendar
									height={24}
									width={24}
									strokeWidth={2}
								/>
							</IconCheck>
							<Column style={{ flex: 1 }}>
								<Text type="p" weight="medium">
									{moment(props.challenge.endDate.iso).diff(
										moment(),
										"days"
									) + "  días restantes "}
								</Text>
								<Text
									type="p2"
									style={{
										color: ColorV2.text.neutralMedium,
									}}
								>
									para poder colaborar.
								</Text>
							</Column>
						</IconCheckView>
					)
				)}
			</MobileSection>
			{props.challenge.type === "dream" && (
				<MobileSection
					style={{
						backgroundColor: ColorV2.surface.background,
						padding: 16,
						borderRadius: 12,
						marginTop: 24,
					}}
				>
					<Text type="p2" style={{ color: ColorV2.text.neutralHard }}>
						Recupera hasta el{" "}
						<span
							style={{
								backgroundColor: ColorV2.surface.secondary,
								padding: "0px 6px",
								borderRadius: 3,
								fontWeight: 500,
							}}
						>
							80%
						</span>{" "}
						de tus donaciones en la Declaración de la Renta.{" "}
						<SpanLink onClick={() => setShowReliefModal(true)}>
							Saber más
						</SpanLink>
					</Text>
				</MobileSection>
			)}
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 12 }}>
					{props.challenge.type === "signature"
						? "Por qué necesitamos tu firma"
						: props.challenge.type === "rose"
						? "Necesitamos tu ayuda"
						: "Conoce su sueño"}
				</Text>
				{props.challenge.to && (
					<Row style={{ alignItems: "center", marginBottom: 24 }}>
						{props.challenge.to.image && (
							<SupporterImage src={props.challenge.to.image} />
						)}
						<Column>
							<Text type="p" weight="medium">
								{props.challenge.type === "signature" && (
									<span
										style={{
											color: Color.text.high,
											fontWeight: 400,
										}}
									>
										Dirigido a{" "}
									</span>
								)}{" "}
								{props.challenge.to.title}
							</Text>
							<Text type="p2" style={{ color: Color.text.high }}>
								{props.challenge.to.subtitle}
							</Text>
						</Column>
					</Row>
				)}
				<Text type="p" style={{ whiteSpace: "pre-wrap" }}>
					<div
						dangerouslySetInnerHTML={{
							__html: props.challenge.history,
						}}
					/>
				</Text>
			</Section>
			{props.challenge.type === "rose" && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						Cómo funciona
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Column style={{ flex: 1 }}>
								<Text type="h6" weight="medium">
									Elige cuántas rosas quieres enviar
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Puedes enviar todas las rosas que quieras.
									Recuerda que puedes enviarlas hasta el 23 de
									abril. Cuantas más rosas envíes más amor
									regalarás este Sant Jordi.
								</Text>
							</Column>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Column style={{ flex: 1 }}>
								<Text type="h6" weight="medium">
									Haz tu donación: ¡es deducible!
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Enviar cada rosa tiene un coste de 5€. Al
									ser una donación te podrás deducir hasta el
									80% en la declaración de la renta. Es decir,
									enviar una rosa realmente te costará solo
									1€.
								</Text>
							</Column>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								3
							</Numbers>
							<Column style={{ flex: 1 }}>
								<Text type="h6" weight="medium">
									Nuestro equipo se encargará de la entrega
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Nuestro equipo social se encargará de
									entregar todas las rosas. Gracias a la ayuda
									de la floristería barcelonesa Rosistirem
									podremos enviar flores a todas las
									residencias de mayores.
								</Text>
							</Column>
						</Row>
					</HowWork>
				</Section>
			)}
			{props.challenge.team && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						El equipo que lo va a hacer posible
					</Text>
					<Column style={{ gap: 24 }}>
						{props.challenge.team.map((item, index) => (
							<Row key={"social-worker-" + index}>
								<SupporterImage src={item.image} />
								<Column>
									<Text type="p" weight="medium">
										{item.name}
									</Text>
									<Label
										style={{
											height: "unset",
											padding: "0px 4px",
											marginBottom: 6,
											marginTop: 2,
										}}
										text={item.department}
										size="small"
										color={ColorV2.text.primary}
									/>
									<Text type="p2">{item.description}</Text>
								</Column>
							</Row>
						))}
					</Column>
				</Section>
			)}
			{props.challenge.timeline && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24 }}
					>
						Últimas actualizaciones
					</Text>
					{props.challenge.timeline.map((item, index) =>
						renderTimelineCell(item, index)
					)}
				</Section>
			)}
			{props.challenge.distribution && (
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 8 }}
					>
						{props.challenge.type === "rose"
							? "¿A qué se destinan los 5€ que cuesta cada rosa?"
							: "¿A qué se destina tu colaboración?"}
					</Text>
					<Text type="p" style={{ marginBottom: 32 }}>
						{props.challenge.type === "rose"
							? "Con tu colaboración podemos hacer frente a los costes que supone enviar una rosa a cada persona mayor. Queremos ser transparentes y mostrarte el detalle de costes:"
							: "Con tu colaboración podemos hacer frente a los costes que supone cumplir el sueño de la persona mayor. Queremos ser transparentes y mostrarte el detalle de costes:"}
					</Text>
					<PriceBreakdownCell
						options={props.challenge.distribution}
					/>
				</Section>
			)}
			<MobileSection>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					{props.challenge.type === "signature"
						? "Últimas personas que han firmado"
						: "Últimos colaboradores"}
				</Text>
				{props.supporters.map((item, index) => (
					<Cell
						key={"supporter-" + index}
						style={{ alignItems: "center", marginBottom: 12 }}
					>
						<SupporterImage
							src={
								item.user.image
									? item.user.image.url
									: require("../../../assets/images/icon/user_icon.png")
							}
						/>
						<Text type="p2">
							<span style={{ fontWeight: 600 }}>
								{item.user.name}
							</span>{" "}
							colaboró {moment(item.createdAt).fromNow()}
						</Text>
					</Cell>
				))}
			</MobileSection>
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					Preguntas frecuentes
				</Text>
				<FAQs
					options={
						props.challenge.type === "signature"
							? [
									{
										id: "item",
										title: "¿Qué se hace con las firmas conseguidas?",
										description:
											"Adopta Un Abuelo se encarga de presentar las firmas recogidas al organismo correspondiente para conseguir el objetivo.",
									},
									{
										id: "item",
										title: "¿Si conozco una petición, puedo proponerla?",
										description:
											"Por supuesto que sí. Envía un email a info@adoptaunabuelo.org contándonos la petición y nuestro equipo se pondrá en contacto contigo.",
									},
									{
										id: "item",
										title: "¿Hay otra forma de ayudar?",
										description:
											"Puedes ayudarnos compartiendo y dando difusión a esta petición para conseguir más apoyo.",
									},
							  ]
							: props.challenge.type === "rose"
							? [
									{
										id: "item",
										title: "¿Qué se hace con el dinero recaudado?",
										description:
											"El importe se destina a comprar la rosa y el envío. Adicionalmente se dona un euro que destinaremos para acompañar a personas mayores en situación de soledad durante todo el año.",
									},
									{
										id: "item",
										title: "¿Hay otra forma de ayudar que no sea con dinero?",
										description:
											"Puedes ayudarnos compartiendo y dando difusión a la iniciativa o puedes participar como voluntario descargando nuestra app.",
									},
									{
										id: "item",
										title: "¿Puedo saber qué abuelo ha recibido mi rosa?",
										description:
											"Las rosas las entregamos directamente en las más de 400 residencias con las que colaboramos y ellos se encargan de entregarlas a los mayores. Grabaremos toda la experiencia, pero por el momento no podemos decirte qué abuelo en concreto recibirá tu rosa.",
									},
							  ]
							: [
									{
										id: "item",
										title: "¿Qué se hace con el dinero recaudado?",
										description:
											"Se destina de forma íntegra a la realización del sueño.",
									},
									{
										id: "item",
										title: "¿Hay otra forma de ayudar que no sea con dinero?",
										description:
											"Puedes ayudarnos compartiendo y dando difusión a este sueño o puedes participar como voluntario descargando nuestra app.",
									},
									{
										id: "item",
										title: "¿Si conozco el sueño de una persona mayor, puedo proponerlo?",
										description:
											"Por supuesto que sí, estaremos encantados de conocer el sueño. Envía un email a info@adoptaunabuelo.org contándonos el sueño y nuestro equipo se pondrá en contacto contigo.",
									},
									{
										id: "item",
										title: "¿Qué ocurre si se recauda más dinero del objetivo?",
										description:
											"El importe extra recaudado será destinado íntegramente a los programas de acompañamiento a personas mayores en situación de soledad no deseada que desarrollamos desde Adopta Un Abuelo. Por cada 1,5€ recaudado se realiza una hora de acompañamiento.",
									},
							  ]
					}
				/>
			</Section>
		</Container>
	);
};
export default Info;
export interface Props {
	challenge: ChallengeScheme;
	supporters: {
		user: any;
		amount: number;
		createdAt: Date;
		letter: boolean;
	}[];
}
