import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChallengeScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import ResumeCheckout from "./section/ResumeCheckout";
import Success from "./section/Success";
import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import Price from "../../components/section/donation/Price";
import SuccessRight from "../../components/section/SuccessCrossSell";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "challenge";

	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [didSubscribe, setDidSubscribe] = useState(false);
	const [selectedPrice, setSelectedPrice] = useState<number | undefined>(
		undefined
	);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		//init
		if (location.state && location.state.challenge) {
			setChallenge(location.state.challenge);
			const restAmount =
				location.state.challenge.goal - location.state.challenge.amount;
			if (restAmount <= 0) setCompleted(true);
		} else {
			navigate("/");
		}
	}, []);

	return (
		<WizardContainer
			ref={wizard}
			views={[
				//Price
				{
					leftView: challenge && (
						<Price
							animated={true}
							challenge={challenge}
							view={view}
							defaultGrowthbook={{
								title: "¿Con cuánto quieres colaborar?",
								title_en: "How much do you want to contribute?",
								subtitle: "",
								subtitle_en: "",
								type_to_eur: 1,
							}}
							onFinish={(result) => {
								if (result.error) {
									setShowError({
										show: true,
										message: result.error,
									});
								} else if (result.data) {
									setSelectedPrice(result.data.price);
									wizard.current?.goNext();
								}
							}}
						/>
					),
					rightView: challenge && (
						<ResumeCheckout
							challenge={challenge}
							amount={
								location.state && location.state.supporters
									? location.state.supporters
									: 0
							}
						/>
					),
					rightViewStyle: {
						display: isMobile ? "none" : "flex",
					},
				},
				//Select payment method
				{
					leftView: <></>,
					centralView:
						selectedPrice && currentUser && challenge ? (
							<PaymentMethod
								animated={true}
								challenge={challenge}
								amount={selectedPrice}
								user={currentUser}
								view={view}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setDidSubscribe(true);
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
					rightView: <></>,
				},
				//Success view
				{
					containerStyle: { overflowY: "scroll" },
					leftView: currentUser ? (
						<>
							<Success
								animated={true}
								user={currentUser}
								didSubscribe={didSubscribe}
							>
								{isMobile ? (
									<SuccessRight
										animated={true}
									/>
								) : null}
							</Success>
						</>
					) : null,
					rightViewStyle: { position: "unset" },
					rightView: !isMobile ? (
						<SuccessRight animated={true} />
					) : null,
					hideBackButton: true,
				},
			]}
		/>
	);
};
export default Checkout;
export interface Props {}
