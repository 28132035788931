import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Track, ChallengeScheme, LetterScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import Price from "../../components/section/donation/Price";
import ResumeCheckout from "./section/ResumeCheckout";
import Success from "./section/Success";
import SkipModal from "../../components/modal/SkipPaymentModal";
import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Letter from "../../components/section/letter/Letter";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import EditLetter from "../../components/section/letter/EditLetter";
import PDFLetter from "../../components/section/letter/PDFLetter";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import styled from "styled-components";
import SuccessRight from "../../components/section/SuccessCrossSell";

const SkipDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${ColorV2.border.neutralHigh};
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const translation = useSelector(
		(state: State) => state.location.translation
	);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "birthday";

	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [supporters, setSupporters] = useState(0);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [didSubscribe, setDidSubscribe] = useState(false);
	const [letter, setLetter] = useState<{
		object?: LetterScheme;
		content: string;
		font: string;
		personalInfo: boolean;
		stamp: boolean;
		image?: string;
	}>({
		content: "",
		font: "Poppins",
		personalInfo: true,
		stamp: true,
	});
	const [selectedPrice, setSelectedPrice] = useState<number | undefined>(
		undefined
	);

	useEffect(() => {
		//init
		if (location.state && location.state.challenge) {
			setChallenge(location.state.challenge);
			setSupporters(location.state.supporters);
		} else {
			navigate("/");
		}
	}, []);

	const renderSkipDonationButton = () => (
		<SkipDiv
			onClick={() => {
				setShowSkipModal(true);

				//Track on Mixpanel
				Track.event({
					event: "skip donation modal show",
					user: currentUser,
					params: {
						view: view,
					},
				});
			}}
		>
			{translation.component_modal_skip_payment_button_skip}
		</SkipDiv>
	);

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={
					"https://parsefiles-spain.back4app.com/QY5gVZmeai8ug19nHGA9AoFmuPU26k04tGJeV1wT/a49c9c56caa6ddd4b1502f2f14fce17b_welcome_01-mp4.mp4"
				}
				text={
					"Con tu colaboración nos ayudas a acompañar y hacer felices a nuestros mayores."
				}
				onVideoEnded={() =>{
					Track.event({
						event: "donation video finish",
						user: currentUser,
						params: {
							view: view,
						},
					});
				}}
				onClick={() => {
					Track.event({
						event: "skip donation modal click",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.event({
						event: "skip donation modal close",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
					wizard.current?.goNext(2);
				}}
			/>
			<WizardContainer
				ref={wizard}
				views={[
					//TODO
					{
						containerStyle: { overflowY: "scroll" },
						leftView: currentUser ? (
							<>
								<Success
									animated={true}
									user={currentUser}
									letter={letter.object}
									didSubscribe={didSubscribe}
								>
									{isMobile ? (
										<SuccessRight
											animated={true}
											animationDelay={8600}
										/>
									) : null}
								</Success>
							</>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight
								animated={true}
								animationDelay={8600}
							/>
						) : null,
						hideBackButton: true,
					},
					//Letter
					{
						leftView: currentUser && challenge && (
							<Letter
								title="Escribe tu felicitación"
								subtitle="Incluye en ella tus mejores deseos, palabras bonitas y todos los piropos que se merece."
								user={currentUser}
								challenge={challenge}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setLetter({
											...letter,
											content: result.data,
										});
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightViewStyle: isMobile ? { display: "none" } : {},
						rightView: challenge && (
							<ResumeCheckout
								animated={true}
								challenge={challenge}
								amount={supporters}
							/>
						),
					},
					//Edit letter PDF
					{
						navigationLeftView: isMobile && (
							<Text type="b1" weight="medium">
								{translation.letter_edit_title_02}
							</Text>
						),
						leftViewStyle: {
							display: isMobile ? "none" : "flex",
						},
						leftView:
							currentUser && challenge ? (
								<EditLetter
									title="Haz tu carta única"
									subtitle="Personaliza tu carta antes de enviarla. Elige el estilo que más te guste y haz esta carta tan especial como tú."
									user={currentUser}
									challenge={challenge}
									animated={true}
									view={view}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											wizard.current?.goNext();
										}
									}}
								/>
							) : null,
						rightView:
							currentUser && challenge ? (
								<PDFLetter
									user={currentUser}
									challenge={challenge}
									animated={true}
									view={view}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											wizard.current?.goNext();
										}
									}}
								/>
							) : undefined,
					},
					//Price
					{
						navigationRightView: isMobile
							? renderSkipDonationButton()
							: undefined,
						leftView: challenge && (
							<Price
								animated={true}
								view={view}
								title="Colabora con el bote"
								subtitle="Todo el dinero irá destinado a comprar los regalos."
								challenge={challenge}
								onSkipClick={() => setShowSkipModal(true)}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setSelectedPrice(result.data.price);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: challenge && (
							<ResumeCheckout
								challenge={challenge}
								amount={
									location.state && location.state.supporters
										? location.state.supporters
										: 0
								}
							/>
						),
						rightViewStyle: {
							display: isMobile ? "none" : "flex",
						},
					},
					//Select payment method
					{
						leftView: <></>,
						centralView:
							selectedPrice && currentUser && challenge ? (
								<PaymentMethod
									animated={true}
									challenge={challenge}
									amount={selectedPrice}
									user={currentUser}
									view={view}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setDidSubscribe(true);
											wizard.current?.goNext();
										}
									}}
								/>
							) : null,
						rightView: <></>,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						leftView: currentUser ? (
							<>
								<Success
									animated={true}
									user={currentUser}
									letter={letter.object}
									didSubscribe={didSubscribe}
								>
									{isMobile ? (
										<SuccessRight
											animated={true}
											animationDelay={8600}
										/>
									) : null}
								</Success>
							</>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight
								animated={true}
								animationDelay={8600}
							/>
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {}
