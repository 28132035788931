import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import { Track } from "client-v2";

import Info from "./steps/Info";
import { Button } from "@adoptaunabuelo/react-components";
import HeaderNav from "../../components/container/Header";
import Header from "./steps/Header";
import Footer from "../../components/container/Footer";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
`;
const NavButton = styled(Button)`
	${media.lessThan("medium")`
        display: none !important;
    `}
`;

const DonationWizard = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const type = location.state ? location.state.type : undefined;

	const [activeHeader, setActiveHeader] = useState(false);

	useEffect(() => {
		//Track to Mixpanel
		Track.event({
			event: "donation detail view init",
			user: currentUser,
			params: {
				view: "donation",
			},
		});

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleScroll = (e: any) => {
		if (window.scrollY > 400) setActiveHeader(true);
		else setActiveHeader(false);
	};

	const onNextClick = () => {
		navigate("/donacion/registro");
	};

	return (
		<Container>
			<HeaderNav
				user={currentUser}
				active={activeHeader}
				childrenPosition={"right"}
				transparent={true}
			>
				{activeHeader ? (
					<NavButton onClick={onNextClick}>
						Quiero ser socio/a
					</NavButton>
				) : (
					<></>
				)}
			</HeaderNav>
			<Footer
				style={{ backgroundColor: "white" }}
				buttonProps={{
					style: { width: "100%" },
					children: "Quiero ser socio/a",
					onClick: onNextClick,
				}}
			/>
			<Header type={type} onNextClick={onNextClick} />
			<Info onClick={onNextClick} />
		</Container>
	);
};

export default DonationWizard;
export interface Props {}
