import { useState, useRef, useEffect } from "react";
import { Track, ProductScheme, UserScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Success from "./steps/SuccessLeftStep";
import SuccessRight from "./steps/SuccessRightStep";
import Price from "../../components/section/donation/Price";
import PriceLeft from "../../components/section/donation/PriceInfo";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import SkipModal from "../../components/modal/SkipPaymentModal";
import BirthStep from "../../components/section/Birthday";
import RightStep from "./steps/RightStep";
import Names from "../../assets/images/history/Names";
import GenderStep from "../../components/section/Gender";
import LocationStep from "../../components/section/Location";
import VisionLeftStep from "./steps/VisionLeftStep";
import styled from "styled-components";
import { ColorV2 } from "@adoptaunabuelo/react-components";

const SkipDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${ColorV2.border.neutralHigh};
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;

const SignUpWizard = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError, setCurrentUser } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const translation = useSelector(
		(state: State) => state.location.translation
	);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "register";
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [didSubscribe, setDidSubscribe] = useState(false);

	useEffect(() => {
		//Track to Mixpanel
		Track.event({
			event: "register process init",
			user: currentUser,
			params: {
				view: view,
			},
		});
		//init
		if (currentUser) {
			initialStep(currentUser);
		}
	}, []);

	const initialStep = async (user: UserScheme) => {
		if (user) {
			if (user.birthday) {
				if (user.gender) {
					if (user.address) wizard.current?.setStep(6);
					else wizard.current?.setStep(5);
				} else wizard.current?.setStep(4);
			} else wizard.current?.setStep(3);
		}
	};

	const renderSkipDonationButton = () => (
		<SkipDiv
			onClick={() => {
				setShowSkipModal(true);

				//Track on Mixpanel
				Track.event({
					event: "skip donation modal show",
					user: currentUser,
					params: {
						view: view,
					},
				});
			}}
		>
			{translation.component_modal_skip_payment_button_skip}
		</SkipDiv>
	);

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={
					"https://parsefiles-spain.back4app.com/QY5gVZmeai8ug19nHGA9AoFmuPU26k04tGJeV1wT/a49c9c56caa6ddd4b1502f2f14fce17b_welcome_01-mp4.mp4"
				}
				text={translation.component_modal_skip_payment_text}
				onVideoEnded={() =>{
					Track.event({
						event: "donation video finish",
						user: currentUser,
						params: {
							view: view,
						},
					});
				}}
				onClick={() => {
					Track.event({
						event: "skip donation modal click",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.event({
						event: "skip donation modal close",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
					wizard.current?.goNext(2);
				}}
			/>
			<WizardContainer
				ref={wizard}
				views={[
					//Birthday
					{
						leftView: currentUser && (
							<BirthStep
								user={currentUser}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setCurrentUser(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: !isMobile ? (
							<RightStep
								src={require("../../assets/images/history/enriqueta.webp")}
								subtitle={
									'"Gracias a Adopta Un Abuelo he aprendido a utilizar el teléfono móvil. Ahora puedo hacer videollamadas con mis voluntarios todas las semanas."'
								}
								footer="Abuela de Madrid"
								Header={<Names type="enriqueta" />}
							/>
						) : undefined,
					},
					//Gender
					{
						leftView: currentUser && (
							<GenderStep
								animated={true}
								user={currentUser}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setCurrentUser(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: !isMobile ? (
							<RightStep
								src={require("../../assets/images/history/jose.webp")}
								subtitle={
									'"Cada vez que mis voluntarias me visitan me siento un poco más feliz. Cuando salimos a dar un paseo siempre presumo orgulloso de que son mi nietas."'
								}
								footer="Abuelo de Jaén"
								Header={<Names type="jose" />}
							/>
						) : undefined,
					},
					//Location
					{
						leftView: currentUser && (
							<LocationStep
								animated={true}
								user={currentUser}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setCurrentUser(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: !isMobile ? (
							<RightStep
								src={require("../../assets/images/history/antonia.webp")}
								subtitle={
									'"Mi mayor ilusión era aprender a leer y gracias a la dedicación de mis voluntarias Charo y Paloma pude conseguirlo. ¡Las quiero mucho!"'
								}
								footer="Abuela de Córdona"
								Header={<Names type="antonia" />}
							/>
						) : undefined,
					},
					//Vision
					{
						leftView: (
							<VisionLeftStep
								animated={true}
								onFinish={(result) => {
									if (result.data) {
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: !isMobile ? (
							<RightStep
								src={require("../../assets/images/background/register/back_vision.webp")}
								subtitle={"Bernardo, el primer abuelo adoptado"}
							/>
						) : undefined,
					},
					//Select price view
					{
						navigationRightView: isMobile
							? renderSkipDonationButton()
							: undefined,
						containerStyle: { overflowY: "scroll" },
						leftView: (
							<Price
								options={["monthly", "annually", "unique"]}
								animated={true}
								view={view}
								defaultGrowthbook={{
									title: "Ningún mayor debería sentirse solo. ¿Nos ayudas?",
									title_en:
										"No grandpa should feel alone. Can you help us?",
									subtitle:
										"Estás regalando {{data}} horas de compañía a una persona mayor",
									subtitle_en:
										"You are giving away {{data}} hours of company to a grandpa",
									type_to_eur: 0.602,
								}}
								onSkipClick={() => setShowSkipModal(true)}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightViewStyle: { overflowY: "scroll" },
						rightView: isMobile ? null : <PriceLeft />,
					},
					//Select payment method
					{
						leftViewStyle: { alignItems: "center" },
						leftView:
							product && currentUser ? (
								<PaymentMethod
									animated={true}
									product={product}
									user={currentUser}
									view={view}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setDidSubscribe(true);
											wizard.current?.goNext();
										}
									}}
									onProductChange={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
										}
									}}
								/>
							) : null,
						rightView: null,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						leftView: currentUser ? (
							<Success
								animated={true}
								user={currentUser}
								didSubscribe={didSubscribe}
							/>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight animated={true} />
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};

export default SignUpWizard;
export interface Props {}
