import styled from "styled-components";
import media from "styled-media-query";
import SectionContainer, { SectionContainerProps } from "./SectionContainer";

const isScreen01: any = "1400px";
const SectionContainerStyled = styled(SectionContainer)`
	width: -webkit-fill-available;
	width: -moz-available;
	margin-right: 0;
	margin-left: calc(100% / 6);
	padding-top: 48px;
	${media.lessThan(isScreen01)`
		margin-right: 0;
		margin-left: calc(100% / 8);
    `}
	${media.lessThan("medium")`
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 8px;
	`}
`;

const LeftSectionContainer = (props: LeftSectionContainerProps) => {
	return (
		<SectionContainerStyled {...props}>
			{props.children}
		</SectionContainerStyled>
	);
};
export default LeftSectionContainer;
export interface LeftSectionContainerProps extends SectionContainerProps {}
