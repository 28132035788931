import styled from "styled-components";
import media from "styled-media-query";
import { ChallengeScheme } from "client-v2";
import moment from "moment";

import {
	Text,
	Button,
	Color,
	Counter,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import PriceBreakdownCell from "../../../components/cell/PriceBreakdownCell";
import { Cake } from "lucide-react";

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const MobileSection = styled.div`
	display: none;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
	${media.lessThan("medium")`
        display: flex;
    `}
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${ColorV2.surface.neutralSoft};
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 18px;
`;
const Image = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 40px;
	margin-right: 16px;
`;
const Icon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	border-radius: 48px;
	background-color: ${ColorV2.surface.background};
`;

const Info = (props: Props) => {
	const endDate = moment(props.challenge.endDate.iso);
	const today = moment();
	
	return (
		<Container>
			<MobileSection style={{ marginTop: 24 }}>
				<Col style={{ marginBottom: 24, gap: 4 }}>
					<Counter
						borderColor={ColorV2.border.neutralSoft}
						amount={props.amount + 18}
						height={52}
						width={36}
						fontSize={44}
					/>
					<Text
						type="h5"
						weight="medium"
						style={{ color: ColorV2.text.primary }}
					>
						Felicitaciones
					</Text>
				</Col>
				<Cell style={{ gap: 12, marginBottom: 0 }}>
					<Icon>
						<Cake height={24} width={24} />
					</Icon>
					<Col>
						<Text type="p" weight="medium">
							¡Quedan {endDate.from(today, true)}!
						</Text>
						<Text
							type="p2"
							style={{ color: ColorV2.text.neutralMedium }}
						>
							El cumpleaños es el{" "}
							{moment(endDate).format("DD MMMM")}
						</Text>
					</Col>
				</Cell>
			</MobileSection>
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					¡Estamos de cumpleaños!
				</Text>
				<Text type="p" style={{ whiteSpace: "pre-wrap" }}>
					{props.challenge.history}
				</Text>
			</Section>
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					Cómo funciona
				</Text>
				<HowWork>
					<Row>
						<Numbers type="p" weight="semibold">
							1
						</Numbers>
						<Col>
							<Text type="h6" weight="medium">
								Escribe tu felicitación
							</Text>
							<Text
								type="p"
								style={{ color: Color.text.high, marginTop: 4 }}
							>
								Incluye en ella tus mejores deseos, palabras
								bonitas y todos los piropos que se merece.
								Nosotros nos encargamos de hacérsela llegar el
								día de su cumpleaños.
							</Text>
						</Col>
					</Row>
					<Row>
						<Numbers type="p" weight="semibold">
							2
						</Numbers>
						<Col>
							<Text type="h6" weight="medium">
								Participa en los regalos
							</Text>
							<Text
								type="p"
								style={{ color: Color.text.high, marginTop: 4 }}
							>
								Un pajarito nos ha chivado los regalos que le
								haría mucha ilusión recibir. Colabora con el
								bote para que podamos comprarlos.
							</Text>
						</Col>
					</Row>
					<Row>
						<Numbers type="p" weight="semibold">
							3
						</Numbers>
						<Col>
							<Text type="h6" weight="medium">
								¡Hora de celebrar!
							</Text>
							<Text
								type="p"
								style={{ color: Color.text.high, marginTop: 4 }}
							>
								Organizaremos una fiesta para celebrar su
								cumpleaños y hacerle llegar todas vuestras
								felicitaciones y regalos. ¡Os lo contaremos todo
								por nuestras redes!
							</Text>
						</Col>
					</Row>
				</HowWork>
			</Section>
			<Section>
				<Text type="h4" weight="semibold" style={{ marginBottom: 8 }}>
					Con estos regalos acertamos fijo
				</Text>
				<Text
					type="p"
					style={{ marginBottom: 32, color: Color.text.high }}
				>
					Queremos que sea un cumpleaños muy especial, así que vamos a
					enviarle regalos de parte de todos. Tu aportación irá a un
					bote común para poder comprarlos.
				</Text>
				<Button
					style={{
						width: "fit-content",
						marginBottom: 24,
						color: ColorV2.text.neutralHard,
						borderColor: ColorV2.text.neutralHard,
					}}
					design={"secondary"}
					onClick={() => props.goToPayment()}
				>
					Quiero colaborar
				</Button>
				<PriceBreakdownCell
					title={"Con tu colaboración podremos regarle:"}
					options={props.challenge.distribution}
				/>
			</Section>
			<MobileSection>
				<Text type="h4" weight="semibold" style={{ marginBottom: 24 }}>
					Últimas felicitaciones
				</Text>
				{props.supporters.map((item, index) => (
					<Cell key={"supporter-" + index}>
						<Image
							src={
								item.user.image
									? item.user.image.url
									: require("../../../assets/images/icon/user_icon.png")
							}
						/>
						<Text type="p2" style={{ color: Color.text.high }}>
							<strong>{item.user.name}</strong>{" "}
							{item.letter ? "envió su felicitación" : "colaboró"}{" "}
							{moment(item.createdAt).fromNow()}
						</Text>
					</Cell>
				))}
			</MobileSection>
		</Container>
	);
};
export default Info;
export interface Props {
	challenge: ChallengeScheme;
	supporters: {
		user: any;
		amount: number;
		createdAt: Date;
		letter: boolean;
	}[];
	amount: number;
	goToPayment: () => void;
}
