import { ComponentPropsWithoutRef } from "react";
import RightSectionContainer, {
	RightSectionContainerProps,
} from "./RightSectionContainer";

const Resume = (props: Props) => {
	return (
		<RightSectionContainer
			{...props}
			childrenProps={{
				...props.childrenProps,
				style: {
					backgroundColor: "white",
					borderRadius: 12,
					overflow: "hidden",
					marginTop: 0,
					marginBottom: 0,
					...props.childrenProps?.style,
				},
			}}
		>
			{props.children}
		</RightSectionContainer>
	);
};
export default Resume;
export interface Props extends RightSectionContainerProps {}
