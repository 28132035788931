import { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { LetterScheme, UserScheme } from "client-v2";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import { Button, ColorV2, Text, Color } from "@adoptaunabuelo/react-components";
import { Download } from "lucide-react";
import Relief from "../../../components/section/donation/Relief";
import LetterSuccessAnimation from "../../../assets/animations/letter_success.json";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";

const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const translateY = window.innerHeight / 2 - 100 - 56; //half window height- half animation height - nav bar height
const AnimationView = styled.div<{
	$showAnimation: boolean;
	$moveAnimation: boolean;
}>`
	transform: ${(props) =>
		props.$moveAnimation
			? "translate(0px, 0px)"
			: `translate(calc(100% - 100px), ${translateY}px)`};
	opacity: ${(props) => (props.$showAnimation ? 1 : 0)};
	transition: opacity 0.6s ease-in, transform 1.8s ease-out;
	${(props) => media.lessThan("medium")`
		transform: ${
			props.$moveAnimation
				? "translateY(0px)"
				: `translateY(${translateY}px)`
		};
    `}
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Numbers = styled(Text)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 13px !important;
	height: 24px;
	width: 24px;
	border-radius: 30px;
	border: 2px solid;
	color: ${(props) =>
		props.color ? props.color : ColorV2.text.neutralHard} !important;
`;
const DataView = styled.div<{ $showContent: boolean }>`
	transform: ${(props) =>
		props.$showContent ? "translateY(0px)" : "translateY(20px)"};
	opacity: ${(props) => (props.$showContent ? 1 : 0)};
	transition: opacity 0.6s ease-in, transform 0.6s ease-out;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [loadingIG, setLoadingIG] = useState(false);
	const [successPDF, setSuccessPDF] = useState(false);

	const startMovingTime = 6400;
	const movingTime = 2200;
	const [moveAnimation, setMoveAnimation] = useState(false);
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		setLoadingPDF(false);
		setLoadingIG(false);
		setSuccessPDF(false);
	}, []);

	useEffect(() => {
		//Animation movement activation
		const timer = setTimeout(() => setMoveAnimation(true), startMovingTime);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		// When movement completed, show view data
		if (moveAnimation) {
			const timer = setTimeout(() => {
				setShowContent(true);
			}, movingTime);
			return () => clearTimeout(timer);
		}
	}, [moveAnimation]);

	const downloadLetter = async () => {
		if (props.letter) {
			setLoadingPDF(true);
			const url = `${process.env.PUBLIC_URL}/assets/letter/birthday.html`;
			const response = await fetch(url);
			let html = await response.text();
			html = html.replace("{{content}}", props.letter.content);
			html = html.replace(
				"{{name}}",
				`${props.user.name} ${props.user.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(props.user.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					props.letter.customization
						? props.letter.customization.font
						: "Poppins"
				}";`
			);

			html = html.replace(
				"{{stamp_img}}",
				"./assets/letter/stamp_retro.png"
			);
			html = html.replace("{{corporate_logo}}", "");

			if (props.letter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (props.letter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (props.letter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${props.letter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			//Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoadingPDF(false);
				});
		}
	};

	return (
		<LeftSectionContainer
			animated={props.animated}
			style={{ paddingTop: 0 }}
		>
			<AnimationView $showAnimation={true} $moveAnimation={moveAnimation}>
				<Player
					style={{
						width: isMobile ? 150 : 200,
						height: isMobile ? 150 : 200,
					}}
					keepLastFrame={true}
					autoplay={true}
					src={LetterSuccessAnimation}
				/>
			</AnimationView>
			<DataView $showContent={showContent}>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Muchas gracias, {props.user.name}!
				</Text>
				<Text type="p" style={{ marginTop: 12, textAlign: "center" }}>
					Hemos recibido tu carta. Gracias a gente como tú hacemos un
					poquito más felices a nuestros mayores.
				</Text>
				{props.letter && (
					<Col
						style={{
							alignItems: "center",
							gap: 8,
							marginTop: 48,
						}}
					>
						<Button
							design={"secondary"}
							size="small"
							icon={<Download color={ColorV2.text.neutralHard} />}
							style={{
								width: "100%",
								color: ColorV2.text.neutralHard,
							}}
							color={ColorV2.text.neutralHard}
							loading={loadingPDF}
							success={successPDF}
							onClick={downloadLetter}
						>
							Descarga mi carta
						</Button>
					</Col>
				)}
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24, textAlign: "center" }}
					>
						¿Y ahora qué?
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									Juntamos vuestras felicitaciones
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Las recopilamos y se las hacemos llegar a la
									persona mayor. ¡Le van a encantar!
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									Compramos los regalos
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Gracias a vuestra colaboración haremos este
									día un poquito más especial.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								3
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									¡Hora de celebrar!
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Organizaremos una fiesta para celebrar su
									cumpleaños y hacerle llegar todas vuestras
									felicitaciones y regalos. ¡Os lo contaremos
									todo por nuestras redes!
								</Text>
							</Col>
						</Row>
					</HowWork>
				</Section>
				{appLocation &&
					appLocation.country_code === "ES" &&
					props.didSubscribe &&
					props.user &&
					!props.user.idCard &&
					!props.user.zipCode && (
						<Relief style={{ marginTop: 32 }} user={props.user} />
					)}
				{props.children}
			</DataView>
		</LeftSectionContainer>
	);
};
export default Success;
export interface Props {
	children?: React.ReactNode;
	animated?: boolean;
	letter?: LetterScheme;
	user: UserScheme;
	didSubscribe?: boolean;
	onNextClick?: () => void;
}
