import styled from "styled-components";
import media from "styled-media-query";

import { Text, ColorV2 } from "@adoptaunabuelo/react-components";
import { useEffect, useState } from "react";
import { CornerDownRight } from "lucide-react";
import { State } from "../../redux";
import { useSelector } from "react-redux";

const ZigzagView = styled.div`
	position: relative;
	box-sizing: border-box;
	padding: 24px 32px;
	background-color: ${ColorV2.surface.background};
	min-width: 350px;
	max-width: 100%;
	width: fit-content;
	:before,
	:after {
		content: "";
		position: absolute;
		left: 0px;
		height: 8px;
		width: 100%;
	}
	:before {
		top: -5px;
		background: radial-gradient(
				circle,
				transparent,
				transparent 50%,
				${ColorV2.surface.background} 50%,
				${ColorV2.surface.background} 100%
			)
			4px -8px / 20px 15px repeat-x;
	}
	:after {
		bottom: -5px;
		background: radial-gradient(
				circle,
				transparent,
				transparent 50%,
				${ColorV2.surface.background} 50%,
				${ColorV2.surface.background} 100%
			)
			4px 0px / 20px 20px repeat-x;
	}
	${media.lessThan("small")`
        padding: 24px;
        min-width: unset;
        width: 100%;
    `}
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	padding: 4px 0px;
	align-items: center;
`;
const Image = styled.img`
	height: 48px;
	width: 48px;
	border-radius: 6px;
	object-fit: cover;
	margin-right: 16px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
`;

const PriceBreakdownCell = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const [goal, setGoal] = useState(0);

	useEffect(() => {
		let temp = 0;
		props.options.map((item) => (temp = temp + item.amount.eur));
		setGoal(temp);
	}, [props.options]);

	const calculateAmount = (amount: number): string => {
		const tempAmount =
			amount / (appLocation ? appLocation.currency.conversion : 1);
		const tempCurrencyName = appLocation
			? appLocation.currency.name
			: "eur";
		const tempLanguage = appLocation ? appLocation.language : "es-ES";

		return new Intl.NumberFormat(tempLanguage, {
			style: "currency",
			currency: tempCurrencyName,
			maximumFractionDigits: 0,
		}).format(tempAmount);
	};

	return (
		<ZigzagView style={props.style}>
			{props.options.map((item, index) => (
				<div key={"distribution-" + index}>
					<Cell
						style={{
							justifyContent: "space-between",
						}}
					>
						{item.image && <Image src={item.image} />}
						<Row style={{ minHeight: item.image ? 48 : 32 }}>
							<Text
								type="p"
								style={{
									fontFamily: "DM Mono",
									display: "flex",
									flex: 1,
									marginRight: 24,
								}}
							>
								{item.title}
							</Text>
							{!item.items && (
								<Text
									type="p"
									style={{ fontFamily: "DM Mono" }}
								>
									{calculateAmount(item.amount.eur)}
								</Text>
							)}
						</Row>
					</Cell>
					{item.items?.map((subitem, index) => (
						<Row
							key={"sub-distribution-" + index}
							style={{ minHeight: item.image ? 48 : 32 }}
						>
							<CornerDownRight
								height={16}
								width={16}
								style={{ marginRight: 8, marginLeft: 16 }}
							/>
							<Text
								type="p"
								style={{
									fontFamily: "DM Mono",
									display: "flex",
									flex: 1,
									marginRight: 24,
								}}
							>
								{subitem.title}
							</Text>
							<Text type="p" style={{ fontFamily: "DM Mono" }}>
								{calculateAmount(subitem.amount.eur)}
							</Text>
						</Row>
					))}
				</div>
			))}
			<Cell
				style={{
					justifyContent: "space-between",
					paddingTop: 12,
					marginTop: 12,
					borderTop: "1px dashed ",
				}}
			>
				<Text
					type="h6"
					style={{
						fontFamily: "DM Mono",
						fontWeight: 500,
						display: "flex",
						flex: 1,
					}}
				>
					Total
				</Text>
				<Text
					type="h6"
					style={{ fontFamily: "DM Mono", fontWeight: 500 }}
				>
					{calculateAmount(goal)}
				</Text>
			</Cell>
		</ZigzagView>
	);
};
export default PriceBreakdownCell;
export interface Props {
	style?: any;
	title?: string;
	options: Array<{
		title: string;
		image?: string;
		amount: {
			[key: string]: number;
		};
		items?: Array<{
			title: string;
			amount: {
				[key: string]: number;
			};
		}>;
	}>;
}
