import { useEffect, useState, CSSProperties } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Grandpa, CoorporateScheme, UserScheme, Constant } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { Player } from "@lottiefiles/react-lottie-player";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import SectionContainer from "../../../components/container/SectionContainer";
import HobbiesStamp from "../../../components/stamp/HobbiesStamp";
import LetterLoadingAnimation from "../../../assets/animations/letter_loading.json";

const Cell = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 500px;
	align-items: center;
	justify-content: center;
	${media.lessThan("medium")`
		max-width: 400px;
    `}
`;
const Image = styled.img`
	width: 160px;
	aspect-ratio: 1/1;
	object-fit: cover;
	z-index: 1;
	background-color: ${ColorV2.surface.background};
	${media.lessThan("medium")`
        width: 150px;
    `}
`;
const MobileBackground = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 50%;
	${media.lessThan("medium")`
        display: block;
    `}
`;
const AnimationView = styled.div<{ $hidden: boolean }>`
	opacity: ${(props) => (props.$hidden ? 0 : 1)};
	transition: opacity 0.6s ease-in, transform 0.6s ease-out;
	transform: ${(props) =>
		props.$hidden ? "translateY(20px)" : "translateY(0px)"};
`;

const Search = (props: Props) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
			  };
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const [loading, setLoading] = useState(false);
	const [grandpa, setGrandpa] = useState<UserScheme | undefined>(undefined);
	const [prevGrandpa, setPrevGrandpa] = useState<UserScheme | undefined>(
		props.prevGrandpa
	);
	const [hobbies, setHobbies] = useState<any[]>([]);

	useEffect(() => {
		if (props.prevGrandpa) {
			getGrandpaHobbies(props.prevGrandpa);
			setGrandpa(props.prevGrandpa);
		} else {
			search();
		}
	}, []);

	const search = async () => {
		setLoading(true);
		//Clear all variables
		props.onGrandpaSearch({ data: undefined });
		localStorage.removeItem("letter");
		setHobbies([]);
		setGrandpa(undefined);
		setPrevGrandpa(undefined);

		//Search grandpa
		Grandpa.getForLetter({
			userId: props.user.objectId,
		})
			.then((result) => {
				getGrandpaHobbies(result.data);
				const prevLetterString = localStorage.getItem("letter") || "{}";
				const prevLetter = JSON.parse(prevLetterString);
				localStorage.setItem(
					"letter",
					JSON.stringify({
						...prevLetter,
						grandpa: result.data,
					})
				);
				setGrandpa(result.data);
			})
			.catch((e: string) => {
				console.error(e);
				setLoading(false);
			});
	};

	const getGrandpaHobbies = (grandpa: UserScheme) => {
		if (
			grandpa.data &&
			grandpa.data.hobbies &&
			grandpa.data.hobbies.length > 0
		) {
			const hobbiesConstants = Constant.hobbies();
			const temp = grandpa.data.hobbies
				.map((item) => {
					const result = hobbiesConstants.filter(
						(i) => i.id === item
					);
					if (result.length > 0) {
						return {
							title:
								appLocation?.language === "en"
									? result[0].label_en
									: result[0].label,
							icon: result[0].icon,
							backgroundColor: result[0].backgroundColor,
						};
					}
				})
				.filter((i) => i)
				.slice(0, 5);
			setHobbies(temp);
		}
	};

	return (
		<SectionContainer
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			animated={props.animated}
			childrenProps={{ style: loading ? { flex: "unset" } : {} }}
			buttonProps={
				loading
					? undefined
					: {
							style: {
								backgroundColor: colors.buttonBackground,
								color: colors.buttonColor,
								width: "100%"
							},
							children: prevGrandpa
								? translation.letter_button_continue
								: translation.letter_button_go,
							onClick: () => props.onFinish({ data: grandpa }),
					  }
			}
			secondaryButtonProps={
				prevGrandpa
					? {
							style: {
								color: colors.buttonBackground,
								width: "100%"
							},
							children: translation.letter_button_other_grandpa,
							onClick: search,
					  }
					: undefined
			}
		>
			{loading ? (
				<AnimationView $hidden={false}>
					<Player
						style={{
							height: "186px",
							width: "186px",
							zIndex: 100,
						}}
						autoplay={true}
						keepLastFrame={true}
						src={LetterLoadingAnimation}
						renderer={"svg"}
						onEvent={(event) => {
							if (event === "complete") {
								if (grandpa) {
									props.onGrandpaSearch({ data: grandpa });
									setLoading(false);
								}
							}
						}}
					/>
					<Text type="b2" style={{ textAlign: "center" }}>
						{translation.letter_search_loading}
					</Text>
				</AnimationView>
			) : grandpa ? (
				<Cell>
					<MobileBackground>
						{hobbies.map((hobbie, index) => (
							<HobbiesStamp
								key={"hobbie-stamp-" + index}
								style={
									index === 0
										? {
												position: "absolute",
												top: 0,
												left: 16,
										  }
										: index === 1
										? {
												position: "absolute",
												top: "15%",
												right: "10%",
										  }
										: index === 2
										? {
												position: "absolute",
												bottom: "10%",
												left: 0,
										  }
										: index === 3
										? {
												position: "absolute",
												bottom: "15%",
												right: 8,
												zIndex: 2,
										  }
										: index === 4
										? {
												position: "absolute",
												top: -52,
												right: 0,
												zIndex: 2,
										  }
										: {}
								}
								icon={hobbie.icon}
								title={hobbie.title}
								backgroundColor={hobbie.backgroundColor}
							/>
						))}
					</MobileBackground>
					<Image
						src={
							grandpa?.image
								? grandpa.image.url
								: grandpa.gender
								? grandpa.gender === "male"
									? require("../../../assets/images/icon/grandpa_icon.png")
									: grandpa.gender === "female"
									? require("../../../assets/images/icon/grandma_icon.png")
									: require("../../../assets/images/icon/user_icon.png")
								: require("../../../assets/images/icon/user_icon.png")
						}
					/>
					{prevGrandpa ? (
						<Text
							type="h3"
							weight="semibold"
							style={{
								textAlign: "center",
								marginBottom: 12,
								marginTop: 32,
							}}
						>
							{translation.letter_search_continue_title}
							<span style={{ textTransform: "capitalize" }}>
								{grandpa?.name.toLowerCase()}
							</span>
						</Text>
					) : (
						<Text
							type="h3"
							weight="semibold"
							style={{
								textAlign: "center",
								marginBottom: 12,
								marginTop: 32,
							}}
						>
							{translation.letter_search_title}
							<span style={{ textTransform: "capitalize" }}>
								{grandpa?.name.toLowerCase()}
							</span>
							!
						</Text>
					)}
					<Text
						type="p2"
						style={{ textAlign: "center", marginBottom: 16 }}
					>
						{prevGrandpa
							? translation.letter_search_continue_subtitle
							: translation.letter_search_subtitle}
					</Text>
				</Cell>
			) : null}
		</SectionContainer>
	);
};
export default Search;
export interface Props {
	user: UserScheme;
	corporate?: CoorporateScheme;
	animated?: boolean;
	prevGrandpa?: UserScheme;
	buttonProps?: {
		style?: CSSProperties;
		children: string;
		width?: number | string;
		request?: string;
	};
	onGrandpaSearch: (result: { data?: UserScheme; error?: string }) => void;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
