import styled from "styled-components";
import { ColorV2, Switch, Text } from "@adoptaunabuelo/react-components";
import { CSSProperties, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { ChevronDown, ChevronUp } from "lucide-react";
import media from "styled-media-query";
import { CoorporateScheme } from "client-v2";

const EditContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px 16px 4px 16px;
	background-color: white;
	border-radius: 12px;
	${media.lessThan("medium")`
		padding: 4px 16px;
	`}
`;
const TagContainer = styled.div`
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	gap: 8px;
	padding: 12px 0px;
	width: -webkit-fill-available;
	width: -moz-available;
`;
const Tag = styled.div<{ $selected: boolean; $color: string }>`
	display: flex;
	flex: 40%;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	box-shadow: ${(props) =>
		props.$selected
			? "0 0 0 2px " +
			  (props.$color ? props.$color : ColorV2.border.primary)
			: "0 0 0 1px " + ColorV2.border.neutral};
	padding: 16px 8px;
	cursor: pointer;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${ColorV2.border.neutral};
	padding: 8px 0px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	${media.lessThan("medium")`
		width: -webkit-fill-available;
		width: -moz-available;
	`}
`;

const EditLetter = (props: EditLetterProps) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const appLocation = useSelector((state: State) => state.location.location);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const fontFamily = [
		{
			id: "Poppins",
			title: appLocation?.language === "en" ? "Normal" : "Normal",
		},
		{
			id: "DM Mono",
			title: appLocation?.language === "en" ? "Machine" : "A máquina",
		},
		{
			id: "Schoolbell",
			title: appLocation?.language === "en" ? "Handmade" : "A mano",
		},
		{
			id: "STIX Two Text",
			title: appLocation?.language === "en" ? "Classic" : "Clásica",
		},
	];
	const colors =
		props.corporate && props.corporate.color
			? {
					main: props.corporate.color.main,
			  }
			: {
					main: ColorV2.surface.primary,
			  };
	const [showFonts, setShowFonts] = useState(true);

	return (
		<EditContainer style={props.style}>
			<Cell
				style={{ flexDirection: "column", alignItems: "flex-start" }}
				onClick={() => {
					if (isMobile) setShowFonts(!showFonts);
				}}
			>
				<Row>
					<Text type="p">{translation.letter_edit_type}</Text>
					{isMobile &&
						(showFonts ? (
							<ChevronUp color={ColorV2.border.neutralHigh} />
						) : (
							<ChevronDown color={ColorV2.border.neutralHigh} />
						))}
				</Row>
				{showFonts && (
					<TagContainer>
						{fontFamily.map((font) => (
							<Tag
								$color={colors.main}
								$selected={font.id === props.font}
								onClick={(e) => {
									props.onChange({
										...props,
										font: font.id,
									});
									e.stopPropagation();
								}}
							>
								<Text type="p" style={{ fontFamily: font.id }}>
									{font.title}
								</Text>
							</Tag>
						))}
					</TagContainer>
				)}
			</Cell>
			<Cell
				style={props.view !== "letter" ? { borderBottom: "unset" } : {}}
			>
				<Text type="p">{translation.letter_edit_sender}</Text>
				<Switch
					active={props.personalInfo}
					color={colors.main}
					onChange={(on) =>
						props.onChange({
							...props,
							personalInfo: on,
						})
					}
				/>
			</Cell>
			{props.view === "letter" && (
				<Cell style={{ borderBottom: "unset" }}>
					<Text type="p">{translation.letter_edit_stamp}</Text>
					<Switch
						active={props.stamp}
						color={colors.main}
						onChange={(on) =>
							props.onChange({
								...props,
								stamp: on,
							})
						}
					/>
				</Cell>
			)}
		</EditContainer>
	);
};
export default EditLetter;
export interface EditLetterProps {
	style?: CSSProperties;
	font: string;
	personalInfo: boolean;
	stamp: boolean;
	corporate?: CoorporateScheme;
	view: "letter" | "birthday";
	onChange: (result: {
		font: string;
		personalInfo: boolean;
		stamp: boolean;
	}) => void;
}
