import { useEffect, useRef, useState } from "react";
import { Subscription, UserScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import { useSearchParams } from "react-router-dom";

import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import PaymentMethod from "../../components/section/donation/PaymentMethod";

const IbanWizard = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "change-payment-method";
	const [searchParams, setSearchParams] = useSearchParams();
	const [canEdit, setCanEdit] = useState<string>("sepa");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		//init
		if (currentUser) {
			getSubscriptionInfo(currentUser);
		}
	}, []);

	const getSubscriptionInfo = (user: UserScheme) => {
		const id = searchParams.get("id");
		if (id && (id === "card" || id === "sepa")) {
			setCanEdit(id);
		} else {
			//Get the current subscription
			setLoading(true);
			Subscription.get({
				userId: user.objectId,
				limit: 1,
				descending: "createdAt",
			})
				.then((result) => {
					if (result.data.length > 0) {
						const subscription = result.data[0];
						if (subscription.stripeId) setCanEdit("card");
						else setCanEdit("sepa");
					}
					setLoading(false);
				})
				.catch((error) => {
					console.error(error.message);
					setLoading(false);
				});
		}
	};

	return (
		<WizardContainer
			ref={wizard}
			loading={loading}
			views={[
				//Select payment method
				{
					leftView: <></>,
					centralView: currentUser ? (
						<PaymentMethod
							animated={true}
							user={currentUser}
							view={view}
							canEdit={canEdit as any}
							onFinish={(result) => {
								if (result.error) {
									setShowError({
										show: true,
										message: result.error,
									});
								} else if (result.data) {
									wizard.current?.goNext();
								}
							}}
						/>
					) : null,
					rightView: <></>,
				},
			]}
		/>
	);
};
export default IbanWizard;
export interface Props {}
