import { ChallengeScheme, Track } from "client-v2";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Container from "../../components/container/Container";
import Resume from "../../components/section/ResumeDetail";
import Header from "../../components/section/Header";
import Info from "./section/Info";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

const Detail = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { setShowSuccess } = bindActionCreators(actionsCreators, dispatch);
	const loading = useSelector((state: State) => state.modals.loading);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	useEffect(() => {
		//Track to Mixpanel
		Track.event({
			event: "birthday view init",
			user: currentUser,
			params: {
				challengeId: props.challenge.objectId,
				view: "birthday"
			}
		});
	}, []);

	const onNextClick = () => {
		navigate("/cumpleanios/registro/?id=" + props.challenge.objectId, {
			state: {
				challenge: props.challenge,
				supporters: props.count,
			},
		});
	};

	const onShareClick = (op: any) => {
		const url =
			"https://adoptaunabuelo.org/cumpleanios/?id=" +
			props.challenge.objectId;
		if (op.id === "twitter") {
			window.open("http://www.twitter.com/share?url=" + url, "_blank");
		} else if (op.id === "facebook") {
			window.open("http://www.facebook.com/sharer.php?u=" + url);
		} else if (op.id === "whatsapp") {
			window.open("whatsapp://send?text=" + url);
		}
		if (op.id === "url") {
			navigator.clipboard.writeText(url);
			setShowSuccess({
				show: true,
				message: "Se ha copiado el enlace en tu portapapeles",
			});
		}
	};

	return (
		<Container
			loading={loading}
			headerProps={{
				user: currentUser,
				transparent: true,
			}}
			showConfetti={true}
			RightView={
				<Resume
					challenge={props.challenge}
					supporters={props.supporters}
					letterCount={props.count}
					onClick={onNextClick}
					onShareClick={onShareClick}
				/>
			}
			footerProps={{
				style: { backgroundColor: "white" },
				buttonProps: {
					children: "Enviar mi felicitación",
					style: {
						width: "-webkit-fill-available",
						minWidth: "-moz-available",
					},
					onClick: onNextClick,
				},
			}}
		>
			<Header
				title={props.challenge.title}
				video={props.challenge.video}
				image={props.challenge.image?.url}
				type={"cumpleaños"}
			/>
			<Info
				challenge={props.challenge}
				supporters={props.supporters}
				amount={props.count}
				goToPayment={() =>
					navigate(
						"/cumpleanios/registro/?id=" + props.challenge.objectId,
						{
							state: {
								step: "presents",
								challenge: props.challenge,
								supporters: props.count,
							},
						}
					)
				}
			/>
		</Container>
	);
};
export default Detail;
export interface Props {
	challenge: ChallengeScheme;
	supporters: Array<any>;
	count: number;
}
