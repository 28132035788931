import { useState } from "react";
import EditLetterComponent, {
	EditLetterProps,
} from "../../../views/letter/section/EditLetter";
import {
	ChallengeScheme,
	CoorporateScheme,
	ErrorScheme,
	Letter,
	LetterScheme,
	Track,
	UserScheme,
} from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { ColorV2 } from "@adoptaunabuelo/react-components";
import ReactPixel from "react-facebook-pixel";
import LeftSectionContainer from "../../container/LeftSectionContainer";

const EditLetter = (
	props: Props &
		(
			| { grandpa: UserScheme; challenge?: undefined }
			| { grandpa?: undefined; challenge: ChallengeScheme }
		)
) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
			  };
	const isScreen02 = useSelector((state: State) => state.screen.isScreen02);
	const appLocation = useSelector((state: State) => state.location.location);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [letter, setLetter] = useState<LetterScheme | undefined>(undefined);

	const onNextClick = () => {
		setLoading(true);
		Letter.create({
			userId: props.user.objectId,
			corporateId: props.corporate?.objectId,
			grandpaId: props.grandpa?.objectId,
			challengeId: props.challenge?.objectId,
			content: props.content,
			image: props.image,
			country: appLocation?.country_code,
			language: appLocation?.language,
			customization: {
				stamp: props.stamp,
				font: props.font,
				personalInfo: props.personalInfo,
			},
		})
			.then((result) => {
				//Track on Facebook
				ReactPixel.trackCustom("CartaEnviadaOK", {
					value: 49.99,
					currency: appLocation ? appLocation.currency.symbol : "€",
					product_id: "A123",
					product_name: "Producto E",
					customer_type: "CartaOK",
				});

				//Track on mixpanel
				Track.event({
					event: "letter sent",
					user: props.user,
					params: {
						view: props.view,
					},
				});

				setLetter(result.data);
				localStorage.removeItem("letter");
				setLoading(false);
				setSuccess(true);
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
				props.onFinish({ error: e.message });
			});
	};

	return (
		<LeftSectionContainer
			title={props.title}
			subtitle={props.subtitle}
			animated={props.animated}
			style={{ width: 420 }}
			buttonProps={{
				style: {
					backgroundColor: success
						? ColorV2.surface.green
						: colors.buttonBackground,
					color: success ? "white" : colors.buttonColor,
				},
				children: translation.letter_edit_button_next,
				loading: loading,
				success: success,
				onSuccess: () => {
					props.onFinish({ data: letter });
				},
				onClick: onNextClick,
			}}
		>
			<EditLetterComponent {...props} />
		</LeftSectionContainer>
	);
};
export default EditLetter;
export interface Props extends EditLetterProps {
	title?: string;
	subtitle?: string;
	user: UserScheme;
	corporate?: CoorporateScheme;
	content: string;
	image?: string;
	animated?: boolean;
	view: "letter" | "birthday";
	onFinish: (result: { data?: LetterScheme; error?: string }) => void;
}
