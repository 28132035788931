import styled from "styled-components";
import moment from "moment";
import media from "styled-media-query";
import { ChallengeScheme } from "client-v2";
import { Text, ColorV2, Counter } from "@adoptaunabuelo/react-components";
import Resume from "../../../components/container/ResumeContainer";
import { Cake } from "lucide-react";

const isScreen02: any = "1050px";
const Section = styled.div`
	padding: 24px;
	${media.lessThan(isScreen02)`
        padding: 20px;
    `}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Image = styled.img`
	height: 220px;
	object-fit: cover;
`;

const ResumeCheckout = (props: Props) => {
	const endDate = moment(props.challenge.endDate.iso);
	const today = moment();

	return (
		<Resume
			animated={props.animated}
		>
			<Image src={props.challenge.image?.url} />
			<Section>
				<Text type="h6" weight="medium">
					{props.challenge.title}
				</Text>
				<Row
					style={{ alignItems: "center", gap: 8, margin: "16px 0px" }}
				>
					<Counter
						amount={props.amount + 18}
						height={36}
						width={24}
						fontSize={28}
					/>
					<Text
						type="p"
						weight="medium"
						style={{ color: ColorV2.text.primary }}
					>
						felicitaciones
					</Text>
				</Row>
				<Text
					type="p2"
					style={{
						color: ColorV2.text.neutralHard,
						display: "flex",
						alignItems: "center",
						gap: 8,
					}}
				>
					<Cake height={24} width={24} /> ¡Quedan{" "}
					{endDate.from(today, true)} para su cumpleaños!
				</Text>
			</Section>
		</Resume>
	);
};
export default ResumeCheckout;
export interface Props {
	animated?: boolean;
	style?: React.CSSProperties;
	challenge: ChallengeScheme;
	amount: number;
	onClick?: () => void;
}
