import { ChallengeScheme, CoorporateScheme, UserScheme } from "client-v2";
import { TextArea, ColorV2 } from "@adoptaunabuelo/react-components";
import { ReactNode, useEffect, useState } from "react";
import Footer from "../../../views/letter/section/Footer";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import LeftSectionContainer from "../../container/LeftSectionContainer";

const Letter = (
	props: LetterProps &
		(
			| { grandpa: UserScheme; challenge?: undefined }
			| { challenge: ChallengeScheme; grandpa?: undefined }
		)
) => {
	const colors =
		props.corporate && props.corporate.color
			? {
					buttonBackground: props.corporate.color.main,
					buttonColor: props.corporate.color.third,
					animation: [
						props.corporate.color.main,
						props.corporate.color.secondary,
					],
			  }
			: {
					buttonBackground: ColorV2.surface.primaryHard,
					buttonColor: "white",
					animation: ["#00315C", "#005AA8"],
			  };
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [letter, setLetter] = useState<string | undefined>(undefined);

	useEffect(() => {
		setLetter(props.defaultValue);
	}, [props.defaultValue]);

	const onNextClick = () => {
		if (letter && letter.length <= 0)
			props.onFinish({
				error: translation.component_input_letter_error_01,
			});
		else {
			if (!letter || letter.length <= 50)
				props.onFinish({
					error: translation.component_input_letter_error_02,
				});
			else if (letter && letter.length >= 16384)
				props.onFinish({
					error: translation.component_input_letter_error_03,
				});
			else
				props.onFinish({
					data: letter,
				});
		}
	};

	return (
		<LeftSectionContainer
			title={props.title}
			subtitle={props.subtitle}
			animated={props.animated}
			style={{
				paddingTop: props.title && 0,
			}}
			childrenProps={{
				style: {
					flex: 1,
					margin: props.title ? (isMobile ? "24px -24px 0px" : "32px 0px 0px") : (isMobile ? "0px -24px 0px" : "0px")
				},
			}}
			buttonProps={{
				LeftView: isMobile && props.grandpa && (
					<Footer
						grandpa={props.grandpa}
						corporate={props.corporate}
					/>
				),
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
					marginTop: isMobile ? 16 : 8,
					marginBottom: isMobile ? 0 : 40,
					marginLeft: isMobile ? 0 : 48,
					marginRight: isMobile ? 0 : 48,
					width: isMobile
						? props.grandpa
							? "fit-content"
							: "100%"
						: "100%",
					position: "relative",
				},
				backgroundColor: isMobile ? "transparent" : "white",
				disabled: letter && letter.length > 0 ? false : true,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<TextArea
				style={{
					height: "100%",
					width: "100%",
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					borderTopRightRadius: isMobile ? 0 : 12,
					borderTopLeftRadius: isMobile ? 0 : 12,
				}}
				type="edit"
				placeholder={translation.component_input_letter_placeholder}
				value={props.defaultValue}
				ToolbarButton={props.TipButton}
				onChange={(value) => {
					const prevLetterString =
						localStorage.getItem("letter") || "{}";
					const prevLetter = JSON.parse(prevLetterString);
					localStorage.setItem(
						"letter",
						JSON.stringify({
							...prevLetter,
							content: value,
						})
					);
					setLetter(value);
				}}
			/>
		</LeftSectionContainer>
	);
};
export default Letter;
export interface LetterProps {
	title?: string;
	subtitle?: string;
	user: UserScheme;
	corporate?: CoorporateScheme;
	animated?: boolean;
	defaultValue?: string;
	TipButton?: ReactNode;
	onFinish: (result: { data?: string; error?: string }) => void;
}
