import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme } from "client-v2";
import ReactPlayer from "react-player";
import {
	Modal,
	Button,
	Text,
	Color,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { Check, Maximize2, Minimize2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan("medium")`
        flex-direction: column;
    `}
`;
const LeftView = styled.div<{ $fullSize?: boolean }>`
	display: flex;
	width: 300px;
	aspect-ratio: 9/16;
	${(props) => media.lessThan("medium")`
		flex: 1;
        justify-content: center;
        width: unset;
        aspect-ratio: unset;
		min-height: ${props.$fullSize ? "80%" : "50%"};
		transition: min-height 0.4s ease-in-out;
    `}
`;
const RightView = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 32px;
	text-align: center;
	${media.lessThan("medium")`
		padding: 24px 24px 16px;
	`}
`;
const DataContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
`;
const CheckIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	border-radius: 40px;
	background-color: ${Color.background.primaryLow};
	margin-right: 16px;
`;
const Image = styled.img`
	height: 240px;
	width: 240px;
	object-fit: cover;
	border-radius: 12px;
	margin: 32px;
	${media.lessThan("small")`
        height: 180px;
        width: 180px;
        margin-bottom: 0px;
    `}
`;
const ContentText = styled(Text)`
	margin-top: 32px !important;
	margin-bottom: 32px !important;
	${media.lessThan("medium")`
		margin-top: 12px !important;
		margin-bottom: 24px !important;
	`}
`;
const DesktopView = styled.div`
	display: block;
	${media.lessThan("medium")`
		display: none;
	`}
`;
const MobileView = styled.div`
	display: none;
	padding: 0px 16px 16px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	justify-content: space-between;
	z-index: 10;
	${media.lessThan("medium")`
		display: flex;
	`}
`;
const VideoView = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: black;
`;
const SuccessButton = styled(Button)`
	background-color: ${ColorV2.surface.primaryHard} !important;
	${media.lessThan("medium")`
		position: sticky;
    	bottom: 16px;
	`}
`;

const SkipModal = (props: SkipModalProps) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [disableSkip, setDisableSkip] = useState(true);
	const [fullSizeVideo, setFullSizeVideo] = useState(
		props.video ? true : false
	);

	useEffect(() => {
		if (props.isVisible) setDisableSkip(true);
	}, [props.isVisible]);

	const options = props.options
		? props.options
		: [
				{
					title: "En España más de 2 millones de mayores viven solos y muchos de ellos no celebran su cumpleaños.",
				},
				{
					title: "Cada euro cuenta. Con una pequeña aportación económica también puedes generar un gran impacto.",
				},
				{
					title: "Nuestro equipo de trabajo social habla con cada mayor para saber los regalos que le harían feliz. Los precios son aproximados e incluyen costes adicionales como comisiones bancarias.",
				},
		  ];

	return (
		<Modal
			style={
				isMobile ? { height: "100%", maxHeight: "95%" } : { width: 800 }
			}
			contentStyle={
				isMobile
					? { padding: 0, height: "100%", display: "flex" }
					: { padding: 0 }
			}
			isVisible={props.isVisible}
			onClose={props.onClose}
			hideClose={true}
			hideHeader={true}
			type={props.type}
		>
			<Container>
				<LeftView $fullSize={fullSizeVideo}>
					{props.video ? (
						<VideoView>
							<ReactPlayer
								width={"100%"}
								height={"100%"}
								url={props.video}
								playing={true}
								playsinline={true}
								volume={1}
								controls={false}
								config={{
									file: {
										forceVideo: true,
										attributes: {
											controlsList:
												"nodownload nofullscreen",
											disablepictureinpicture: true,
										},
									},
								}}
								onEnded={props.onVideoEnded && props.onVideoEnded}
							/>
							<MobileView>
								<Button
									style={{
										backgroundColor: "white",
										borderRadius: 80,
									}}
									design="image"
									icon={
										fullSizeVideo ? (
											<Minimize2
												color={ColorV2.text.neutralHard}
											/>
										) : (
											<Maximize2
												color={ColorV2.text.neutralHard}
											/>
										)
									}
									onClick={() =>
										setFullSizeVideo(!fullSizeVideo)
									}
								/>
								<Button
									style={{
										backgroundColor: disableSkip
											? "rgba(0, 29, 61, 0.24"
											: "white",
										color: disableSkip
											? "white"
											: ColorV2.text.neutralHard,
										height: 42,
										padding: "0px 16px",
										backdropFilter: "blur(20px)",
									}}
									countdown={10}
									onClick={props.onClose}
									onCountdownEnd={() => setDisableSkip(false)}
								>
									<Text
										type="b2"
										style={{
											color: disableSkip
												? "white"
												: ColorV2.text.neutralHard,
										}}
									>
										{disableSkip
											? translation.component_modal_skip_payment_button_video_01
											: translation.component_modal_skip_payment_button_skip}
									</Text>
								</Button>
							</MobileView>
						</VideoView>
					) : (
						<Image
							src={
								"https://adoptaunabuelo.org/wp-content/uploads/2023/04/sentado_02.webp"
							}
						/>
					)}
				</LeftView>
				<RightView>
					<DataContainer>
						{!isMobile && (
							<Text
								type="h6"
								weight="semibold"
								style={{
									color: ColorV2.text.primary,
									marginBottom: 8,
								}}
							>
								{
									translation.component_modal_skip_payment_title_01
								}
							</Text>
						)}
						<Text type="h3" weight="semibold">
							{translation.component_modal_skip_payment_title_02}
						</Text>
						<ContentText type="p">
							{props.text
								? props.text
								: translation.component_modal_skip_payment_text}
						</ContentText>
						{props.options &&
							options.map((item, index) => (
								<Cell key={"skip-option-" + index}>
									<CheckIcon>
										<Check
											color={Color.text.primary}
											height={16}
											width={16}
										/>
									</CheckIcon>
									<Text type="p" style={{ flex: 1 }}>
										{item.title}
									</Text>
								</Cell>
							))}
					</DataContainer>
					<SuccessButton onClick={props.onClick}>
						{translation.component_modal_skip_payment_button_ok}
					</SuccessButton>
					<DesktopView>
						<Button
							style={{
								backgroundColor: "transparent",
								color: ColorV2.text.neutralHard,
								marginTop: 8,
								width: "100%",
							}}
							disabled={disableSkip}
							countdown={10}
							onClick={props.onClose}
							onCountdownEnd={() => setDisableSkip(false)}
						>
							{disableSkip
								? translation.component_modal_skip_payment_button_video_02
								: translation.component_modal_skip_payment_button_skip}
						</Button>
					</DesktopView>
				</RightView>
			</Container>
		</Modal>
	);
};
export default SkipModal;
export interface SkipModalProps {
	isVisible: boolean;
	options?: Array<{
		title: any;
	}>;
	text?: string;
	type?: "full-screen" | "default";
	coorporate?: CoorporateScheme;
	video?: string;
	onVideoEnded?: () => void;
	onClose: () => void;
	onClick: () => void;
}
