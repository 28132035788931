import { useEffect, useState, useRef } from "react";
import { EventScheme, ProductScheme } from "client-v2";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Ticket from "../../components/section/Ticket";
import Success from "./section/Success";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import ResumeCheckout from "./section/ResumeCheckout";
import SuccessRight from "../../components/section/SuccessCrossSell";

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "event";

	const [event, setEvent] = useState<EventScheme | undefined>(undefined);
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);

	useEffect(() => {
		//init
		if (location.state && location.state.event && location.state.product) {
			setEvent(location.state.event);
			setProduct(location.state.product);
		} else {
			navigate("/");
		}
	}, []);

	return (
		<WizardContainer
			ref={wizard}
			views={[
				//Ticket resume
				{
					leftView: currentUser && product && (
						<Ticket
							animated={true}
							product={product}
							user={currentUser}
							onFinish={(result) => {
								if (result.error) {
									setShowError({
										show: true,
										message: result.error,
									});
								} else if (result.data) {
									wizard.current?.goNext(result.data);
								}
							}}
						/>
					),
					rightView: product && event && (
						<ResumeCheckout
							title={product.title}
							price={product.price}
							startDate={
								product.startDate
									? product.startDate.iso
									: undefined
							}
							endDate={
								product.endDate
									? product.endDate.iso
									: undefined
							}
							address={event.address}
							image={event.image.url}
							corporate={
								product.data && product.data.corporate
									? true
									: false
							}
						/>
					),
					rightViewStyle: {
						display: isMobile ? "none" : "flex",
					},
				},
				//Select payment method
				{
					leftViewStyle: { alignItems: "center" },
					leftView:
						product && currentUser ? (
							<PaymentMethod
								animated={true}
								product={product}
								user={currentUser}
								view={view}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
					rightView: null,
				},
				//Success view
				{
					containerStyle: { overflowY: "scroll" },
					leftView: currentUser ? (
						<>
							<Success
								animated={true}
								event={event}
								product={product}
							>
								{isMobile ? (
									<SuccessRight animated={true} />
								) : null}
							</Success>
						</>
					) : null,
					rightViewStyle: { position: "unset" },
					rightView: !isMobile ? (
						<SuccessRight animated={true} />
					) : null,
					hideBackButton: true,
				},
			]}
		/>
	);
};
export default Checkout;
export interface Props {}
