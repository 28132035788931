import { ProductScheme, SubscriptionScheme } from "client-v2";
import SectionContainer from "../../container/SectionContainer";
import { Button, Label, Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 24px;
	border-radius: 12px;
	gap: 4px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const SubscriptionSection = (props: SubscriptionSectionProps) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const [chargeString, setChargeString] = useState<string | undefined>();
	const [price, setPrice] = useState<number>(
		props.subscription.Product.price
	);

	useEffect(() => {
		//Get the charge string
		if (props.subscription.Product.charge === "annually")
			setChargeString("año");
		else if (props.subscription.Product.charge === "monthly")
			setChargeString("mes");
		else if (props.subscription.Product.charge === "quaterly")
			setChargeString("trimestre");

		//Get the data price
		const data = props.subscription.Product.data;
		const realPrice: any = data
			? data[props.subscription.currency.toUpperCase()]
			: props.subscription.Product.price;
		setPrice(
			realPrice ? realPrice.price : props.subscription.Product.price
		);
	}, [props.subscription]);

	const onNextClick = () => {
		props.onFinish({ data: props.subscription.Product });
	};

	return (
		<SectionContainer
			title="Tu donación"
			buttonProps={
				props.subscription.state === "active"
					? undefined
					: {
							children: "Reactivar suscripción",
							onClick: onNextClick,
					  }
			}
		>
			<Container
				style={{
					opacity: props.subscription.state === "active" ? 1 : 0.7,
				}}
			>
				<Row>
					<Label text={props.subscription.state} />
					{props.subscription.state === "active" && (
						<Button
							design="call-to-action"
							onClick={() =>
								props.onFinish({
									data: props.subscription.Product,
								})
							}
						>
							Editar
						</Button>
					)}
				</Row>
				<Text type="h4">
					{new Intl.NumberFormat(
						appLocation ? appLocation.language : "es-ES",
						{
							style: "currency",
							currency: props.subscription.currency,
							maximumFractionDigits: 0,
						}
					).format(price)}
					/{chargeString}
				</Text>
				<Text type="p">
					Activa desde{" "}
					{moment(props.subscription.createdAt).format("DD/MM/YYYY")}
				</Text>
				<Text type="p">
					{props.subscription.state === "cancel"
						? "Cancelada el "
						: "Renovación "}
					{moment(props.subscription.endDate.iso).format(
						"DD/MM/YYYY"
					)}
				</Text>
			</Container>
		</SectionContainer>
	);
};
export default SubscriptionSection;
export interface SubscriptionSectionProps {
	subscription: SubscriptionScheme;
	onFinish: (result: { data?: ProductScheme; error?: string }) => void;
}
