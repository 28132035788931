import styled from "styled-components";
import { UserScheme } from "client-v2";

import { Color, Text } from "@adoptaunabuelo/react-components";
import { Heart } from "lucide-react";
import Relief from "../../../components/section/donation/Relief";
import { State } from "../../../redux";
import { useSelector } from "react-redux";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;
const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
	color: ${Color.text.primary} !important;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	return (
		<LeftSectionContainer>
			<Container>
				<Heart
					height={48}
					width={48}
					fill={Color.text.primary}
					color={"transparent"}
				/>
				<Text type="h3" weight="semibold" style={{ marginTop: 12 }}>
					¡Gracias por tu ayuda!
				</Text>
				<Text
					type="p"
					style={{
						marginTop: 12,
						marginBottom: 12,
						textAlign: "center",
					}}
				>
					Con tu aportación estamos más cerca de cumplir este sueño y
					el de otros muchos abuelos.
				</Text>
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24, textAlign: "center" }}
					>
						¿Y ahora qué?
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Tenemos que conseguir el sueño
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Todos nuestros esfuerzos están puestos en
									encontrar personas como tú, que quiera
									ayudarnos a hacer felices a las personas
									mayores.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									¡Objetivo conseguido!
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Nuestro equipo de trabajo social se encarga
									de hacer realidad el sueño y que la
									experiencia de nuestros abuelos sea
									inolvidable.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								3
							</Numbers>
							<Col>
								<Text type="h6" weight="medium">
									Atento a nuestras redes
								</Text>
								<Text
									type="p"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Compartiremos vídeos y fotos del sueño
									cumplido para que lo puedas disfrutar tanto
									como nuestros abuelos.
								</Text>
							</Col>
						</Row>
					</HowWork>
				</Section>
				{appLocation &&
					appLocation.country_code === "ES" &&
					props.didSubscribe &&
					props.user &&
					!props.user.idCard &&
					!props.user.zipCode && (
						<Relief style={{ marginTop: 32 }} user={props.user} />
					)}
				{props.children}
			</Container>
		</LeftSectionContainer>
	);
};
export default Success;
export interface Props {
	children?: React.ReactNode;
	animated?: boolean;
	user: UserScheme;
	didSubscribe?: boolean;
	onNextClick?: () => void;
}
