import { CSSProperties } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Text, Color } from "@adoptaunabuelo/react-components";
import RightSectionContainer from "../../../components/container/RightSectionContainer";

const size: any = "1080px";

const ImageView = styled.img`
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
	border-radius: 12px;
	margin-bottom: 24px;
	${media.lessThan("medium")`
        height: 0px;
    `}
`;
const TextContainer = styled.div`
	width: 350px;
	${media.lessThan(size)`
        width: 300px;
    `}
`;
const HeaderView = styled.div`
	margin-top: 16px;
`;

const LeftStep = (props: Props) => {
	return (
		<RightSectionContainer>
			<ImageView src={props.src} />
			<TextContainer>
				<Text
					type="h3"
					weight="semibold"
					style={{ color: Color.text.deepBlue }}
				>
					{props.title}
				</Text>
				{props.subtitle && (
					<Text type="p" style={{ color: Color.text.deepBlue }}>
						{props.subtitle}
					</Text>
				)}
				{props.Header && <HeaderView>{props.Header}</HeaderView>}
				{props.footer && (
					<Text type="p" style={{ color: Color.text.high }}>
						{props.footer}
					</Text>
				)}
			</TextContainer>
		</RightSectionContainer>
	);
};
export default LeftStep;
export interface Props {
	src: string;
	title?: string;
	titleStyle?: CSSProperties;
	subtitle?: string;
	Header?: React.ReactNode;
	footer?: string;
}
