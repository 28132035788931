import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import {
	Track,
	UserScheme,
	CoorporateScheme,
	Constant,
	LetterScheme,
	ProductScheme,
} from "client-v2";
import SkipModal from "../../components/modal/SkipPaymentModal";
import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Personal from "./section/Personal";
import Search from "./section/Search";
import Stamp from "./section/Stamp";
import Letter from "../../components/section/letter/Letter";
import LetterLeft from "./section/LetterLeft";
import LetterRight from "./section/LetterRight";
import EditLetter from "../../components/section/letter/EditLetter";
import PDFLetter from "../../components/section/letter/PDFLetter";
import Price from "../../components/section/donation/Price";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import { ChevronDown, Sparkles } from "lucide-react";
import { ColorV2, Feedback, Text } from "@adoptaunabuelo/react-components";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import Success from "./section/Success";
import SuccessRight from "../../components/section/SuccessCrossSell";
import LetterImage from "../../components/section/letter/LetterImage";
import PriceLeft from "../../components/section/donation/PriceInfo";
import { GrowthBook } from "@growthbook/growthbook-react";
import PaymentMethodRight from "./section/PaymentMethodRight";
import RightSectionContainer from "../../components/container/RightSectionContainer";

const AbsoluteDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;
	background: linear-gradient(72deg, #5963f6 0%, #cd59f6 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;
const SkipDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${ColorV2.border.neutralHigh};
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;
const PersonalRight = styled(RightSectionContainer)`
	${media.lessThan("medium")`
        display: none;
	`}
`;
const CorporateLogo = styled.img`
	height: 56px;
	width: auto;
`;
const ArrowAnimationView = styled.div`
	display: none;
	position: sticky;
	bottom: 0px;
	right: 0px;
	left: 0px;
	width: 100%;
	padding: 52px 0px 16px;
	background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
	align-items: flex-end;
	justify-content: center;
	${media.lessThan("medium")`
		display: flex;
    `}
`;

const Checkout = (props: Props) => {
	const wizard = useRef<WizardContainerRef>(null);
	const tips = Constant.letterTips();
	const dispatch = useDispatch();
	const { setShowError, setCurrentUser } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const isScreen01: boolean = useSelector(
		(state: State) => state.screen.isScreen01
	);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const view = "letter"

	const [grandpa, setGrandpa] = useState<UserScheme | undefined>(undefined);
	const [letter, setLetter] = useState<{
		object?: LetterScheme;
		content: string;
		font: string;
		personalInfo: boolean;
		stamp: boolean;
		image?: string;
	}>({
		content: "",
		font: "Poppins",
		personalInfo: true,
		stamp: true,
	});
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [showMug, setShowMug] = useState(false);
	const [showSkipModal, setShowSkipModal] = useState(false);
	const [didSubscribe, setDidSubscribe] = useState(false);
	const [tip, setTip] = useState<string | undefined>(undefined);

	useEffect(() => {
		//Track to Mixpanel
		Track.event({
			event: "letter view init",
			user: currentUser,
			params: {
				view: view,
			},
		});

		//init
		if (currentUser) {
			if (props.prevLetter) {
				setLetter({
					content: props.prevLetter.content,
					font: "Poppins",
					personalInfo: true,
					stamp: true,
				});
				setGrandpa(props.prevLetter.grandpa);
			}
		}
	}, []);

	const renderTipButton = () => (
		<AbsoluteDiv
			onClick={() => {
				const randomIndex = Math.floor(Math.random() * tips.length);
				const randomValue = tips[randomIndex];
				setTip(
					appLocation?.language === "en"
						? randomValue.title_en
						: randomValue.title
				);
			}}
		>
			<Sparkles color={"#6C62F6"} height={20} width={20} />
			{translation.component_input_letter_inspiration}
		</AbsoluteDiv>
	);

	const renderSkipDonationButton = () => (
		<SkipDiv
			onClick={() => {
				setShowSkipModal(true);

				//Track on Mixpanel
				Track.event({
					event: "skip donation modal show",
					user: currentUser,
					params: {
						view: view,
					},
				});
			}}
		>
			{translation.component_modal_skip_payment_button_skip}
		</SkipDiv>
	);

	const onBackClick = (step: number) => {
		if (step === 6) {
			if (
				!(
					props.corporate &&
					props.corporate.data &&
					props.corporate.data.letter &&
					props.corporate.data.letter.image
				)
			) {
				wizard.current?.goBack(1, step);
			}
		}
	};

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={
					"https://parsefiles-spain.back4app.com/QY5gVZmeai8ug19nHGA9AoFmuPU26k04tGJeV1wT/b030eef7590a0208ec341464aa82de50_skip_modal-mp4.mp4"
				}
				text={translation.component_modal_skip_payment_text}
				coorporate={props.corporate}
				onVideoEnded={() =>{
					Track.event({
						event: "donation video finish",
						user: currentUser,
						params: {
							view: view,
						},
					});
				}}
				onClick={() => {
					Track.event({
						event: "skip donation modal click",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.event({
						event: "skip donation modal close",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
					wizard.current?.goNext(2);
				}}
			/>
			<WizardContainer
				ref={wizard}
				onBackClick={onBackClick}
				personalView={
					//Personal data
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftViewStyle: isMobile
							? { alignItems: "unset" }
							: undefined,
						leftView: currentUser ? (
							<Personal
								user={currentUser}
								corporate={props.corporate}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setCurrentUser(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
						rightView: !isMobile ? (
							<PersonalRight
								title={translation.letter_personal_title}
								subtitle={translation.letter_personal_subtitle}
								animated={true}
							/>
						) : null,
					}
				}
				views={[
					//Search grandpa
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: !isMobile ? (
							<Stamp
								position="left"
								hobbies={grandpa?.data?.hobbies}
							/>
						) : null,
						centralView: currentUser ? (
							<Search
								user={currentUser}
								corporate={props.corporate}
								animated={true}
								prevGrandpa={grandpa}
								onGrandpaSearch={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setGrandpa(result.data);
										setLetter({
											...letter,
											content: "",
										});
									} else {
										setGrandpa(undefined);
									}
								}}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										wizard.current?.goNext();
									}
								}}
							/>
						) : null,
						rightView: !isMobile ? (
							<Stamp
								position="right"
								hobbies={grandpa?.data?.hobbies}
							/>
						) : null,
						hideBackButton: true,
					},
					//Write letter
					{
						navigationLeftView: isMobile && (
							<Text type="b1" weight="medium">
								{translation.letter_letter_title}
							</Text>
						),
						navigationRightView: isMobile
							? renderTipButton()
							: props.corporate?.logo && (
									<CorporateLogo
										src={props.corporate.logo.url}
									/>
							  ),
						leftViewStyle: isMobile ? { display: "none" } : {},
						leftView: grandpa ? (
							<LetterLeft grandpa={grandpa} />
						) : null,
						centralViewStyle: {
							width: isMobile ? "100%" : isScreen01 ? 500 : 600,
							height: isMobile
								? "calc(var(--doc-height) - 56px)"
								: "calc(var(--doc-height) - 72px)",
						},
						centralView:
							currentUser && grandpa ? (
								<>
									{tip && (
										<Feedback
											isVisible={tip ? true : false}
											type={"custom"}
											style={{
												background:
													"linear-gradient(72deg, #5963F6 0%, #CD59F6 100%)",
												borderRadius: 12,
												top: isMobile ? 0 : -50,
												right: isMobile ? 16 : 0,
												left: isMobile ? 16 : 0,
												width: isMobile
													? "unset"
													: "100%",
												maxWidth: "unset",
												bottom: "unset",
											}}
											text={tip}
											closeAfter={5000}
											showClose={true}
											onClose={() => setTip(undefined)}
										/>
									)}
									<Letter
										user={currentUser}
										grandpa={grandpa}
										animated={true}
										corporate={props.corporate}
										defaultValue={letter.content}
										TipButton={renderTipButton()}
										onFinish={(result) => {
											if (result.error) {
												setShowError({
													show: true,
													message: result.error,
												});
											} else if (result.data) {
												setLetter({
													...letter,
													content: result.data,
												});
												//Go to image if is a corporate letter
												if (
													props.corporate &&
													props.corporate.data &&
													props.corporate.data
														.letter &&
													props.corporate.data.letter
														.image
												)
													wizard.current?.goNext();
												else wizard.current?.goNext(2);
											}
										}}
									/>
								</>
							) : null,
						rightViewStyle: isMobile ? { display: "none" } : {},
						rightView: grandpa ? (
							<LetterRight hobbies={grandpa.data?.hobbies} />
						) : null,
					},
					//Add image step
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: <></>,
						centralView: (
							<LetterImage
								corporate={props.corporate}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else {
										setLetter({
											...letter,
											image: result.data,
										});
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: <></>,
					},
					//Edit letter PDF
					{
						navigationLeftView: isMobile && (
							<Text type="b1" weight="medium">
								{translation.letter_edit_title_02}
							</Text>
						),
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftViewStyle: {
							display: isMobile ? "none" : "flex",
						},
						leftView:
							currentUser && grandpa ? (
								<EditLetter
									title={translation.letter_edit_title}
									subtitle={translation.letter_edit_subtitle}
									user={currentUser}
									grandpa={grandpa}
									view={view}
									corporate={props.corporate}
									animated={true}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											if (props.corporate)
												wizard.current?.goNext(3);
											else wizard.current?.goNext();
										}
									}}
								/>
							) : null,
						rightView:
							currentUser && grandpa ? (
								<PDFLetter
									user={currentUser}
									grandpa={grandpa}
									corporate={props.corporate}
									animated={true}
									view={view}
									{...letter}
									onChange={(data) =>
										setLetter({
											content: letter.content,
											...data,
										})
									}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setLetter({
												...letter,
												object: result.data,
											});
											wizard.current?.goNext();
										}
									}}
								/>
							) : undefined,
					},
					//Select price view
					{
						navigationRightView: isMobile
							? renderSkipDonationButton()
							: props.corporate?.logo && (
									<CorporateLogo
										src={props.corporate.logo.url}
									/>
							  ),
						containerStyle: { overflowY: "scroll" },
						leftView: (
							<Price
								growthbook={props.growthbook}
								options={["monthly", "annually", "unique"]}
								animated={true}
								view={view}
								onSkipClick={() => setShowSkipModal(true)}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
										if (result.data.charge === "unique")
											setShowMug(false);
										else if (
											(result.data.charge ===
												"annually" &&
												result.data.price >= 200) ||
											(result.data.charge === "monthly" &&
												result.data.price >= 20)
										)
											setShowMug(false);
										else setShowMug(true);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightViewStyle: { overflowY: "scroll" },
						rightView: isMobile ? null : <PriceLeft />,
					},
					//Select payment method
					{
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: <></>,
						centralView:
							product && currentUser ? (
								<PaymentMethod
									animated={true}
									product={product}
									user={currentUser}
									corporate={props.corporate}
									view={view}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setDidSubscribe(true);
											wizard.current?.goNext();
										}
									}}
									onProductChange={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setProduct(result.data);
										}
									}}
								/>
							) : null,
						rightView: <></>,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						navigationRightView: props.corporate?.logo && (
							<CorporateLogo src={props.corporate.logo.url} />
						),
						leftView: currentUser ? (
							<>
								<Success
									animated={true}
									user={currentUser}
									letter={letter.object}
									grandpa={grandpa}
									didSubscribe={didSubscribe}
									corporate={props.corporate}
								>
									{isMobile ? (
										<SuccessRight
											animated={true}
											animationDelay={8600}
										/>
									) : null}
								</Success>
								<ArrowAnimationView>
									<ChevronDown />
								</ArrowAnimationView>
							</>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight
								animated={true}
								animationDelay={8600}
							/>
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {
	corporate?: CoorporateScheme;
	nLetters?: number;
	growthbook?: GrowthBook;
	prevLetter?: {
		content: string;
		grandpa: UserScheme;
	};
}
