import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
	AUA,
	Payment,
	PaymentMethod,
	CoorporateScheme,
	PaymentMethodScheme,
	UserScheme,
	ErrorScheme,
	Subscription,
	ProductScheme,
	Track,
	User,
	Product,
	ChallengeScheme,
	Challenge,
	SubscriptionScheme,
} from "client-v2";
import {
	loadStripe,
	PaymentRequest,
	PaymentRequestPaymentMethodEvent,
	SetupIntent,
} from "@stripe/stripe-js";
import media from "styled-media-query";
import Stripe from "stripe";
import ReactPixel from "react-facebook-pixel";
import SectionContainer from "../../container/SectionContainer";
import PaycardCell from "../../cell/PaycardCell";
import {
	Text,
	Payout,
	Modal,
	Input,
	ColorV2,
	Button,
} from "@adoptaunabuelo/react-components";
import { ShieldCheck, Lock, User as UserIcon, Landmark } from "lucide-react";
import PaycardLogo from "../../../assets/logo/Paycard";
import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const isScreen01: any = "1350px";
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	cursor: pointer;
	border-radius: 12px;
	margin-top: 4px;
	&:hover {
		background-color: ${ColorV2.surface.neutralSoft};
	}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const DataRow = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 32px 0px;
	padding-top: 18px;
	border-top: 1px solid ${ColorV2.border.neutralSoft};
	${media.lessThan("medium")`
		margin: 24px 0px 12px;
	`}
`;
const LockRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	${media.lessThan(isScreen01)`
        margin-top: 12px;
    `}
`;
const PaycardContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 12px;
	padding: 4px;
`;
const ButtonChildren = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
`;
const MugBanner = styled.div`
	display: none;
	background-color: ${ColorV2.surface.brown};
	border-radius: 12px;
	${media.lessThan("medium")`
		display: flex;
	`}
`;
const PresentImage = styled.img`
	width: 134px;
	object-fit: cover;
	object-position: left;
`;

const PaymentMethodStep = (
	props: Props &
		(
			| {
					product: ProductScheme;
					subscription?: SubscriptionScheme;
					canEdit?: undefined;
					challenge?: undefined;
					amount?: undefined;
			  }
			| {
					product?: undefined;
					subscription?: undefined;
					canEdit: "sepa" | "card";
					challenge?: undefined;
					amount?: undefined;
			  }
			| {
					product?: ProductScheme;
					subscription?: undefined;
					canEdit?: undefined;
					challenge: ChallengeScheme;
					amount?: number;
			  }
		)
) => {
	const payout = useRef<any>(null);
	const modal = useRef<any>(null);
	const stripeKey = AUA.getStripeKey();

	const appLocation = useSelector((state: State) => state.location.location);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodScheme[]>(
		[]
	);
	const [selectedPaycard, setSelectedPaycard] = useState<
		PaymentMethodScheme | undefined
	>(undefined);
	const [paymentIntent, setPaymentIntent] = useState<
		Stripe.PaymentIntent | undefined
	>(undefined);
	const [setupIntent, setSetupIntent] = useState<SetupIntent | undefined>(
		undefined
	);
	const [showAdd, setShowAdd] = useState(false);
	const [confirmUrl, setConfirmUrl] = useState<string | undefined>(undefined);
	const [showModal, setShowModal] = useState(false);
	const [paymentRequest, setPaymentRequest] = useState<
		PaymentRequest | undefined
	>(undefined);
	const [canUseApplePay, setCanUseApplePay] = useState<
		"apple_pay" | "google_pay" | undefined
	>(undefined);
	const [applePaySelection, setApplePaySelection] = useState<
		"apple_pay" | "google_pay" | undefined
	>(undefined);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [successProduct, setSuccessProduct] = useState(false);
	const [IBAN, setIBAN] = useState<{
		account: string | undefined;
		name: string | undefined;
	}>({
		account: undefined,
		name: undefined,
	});
	const [priceLeft, setPriceLeft] = useState(0);
	const canUseStripe = props.canEdit
		? props.canEdit === "sepa"
			? false
			: true
		: props.challenge && props.challenge.type !== "signature"
		? true
		: (appLocation && !appLocation.currency.hasSEPA) ||
		  props.product?.charge === "unique";

	window.addEventListener(
		"message",
		function (ev) {
			if (ev.data === "3DS-authentication-complete" && showModal) {
				modal.current.close();
			}
		},
		false
	);

	useEffect(() => {
		//Load Apple Pay and Google Pay
		if ((props.product || props.challenge) && canUseStripe) {
			loadStripe(stripeKey).then((stripe) => {
				if (stripe) {
					const paymentRequest = stripe.paymentRequest({
						currency: appLocation
							? appLocation.currency.name
							: "eur",
						country: appLocation ? appLocation.country_code : "ES",
						total: {
							label: "Donación Adopta Un Abuelo",
							amount: props.product
								? props.product.price
									? props.product.price * 100
									: 0
								: props.amount
								? props.amount * 100
								: 0,
						},
						requestPayerName: false,
						requestPayerEmail: false,
					});
					setPaymentRequest(paymentRequest);
					paymentRequest.canMakePayment().then((result) => {
						if (result && (result.applePay || result.googlePay)) {
							setCanUseApplePay(
								result.applePay
									? "apple_pay"
									: result.googlePay
									? "google_pay"
									: undefined
							);
							setShowAdd(false);
						}
					});
				}
			});
		} else {
			setCanUseApplePay(undefined);
		}
	}, [props.product]);

	useEffect(() => {
		if (props.product) {
			if (props.product.charge === "annually") {
				setPriceLeft(200 - props.product.price);
			} else if (props.product.charge === "monthly") {
				setPriceLeft(20 - props.product.price);
			}
		}
	}, []);

	useEffect(() => {
		getPaymentMethods();
	}, []);

	const getPaymentMethods = () => {
		setLoading(true);
		PaymentMethod.get({
			userId: props.user.objectId,
			types: canUseStripe ? ["paycard"] : ["sepa_debit"],
		})
			.then((result) => {
				setLoading(false);
				setPaymentMethods(result.data);
				if (result.data.length > 0) {
					setShowAdd(false);
					result.data.map((item) => {
						if (item.default) {
							setSelectedPaycard(item);
						}
					});
				} else {
					setShowAdd(true);
				}
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
				props.onFinish({ error: e.message });
			});
	};

	const updateProduct = async () => {
		if (
			props.product &&
			(props.product.charge === "annually" ||
				props.product.charge === "monthly")
		) {
			setLoading(true);
			Product.get({
				active: true,
				price:
					props.product.charge === "annually"
						? appLocation
							? appLocation.currency.price.annually.gift
							: 200
						: props.product.charge === "monthly"
						? appLocation
							? appLocation.currency.price.monthly.gift
							: 20
						: undefined,
				charge: props.product.charge,
			})
				.then((result) => {
					setLoading(false);
					setSuccessProduct(true);
					props.onProductChange &&
						props.onProductChange({ data: result.data[0] });
				})
				.catch((e: ErrorScheme) => {
					setLoading(false);
					props.onProductChange &&
						props.onProductChange({ error: e.message });
				});
		}
	};

	//1- Check if is a payment method update or a subscription init. Generate the new Payment Method if needed and go to step 2
	const onNextClick = async () => {
		setLoading(true);
		//Check if is a new IBAN
		if (IBAN.account && IBAN.name) {
			//check IBAN
			const ibanRegex =
				/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
			const ibanAccount = IBAN.account
				.replace(/\s/g, "")
				.replace("-", "")
				.toUpperCase();
			if (ibanRegex.test(ibanAccount)) {
				//Save the payment method
				PaymentMethod.initSetupIntent({
					userId: props.user.objectId,
					IBAN: ibanAccount,
					name: IBAN.name,
				})
					.then((result) => {
						const paymentMethod =
							result.data as PaymentMethodScheme;
						if (props.canEdit) {
							getPaymentMethods();
						} else {
							if (props.subscription) updateSubscription();
							else initSubscription(paymentMethod.objectId);
						}
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						props.onFinish({ error: e.message });
					});
			} else {
				setLoading(false);
				props.onFinish({
					error: "Parece que el número de cuenta que has introducido no es correcto.",
				});
			}
		} else {
			const generatedPaymentMethod =
				await payout.current?.getPaymentMethod();

			//Use Apple Pay or Google Pay
			if (applePaySelection && paymentRequest) {
				paymentRequest.show();
				paymentRequest.on("paymentmethod", async (ev) => {
					if (
						props.challenge &&
						props.challenge.type !== "signature"
					) {
						registerToChallenge(ev.paymentMethod.id, ev);
					} else if (props.subscription) updateSubscription();
					else initSubscription(ev.paymentMethod.id, ev);
				});
			}
			//Use the new added paycard
			else if (generatedPaymentMethod) {
				if (props.canEdit) {
					PaymentMethod.initSetupIntent({
						userId: props.user.objectId,
						cardId: generatedPaymentMethod.id,
						redirectUrl: window.location.origin + "/card-success",
					})
						.then((result) => {
							//Check if 3d secure needed
							const setupIntent = result.data as SetupIntent;
							confirmSetupIntent(setupIntent);
						})
						.catch((e: ErrorScheme) => {
							setLoading(false);
							props.onFinish({ error: e.message });
						});
				} else if (
					props.challenge &&
					props.challenge.type !== "signature"
				) {
					registerToChallenge(generatedPaymentMethod.id);
				} else if (props.subscription) updateSubscription();
				else initSubscription(generatedPaymentMethod.id);
			}
			//Use an old selected paycard
			else if (selectedPaycard) {
				if (props.challenge && props.challenge.type !== "signature")
					registerToChallenge(selectedPaycard.objectId);
				else if (props.subscription) updateSubscription();
				else if (!props.canEdit)
					initSubscription(selectedPaycard.objectId);
			} else {
				setLoading(false);
				props.onFinish({
					error: "Debes seleccionar un método de pago antes de continuar",
				});
			}
		}
	};

	//2- Init the subscription or payment intent. If need to verify the payment intent, go to step 3. If not, go to step 5
	const initSubscription = async (
		paymentMethodId: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		if (props.product) {
			if (props.product.type === "event") {
				registerToEvent(
					props.product,
					paymentMethodId,
					paymentRequestEvent
				);
			} else if (props.product.charge === "unique") {
				Track.event({
					event: "donation payment intent",
					user: props.user,
					params: {
						Product: "one-off",
						amount: props.product.price,
						view: props.view,
						Paycard: selectedPaycard?.brand,
					},
				});
				Payment.initPaymentIntent({
					userId: props.user.objectId,
					amount: props.product.price,
					currency: appLocation ? appLocation.currency.name : "eur",
					redirectUrl: window.location.origin + "/card-success",
					paymentMethod: paymentMethodId,
				})
					.then((result) => {
						//Check if 3d secure needed
						if (result.data.status === "requires_action") {
							confirmPaymentIntent(result.data);
						} else {
							onPaymentIntentConfirmed(
								undefined,
								paymentRequestEvent
							);
						}
					})
					.catch((e: ErrorScheme) => {
						onPaymentIntentConfirmed(e.message);
					});
			} else {
				//Track on mixpanel
				Track.event({
					event: "donation payment intent",
					user: props.user,
					params: {
						Product: props.product.objectId,
						amount: props.product.price,
						view: props.view,
					},
				});

				const initDate = moment().add(1, "month").startOf("month");
				Subscription.create({
					userId: props.user.objectId,
					productId: props.product.objectId,
					paymentMethod: paymentMethodId,
					currency: appLocation ? appLocation.currency.name : "eur",
					redirectUrl: window.location.origin + "/card-success",
					initDate: canUseStripe ? undefined : initDate.toDate(),
				})
					.then((result) => {
						const tempPaymentIntent: any = result.data;
						if (tempPaymentIntent.id) {
							confirmPaymentIntent(
								result.data as Stripe.PaymentIntent
							);
						} else {
							onPaymentIntentConfirmed(
								undefined,
								paymentRequestEvent
							);
						}
					})
					.catch((e: ErrorScheme) => {
						onPaymentIntentConfirmed(e.message);
					});
			}
		}
	};

	const registerToChallenge = async (
		paymentMethod: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		if (props.challenge && props.amount) {
			setLoading(true);
			Track.event({
				event: "donation payment intent",
				user: props.user,
				params: {
					Product: "one-off",
					amount: props.amount,
					view: props.view,
				},
			});

			Challenge.initPaymentIntent(props.challenge.objectId, {
				userId: props.user.objectId,
				amount: props.amount,
				currency: appLocation ? appLocation.currency.name : "eur",
				paymentMethod: paymentMethod,
				redirectUrl: window.location.origin + "/card-success",
			})
				.then((result) => {
					const tempPaymentIntent: any = result.data;
					if (tempPaymentIntent.id) {
						confirmPaymentIntent(
							result.data as Stripe.PaymentIntent
						);
					} else {
						onPaymentIntentConfirmed(
							undefined,
							paymentRequestEvent
						);
					}
				})
				.catch((e: ErrorScheme) => {
					onPaymentIntentConfirmed(e.message);
				});
		}
	};

	const registerToEvent = async (
		product: ProductScheme,
		paymentMethodId?: string,
		paymentRequestEvent?: PaymentRequestPaymentMethodEvent
	) => {
		setLoading(true);
		Track.event({
			event: "event register intent",
			user: props.user,
			params: {
				Product: product.objectId,
				amount: product.price,
				view: props.view,
			},
		});
		Product.initRegister(product.objectId, {
			userId: props.user.objectId,
			currency: appLocation ? appLocation.currency.name : "eur",
			redirectUrl: window.location.origin + "/card-success",
			paymentMethod: paymentMethodId ? paymentMethodId : undefined,
		})
			.then((result) => {
				if (product.price !== 0) {
					//Check if 3d secure needed
					const paymentIntent = result.data as Stripe.PaymentIntent;
					if (
						paymentIntent.status === "processing" ||
						paymentIntent.status === "succeeded"
					)
						onPaymentIntentConfirmed(
							undefined,
							paymentRequestEvent
						);
					else confirmPaymentIntent(paymentIntent);
				} else {
					onPaymentIntentConfirmed();
				}
			})
			.catch((error: ErrorScheme) => {
				onPaymentIntentConfirmed(error.message);
			});
	};

	const updateSubscription = () => {
		if (props.subscription && props.product) {
			Subscription.set(props.subscription.objectId, {
				productId: props.product.objectId,
			})
				.then((result) => {
					onPaymentIntentConfirmed();
				})
				.catch((e) => {
					onPaymentIntentConfirmed(e.message);
				});
		}
	};

	//3- Open modal to confirm payment intent in needed. If not, go to step 5
	const confirmPaymentIntent = async (
		paymentIntent: Stripe.PaymentIntent
	) => {
		setPaymentIntent(paymentIntent);
		if (paymentIntent.status === "requires_action") {
			if (
				paymentIntent.next_action &&
				paymentIntent.next_action.redirect_to_url &&
				paymentIntent.next_action.redirect_to_url.url
			) {
				setConfirmUrl(paymentIntent.next_action.redirect_to_url.url);
				setShowModal(true);
			}
		} else {
			onPaymentIntentConfirmed();
		}
	};

	const confirmSetupIntent = async (setupIntent: SetupIntent) => {
		setSetupIntent(setupIntent);
		if (setupIntent.status === "requires_action") {
			if (
				setupIntent.next_action &&
				setupIntent.next_action.redirect_to_url &&
				setupIntent.next_action.redirect_to_url.url
			) {
				setConfirmUrl(setupIntent.next_action.redirect_to_url.url);
				setShowModal(true);
			}
		} else {
			onSetupIntentConfirmed();
		}
	};

	//4- On confirmation modal closed. Review payment intent status
	const onConfirmModalClose = async () => {
		if (paymentIntent) {
			setShowModal(false);
			Payment.finishPaymentIntent(paymentIntent.id)
				.then((result) => {
					if (
						result.data.status === "succeeded" ||
						result.data.status === "processing"
					) {
						onPaymentIntentConfirmed();
					} else {
						onPaymentIntentConfirmed(
							"Parece que hay un error con tus datos. Comprueba que has añadido todo de manera correcta."
						);
					}
				})
				.catch((error: ErrorScheme) => {
					onPaymentIntentConfirmed(error.message);
				});
		} else {
			setLoading(false);
		}
	};

	//5- On confirmation finished.
	const onPaymentIntentConfirmed = (
		error?: string,
		ev?: PaymentRequestPaymentMethodEvent
	) => {
		if (props.product || props.challenge) {
			if (error) {
				if (props.product?.type === "event") {
					//Track on mixpanel
					Track.event({
						event: "event register failed",
						user: props.user,
						params: {
							Product: props.product.objectId,
							amount: props.product.price,
							view: props.view,
						},
					});
				} else {
					//Track on mixpanel
					Track.event({
						event: "donation activation failed",
						user: props.user,
						params: {
							Product: props.product
								? props.product.charge === "unique"
									? "one-off"
									: props.product.objectId
								: "unique",
							amount: props.product
								? props.product.price
								: props.amount,
							view: props.view,
							error: error,
						},
					});
				}

				if (ev) ev.complete("fail");

				setLoading(false);
				props.onFinish({
					error: error,
				});
			} else {
				if (props.product?.type === "event") {
					//Track on mixpanel
					Track.event({
						event: "event register done",
						user: props.user,
						params: {
							Product: props.product.objectId,
							amount: props.product.price,
							view: props.view,
						},
					});
				} else {
					//Track on Facebook
					const trackTitle = props.product
						? props.product.charge === "annually"
							? "SocioAnual"
							: props.product.charge === "monthly"
							? "SocioMensual"
							: "SocioPuntual"
						: "SocioPuntual";
					const customerType = props.product
						? props.product.charge === "annually"
							? "Anual"
							: props.product.charge === "monthly"
							? "Mensual"
							: "Puntual"
						: "Puntual";
					ReactPixel.trackCustom(trackTitle, {
						value: props.product
							? props.product.price
							: props.amount,
						currency: appLocation
							? appLocation.currency.name
							: "eur",
						product_id: props.product
							? props.product.objectId
							: "Puntual",
						product_name: props.product
							? props.product.title
							: "Puntual",
						customer_type: customerType,
					});

					//Track on mixpanel
					Track.event({
						event: "donation activation done",
						user: props.user,
						params: {
							Product: props.product
								? props.product.charge === "unique"
									? "one-off"
									: props.product.objectId
								: "one-off",
							amount: props.product
								? props.product.price
								: props.amount,
							view: props.view,
							currency: appLocation
								? appLocation.currency.name.toUpperCase()
								: "EUR",
						},
					});
				}
				const transactionId = moment().format(
					props.user.objectId + "-mm:ss-DD/MM/YYYY"
				);
				Track.charge(
					transactionId,
					props.product
						? {
								objectId: props.product.objectId,
								name: props.product.title,
								price: props.product.price,
								currency: appLocation
									? appLocation.currency.name.toUpperCase()
									: "EUR",
						  }
						: {
								objectId: "unique",
								name: "Pago único",
								price: props.amount ? props.amount : 0,
								currency: appLocation
									? appLocation.currency.name.toUpperCase()
									: "EUR",
						  }
				);

				if (ev) ev.complete("success");

				//Update user
				User.set(props.user.objectId, {
					internalData: {
						tags: "payment-donation",
					},
				});

				setLoading(false);
				setSuccess(true);
			}
		}
	};

	const onSetupIntentConfirmed = () => {
		setShowModal(false);
		setLoading(false);
		setShowAdd(false);
		getPaymentMethods();
	};

	const onPaymentMethodClick = async (paymentMethod: PaymentMethodScheme) => {
		setLoading(true);
		PaymentMethod.setDefault(paymentMethod.objectId, {
			userId: props.user.objectId,
		})
			.then((result) => {
				setLoading(false);
				getPaymentMethods();
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
				props.onFinish({ error: e.message });
			});
	};

	return (
		<SectionContainer
			title={"Método de pago"}
			animated={props.animated}
			buttonProps={
				props.canEdit && !showAdd
					? undefined
					: {
							style: {
								backgroundColor: success
									? ColorV2.surface.green
									: applePaySelection
									? "black"
									: ColorV2.surface.primaryHard,
							},
							children: applePaySelection ? (
								<ButtonChildren>
									{"Donar con"}
									<PaycardLogo
										brand={applePaySelection + "_button"}
									/>
								</ButtonChildren>
							) : props.subscription ? (
								"Actualizar mi donación"
							) : props.product ? (
								"Colaborar con " +
								props.product.price +
								" " +
								(appLocation
									? appLocation.currency.symbol
									: "€") +
								(props.product.charge === "monthly"
									? " al mes"
									: props.product.charge === "annually"
									? " al año"
									: "")
							) : props.challenge &&
							  props.challenge.type !== "signature" ? (
								"Colaborar con " +
								props.amount +
								(appLocation
									? appLocation.currency.symbol
									: "€")
							) : (
								"Guardar"
							),
							loading: loading,
							success: success,
							onSuccess: () => {
								setSuccess(false);
								props.onFinish({ data: "ok" });
							},
							onClick: onNextClick,
					  }
			}
		>
			<Modal
				type="web"
				contentStyle={{ padding: 0, height: "100%" }}
				ref={modal}
				isVisible={showModal}
				hideClose={true}
				hideHeader={true}
				url={confirmUrl}
				style={{ width: 600, height: "100%", padding: 0 }}
				onClose={() => onConfirmModalClose()}
			/>
			{showAdd ? (
				<>
					{canUseStripe ? (
						<Payout
							ref={payout}
							stripeKey={stripeKey}
							paymentOption={"card"}
							design={"secondary"}
						/>
					) : (
						<Col style={{ gap: 8 }}>
							<Input
								type="text"
								design="secondary"
								placeholder="Nombre del titular"
								icon={
									<UserIcon
										height={18}
										width={18}
										color={ColorV2.border.neutralHigh}
									/>
								}
								onChange={(e) =>
									setIBAN({
										...IBAN,
										name: e.target.value,
									})
								}
							/>
							<Input
								type="text"
								design="secondary"
								placeholder="Número de cuenta bancaria"
								icon={
									<Landmark
										height={18}
										width={18}
										color={ColorV2.border.neutralHigh}
									/>
								}
								onChange={(e) =>
									setIBAN({
										...IBAN,
										account: e.target.value,
									})
								}
							/>
						</Col>
					)}
				</>
			) : (
				<PaycardContainer>
					{paymentMethods.map((item) => (
						<PaycardCell
							key={"paycard-" + item.objectId}
							brand={item.brand}
							last4={item.last4}
							name={item.name}
							expDate={
								item.expDate ? item.expDate.iso : undefined
							}
							default={applePaySelection ? false : item.default}
							color={
								props.corporate &&
								props.corporate.color &&
								props.corporate.color.main
							}
							onClick={() => {
								setApplePaySelection(undefined);
								onPaymentMethodClick(item);
							}}
						/>
					))}
					{canUseApplePay && (
						<PaycardCell
							key={canUseApplePay}
							brand={canUseApplePay}
							last4={"Pay"}
							default={applePaySelection ? true : false}
							color={
								props.corporate &&
								props.corporate.color &&
								props.corporate.color.main
							}
							onClick={() => {
								setApplePaySelection(canUseApplePay);
							}}
						/>
					)}
					<Cell onClick={() => setShowAdd(!showAdd)}>
						<Text
							type="p"
							style={{
								color: props.corporate?.color
									? props.corporate.color.main
									: ColorV2.text.primary,
								fontSize: 24,
								height: 36,
								borderRadius: 6,
								width: 36,
								backgroundColor: ColorV2.surface.neutralSoft,
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
							}}
						>
							+
						</Text>
						<Text
							type="p"
							weight="medium"
							style={{
								color: props.corporate?.color
									? props.corporate.color.main
									: ColorV2.text.primary,
								marginLeft: 16,
							}}
						>
							Añadir nuevo método de pago
						</Text>
					</Cell>
				</PaycardContainer>
			)}
			<DataRow>
				{canUseStripe && (
					<Row style={{ gap: 4, marginBottom: 16 }}>
						<PaycardLogo
							brand={"visa"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"mastercard"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"amex"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"apple_pay"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
						<PaycardLogo
							brand={"google_pay"}
							style={{ height: 24, width: 36, borderRadius: 6 }}
						/>
					</Row>
				)}
				{props.showPresent && (
					<MugBanner>
						<Col style={{ padding: "16px 0px 24px 16px", gap: 12 }}>
							<Text type="p" weight="semibold">
								Aumenta tu donación {priceLeft}€ y llévate una
								taza única de Miki Nöelle.
							</Text>
							<Button
								size="small"
								loading={loading}
								success={successProduct}
								style={{
									backgroundColor: successProduct
										? ColorV2.surface.green
										: "white",
									color: ColorV2.text.neutralHard,
									width: "fit-content",
								}}
								onClick={updateProduct}
							>
								Quiero la taza
							</Button>
						</Col>
						<PresentImage
							src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
						/>
					</MugBanner>
				)}
				<Col style={{ flex: 1, justifyContent: "flex-end" }}>
					<LockRow>
						<ShieldCheck
							style={{ marginRight: 8, marginTop: -2 }}
							height={20}
							width={20}
							color={ColorV2.border.neutralHigh}
						/>
						<Text
							type="p2"
							style={{ color: ColorV2.border.neutralHigh }}
						>
							Pago seguro garantizado
						</Text>
					</LockRow>
					<LockRow style={{ marginTop: 12 }}>
						<Lock
							style={{ marginRight: 8, marginTop: -2 }}
							height={20}
							width={20}
							color={ColorV2.border.neutralHigh}
						/>
						<Text
							type="p2"
							style={{ color: ColorV2.border.neutralHigh }}
						>
							Encriptación SSL protegida
						</Text>
					</LockRow>
					{props.product && props.product.charge !== "unique" && (
						<Text
							type="c1"
							style={{
								color: ColorV2.text.neutralMedium,
								marginTop: 16,
							}}
						>
							Tu donación se renovará automaticamente cada{" "}
							{props.product.charge === "annually"
								? "año"
								: "mes"}
							. Puedes cancelarla en cualquier momento.
						</Text>
					)}
				</Col>
			</DataRow>
		</SectionContainer>
	);
};
export default PaymentMethodStep;
export interface Props {
	animated?: boolean;
	user: UserScheme;
	view: string;
	corporate?: CoorporateScheme;
	showPresent?: boolean;
	onFinish: (result: { data?: string; error?: string }) => void;
	onProductChange?: (result: {
		data?: ProductScheme;
		error?: string;
	}) => void;
}
