import { CSSProperties, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { User, UserScheme } from "client-v2";
import LetterIcon from "../../../assets/images/letter/Icon";

import {
	Button,
	ColorV2,
	Text,
	Modal,
	Input,
} from "@adoptaunabuelo/react-components";

const Section = styled.div`
	display: flex;
	flex-direction: row;
	padding: 24px;
	background-color: ${ColorV2.surface.secondary};
	border-radius: 12px;
	gap: 16px;
	${media.lessThan("small")`
        padding: 16px;
        gap: 12px;
    `}
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Icon = styled.div`
	display: flex;
	width: 64px;
	height: 64px;
	justify-content: center;
	align-items: center;
`;

const Relief = (props: Props) => {
	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";

	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [idCard, setIdCard] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [location, setLocation] = useState<LocationObjProps | undefined>(
		undefined
	);

	const saveIdCard = () => {
		if (location && idCard) {
			if (validateCardId(idCard)) {
				setLoading(true);
				User.set(props.user.objectId, {
					idCard: idCard,
					...location,
				})
					.then((result) => {
						setLoading(false);
						setShowModal(false);
					})
					.catch((e: string) => {
						setError(e);
						setLoading(false);
					});
			} else {
				setError("El documento introducido no es válido.");
			}
		} else {
			setError(
				"Debes añadir un documento y una dirección fiscal antes de continuar."
			);
		}
	};

	const validateCardId = (id: string) => {
		var DNI_REGEX = /^(\d{8})([A-Z])$/;
		var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

		// Ensure upcase and remove whitespace
		const str = id.toUpperCase().replace(/\s/, "");

		//Check the id type
		if (str.match(DNI_REGEX)) {
			var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
			var letter = dni_letters.charAt(parseInt(str, 10) % 23);
			return letter == str.charAt(8);
		} else if (str.match(NIE_REGEX)) {
			// Change the initial letter for the corresponding number and validate as DNI
			var nie_prefix = str.charAt(0);
			switch (nie_prefix) {
				case "X":
					nie_prefix = "0";
					break;
				case "Y":
					nie_prefix = "1";
					break;
				case "Z":
					nie_prefix = "2";
					break;
			}
			const tempStr = nie_prefix + str.substr(1);

			//Validate NIE as DNI
			var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
			var letter = dni_letters.charAt(parseInt(tempStr, 10) % 23);
			return letter == tempStr.charAt(8);
		} else return false;
	};

	const onLocationChange = async (item: LocationObjProps) => {
		setError("");
		setLocation(item);
	};

	return (
		<>
			<Modal
				isVisible={showModal}
				title={"Añadir información fiscal"}
				subtitle={
					"Asegúrate de que los datos introducidos sean correctos."
				}
				error={error}
				buttonProps={{
					children: "Guardar",
					loading: loading,
					onClick: saveIdCard,
				}}
				onClose={() => setShowModal(false)}
			>
				<Input
					containerStyle={{ margin: "4px 0px 16px" }}
					type={"location"}
					googleAPIKey={googleAPIKey}
					placeholder="Dirección fiscal"
					design="secondary"
					onLocationChange={onLocationChange}
				/>
				<Input
					containerStyle={{ margin: "0px 0px 32px" }}
					type="text"
					placeholder="DNI o NIE"
					design="secondary"
					onChange={(e) => {
						setError(undefined);
						setIdCard(e.target.value);
					}}
				/>
			</Modal>
			<Section style={props.style}>
				<Col>
					<Text
						type="h5"
						weight="semibold"
						style={{ marginBottom: 8 }}
					>
						Recupera hasta el 80% de tus donaciones
					</Text>
					<Text type="p2">
						Añade tu información fiscal para que puedas desgravarte
						hasta el 80% de tu donación en la próxima declaración de
						la Renta.
					</Text>
					<Button
						style={{
							width: "fit-content",
							marginTop: 16,
							color: ColorV2.text.neutralHard,
							height: 42,
							border: "1px solid " + ColorV2.text.neutralHard,
						}}
						design="secondary"
						size="small"
						onClick={() => setShowModal(true)}
					>
						Añadir información
					</Button>
				</Col>
				<Col style={{ flex: 0, justifyContent: "center" }}>
					<Icon>
						<LetterIcon type="02" />
					</Icon>
				</Col>
			</Section>
		</>
	);
};
export default Relief;
export interface Props {
	style?: CSSProperties;
	user: UserScheme;
}

interface LocationObjProps {
	address?: string;
	sortAddress?: string;
	route?: string;
	routeNumber?: string;
	routeInfo?: string;
	city?: string;
	province?: string;
	zipCode?: string;
	country?: string;
	location?: google.maps.LatLngLiteral;
	timeZone?: string;
}
