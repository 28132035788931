import styled from "styled-components";
import media from "styled-media-query";
import SectionContainer, { SectionContainerProps } from "./SectionContainer";

const isScreen01: any = "1400px";
const SectionContainerStyled = styled(SectionContainer)`
	width: -webkit-fill-available;
	width: -moz-available;
	margin-right: calc(100% / 6);
	margin-left: calc(100% / 6);
	padding-top: 48px;
	${media.lessThan(isScreen01)`
		margin-right: calc(100% / 8);
		margin-left: calc(100% / 8);
    `}
	${media.lessThan("medium")`
		width: -webkit-fill-available;
		width: -moz-available;
		margin-right: 0px;
		margin-left: 0px;
		padding-left: 0px;
		padding-right: 0px;
	`}
`;

const RightSectionContainer = (props: RightSectionContainerProps) => {
	return (
		<SectionContainerStyled {...props}>
			{props.children}
		</SectionContainerStyled>
	);
};
export default RightSectionContainer;
export interface RightSectionContainerProps extends SectionContainerProps {}
