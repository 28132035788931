import { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CoorporateScheme, LetterScheme, UserScheme } from "client-v2";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import { Button, Color, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { Download, Instagram } from "lucide-react";
import InstagramModal from "./InstagramModal";
import Relief from "../../../components/section/donation/Relief";
import LetterSuccessAnimation from "../../../assets/animations/letter_success.json";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";

const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const RevCol = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	${media.lessThan("medium")`
        flex-direction: column;
    `}
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 13px !important;
	height: 24px;
	width: 24px;
	border-radius: 30px;
	border: 2px solid;
	color: ${(props) =>
		props.color ? props.color : ColorV2.text.neutralHard} !important;
`;
const translateY = window.innerHeight / 2 - 100 - 56; //half window height- half animation height - nav bar height
const AnimationView = styled.div<{
	$showAnimation: boolean;
	$moveAnimation: boolean;
}>`
	transform: ${(props) =>
		props.$moveAnimation
			? "translate(0px, 0px)"
			: `translate(calc(100% - 100px), ${translateY}px)`};
	opacity: ${(props) => (props.$showAnimation ? 1 : 0)};
	transition: opacity 0.6s ease-in, transform 1.8s ease-out;
	${(props) => media.lessThan("medium")`
		transform: ${
			props.$moveAnimation
				? "translateY(0px)"
				: `translateY(${translateY}px)`
		};
    `}
`;
const DataView = styled.div<{ $showContent: boolean }>`
	transform: ${(props) =>
		props.$showContent ? "translateY(0px)" : "translateY(20px)"};
	opacity: ${(props) => (props.$showContent ? 1 : 0)};
	transition: opacity 0.6s ease-in, transform 0.6s ease-out;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [loadingIG, setLoadingIG] = useState(false);
	const [successPDF, setSuccessPDF] = useState(false);
	const [showIG, setShowIG] = useState(false);

	const startMovingTime = 6400;
	const movingTime = 2200;
	const [moveAnimation, setMoveAnimation] = useState(false);
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		setLoadingPDF(false);
		setLoadingIG(false);
		setSuccessPDF(false);
	}, []);

	useEffect(() => {
		//Animation movement activation
		const timer = setTimeout(() => setMoveAnimation(true), startMovingTime);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		// When movement completed, show view data
		if (moveAnimation) {
			const timer = setTimeout(() => {
				setShowContent(true);
			}, movingTime);
			return () => clearTimeout(timer);
		}
	}, [moveAnimation]);

	const toBase64 = async (url: string): Promise<string> => {
		const response = await fetch(url);
		const blob = await response.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	};

	const downloadLetter = async () => {
		if (props.letter) {
			setLoadingPDF(true);
			const response = await fetch(
				`${process.env.PUBLIC_URL}/assets/letter/letter.html`
			);
			let html = await response.text();
			html = html.replace("{{content}}", props.letter.content);
			html = html.replace(
				"{{name}}",
				`${props.user.name} ${props.user.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(props.user.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					props.letter.customization
						? props.letter.customization.font
						: "Poppins"
				}";`
			);
			if (props.corporate) {
				html = html.replace(
					"{{stamp_img}}",
					"./assets/letter/stamp_empty.png"
				);
				let logoBase64 = "";
				if (props.corporate.logo?.url) {
					logoBase64 = await toBase64(props.corporate.logo?.url);
				}
				html = html.replace(
					"{{corporate_logo}}",
					`<img src="${logoBase64}"/>`
				);
			} else {
				html = html.replace(
					"{{stamp_img}}",
					"./assets/letter/stamp_retro.png"
				);
				html = html.replace("{{corporate_logo}}", "");
			}
			if (props.letter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (props.letter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (props.letter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${props.letter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			//Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoadingPDF(false);
				});
		}
	};

	return (
		<LeftSectionContainer
			animated={props.animated}
			style={{ paddingTop: 0 }}
		>
			{props.grandpa && (
				<InstagramModal
					isVisible={showIG}
					grandpa={props.grandpa}
					coorporate={props.corporate}
					onClose={() => setShowIG(false)}
				/>
			)}
			<AnimationView $showAnimation={true} $moveAnimation={moveAnimation}>
				<Player
					style={{
						width: isMobile ? 150 : 200,
						height: isMobile ? 150 : 200,
					}}
					keepLastFrame={true}
					autoplay={true}
					src={LetterSuccessAnimation}
				/>
			</AnimationView>
			<DataView $showContent={showContent}>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Muchas gracias, {props.user.name}!
				</Text>
				<Text type="p" style={{ marginTop: 12, textAlign: "center" }}>
					Hemos recibido tu carta. Gracias a gente como tú hacemos un
					poquito más felices a nuestros mayores.
				</Text>
				{props.letter && (
					<RevCol
						style={{
							alignItems: "center",
							gap: 8,
							marginTop: 48,
						}}
					>
						<Button
							design={"secondary"}
							size="small"
							icon={<Download color={ColorV2.text.neutralHard} />}
							style={{
								width: "100%",
								color: ColorV2.text.neutralHard,
							}}
							color={ColorV2.text.neutralHard}
							loading={loadingPDF}
							success={successPDF}
							onClick={downloadLetter}
						>
							Descarga mi carta
						</Button>
						<Button
							design={"secondary"}
							size="small"
							icon={
								<Instagram color={ColorV2.text.neutralHard} />
							}
							style={{
								width: "100%",
								color: ColorV2.text.neutralHard,
							}}
							color={ColorV2.text.neutralHard}
							loading={loadingIG}
							onClick={() => setShowIG(true)}
						>
							Compartir en Stories
						</Button>
					</RevCol>
				)}
				{appLocation &&
					appLocation.country_code === "ES" &&
					props.didSubscribe &&
					props.user &&
					!props.user.idCard &&
					!props.user.zipCode && (
						<Relief style={{ marginTop: 32 }} user={props.user} />
					)}
				<Section>
					<Text
						type="h4"
						weight="semibold"
						style={{ marginBottom: 24, textAlign: "center" }}
					>
						¿Y ahora qué?
					</Text>
					<HowWork>
						<Row>
							<Numbers type="p" weight="semibold">
								1
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									Revisamos todas las cartas
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Recopilamos las cartas y las leemos una a
									una para asegurarnos de que las cartas son
									adecuadas.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								2
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									Las enviamos a las residencias
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Una vez organizadas las cartas, se envían
									directas a las residencias para que las
									impriman y se la entreguen en mano a los
									abuelos.
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								3
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									¡Día de lectura!
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Organizamos eventos para leer vuestras
									cartas en las residencias, ¡atento a
									nuestras redes para inscribirte!
								</Text>
							</Col>
						</Row>
						<Row>
							<Numbers type="p" weight="semibold">
								4
							</Numbers>
							<Col>
								<Text type="p" weight="medium">
									Carta recibida
								</Text>
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										marginTop: 4,
									}}
								>
									Te enviaremos un email confirmando que tu
									abuelo ha leído tu carta.
								</Text>
							</Col>
						</Row>
					</HowWork>
				</Section>
				{props.children}
			</DataView>
		</LeftSectionContainer>
	);
};
export default Success;
export interface Props {
	children?: React.ReactNode;
	animated?: boolean;
	letter?: LetterScheme;
	corporate?: CoorporateScheme;
	grandpa?: UserScheme;
	user: UserScheme;
	didSubscribe?: boolean;
	onNextClick?: () => void;
}
