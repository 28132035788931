import { useEffect, useState } from "react";
import styled from "styled-components";
import { Track, UserScheme, EventScheme, ProductScheme } from "client-v2";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { State } from "../../redux";

import Header from "../../components/section/Header";
import Resume from "./section/Resume";
import Info from "./section/Info";
import { Text, Color, Label, ColorV2 } from "@adoptaunabuelo/react-components";
import Container from "../../components/container/Container";

const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

const Detail = (props: Props) => {
	const loading = useSelector((state: State) => state.modals.loading);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const navigate = useNavigate();
	const [pastDue, setPastDue] = useState(false);

	useEffect(() => {
		//Track to Mixpanel
		Track.event({
			event: "event view init",
			user: currentUser,
			params: {
				view: "event",
			},
		});

		const pastDue = props.product.startDate
			? moment() > moment(props.product.startDate.iso)
			: false;
		setPastDue(pastDue);
	}, []);

	const onNextClick = () => {
		Track.event({
			event: "event progress init",
			user: currentUser,
			params: {
				view: "event",
			},
		});
		navigate("/evento/registro?id=" + props.product.objectId, {
			state: {
				event: props.event,
				product: props.product,
			},
		});
	};

	return (
		<Container
			loading={loading}
			headerProps={{
				user: currentUser,
				transparent: true,
			}}
			RightView={
				<Resume
					event={props.event}
					product={props.product}
					onClick={onNextClick}
				/>
			}
			footerProps={{
				style: {
					backgroundColor: "white",
					borderTop: "1px solid " + ColorV2.border.neutralSoft,
				},
				buttonProps: {
					children: "Reservar",
					disabled: pastDue,
					onClick: onNextClick,
				},
				childrenPosition: "left",
				children: (
					<Col style={{ marginRight: 8 }}>
						{pastDue ? (
							<Label
								text="Evento finalizado"
								color={Color.status.color.error}
								style={{
									backgroundColor:
										Color.status.color.errorDefault,
									marginBottom: 4,
								}}
							/>
						) : (
							<Text type="p" weight="medium">
								{props.product.price > 0
									? props.product.price + "€ por persona"
									: "Evento gratuito"}
							</Text>
						)}
						<Text type="p2" style={{ color: Color.text.high }}>
							{moment(props.product.startDate?.iso).format(
								"ddd DD MMM"
							)}{" "}
							{moment(props.product.startDate?.iso).format(
								"HH:mm"
							)}
						</Text>
					</Col>
				),
			}}
		>
			<Helmet>
				<title>{props.product.title + " | Adopta Un Abuelo"}</title>
				<meta
					name="description"
					content={props.event.description.substring(0, 150)}
				/>
				<meta name="keywords" content={"adopta, un, abuelo, evento"} />
				<meta
					property="og:title"
					content={props.product.title + " | Adopta Un Abuelo"}
				/>
				<meta
					property="og:description"
					content={props.event.description.substring(0, 150)}
				/>
				<meta property="og:image" content={props.event.image?.url} />
				<meta
					property="og:url"
					content={
						"https://adoptaunabuelo.org/evento/?id=" +
						props.product.objectId
					}
				/>
			</Helmet>
			<Header
				title={props.product.title}
				image={props.event.image.url}
				video={props.event.video}
			/>
			<Info event={props.event} product={props.product} />
		</Container>
	);
};
export default Detail;
export interface Props {
	product: ProductScheme;
	event: EventScheme;
}
