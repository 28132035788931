import { useRef } from "react";
import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Profile from "./Profile";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionsCreators } from "../../redux";
import Success from "./Success";

const ProfileWizard = (props: ProfileProps) => {
	const wizard = useRef<WizardContainerRef>(null);
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);

	return (
		<WizardContainer
			ref={wizard}
			views={[
				{
					leftView: <></>,
					centralView: (
						<Profile
							onFinish={(result) => {
								if (result.data) {
									wizard.current?.goNext();
								} else if (result.error) {
									setShowError({
										show: true,
										message: result.error,
									});
								}
							}}
						/>
					),
					rightView: <></>,
				},
				{
					leftView: <></>,
					centralView: <Success />,
					rightView: <></>,
				},
			]}
		/>
	);
};
export default ProfileWizard;
export interface ProfileProps {}
