import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../redux";

import IbanWizard from "../views/iban/IbanWizard";
import Loading from "../components/modal/Loading";

const PaycardLayout = (props: PayoutLayoutProps) => {
	const loading = useSelector((state: State) => state.modals.loading);

	return (
		<>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route path="/" element={<IbanWizard />} />
			</Routes>
		</>
	);
};

export default PaycardLayout;
export interface PayoutLayoutProps {}
