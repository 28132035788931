import { useState } from "react";
import styled from "styled-components";
import {
	CoorporateScheme,
	ErrorScheme,
	Regex,
	User,
	UserScheme,
} from "client-v2";
import media from "styled-media-query";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import SectionContainer from "../../container/SectionContainer";
import {
	Input,
	Text,
	Checkbox,
	Color,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import moment from "moment";

const TermsText = styled(Text)`
	font-size: 13px !important;
	color: ${Color.text.high};
`;
const Link = styled.a`
	color: ${Color.text.high};
	&:hover {
		text-decoration: underline;
	}
`;
const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 8px;
`;
const CheckboxView = styled.div`
	display: flex;
	flex: 1;
	align-items: flex-end;
	padding-top: 8px;
	${media.lessThan("medium")`
        padding-bottom: unset;
	`}
`;

const Personal = (props: PersonalProps) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const colors = {
		buttonBackground: ColorV2.surface.primaryHard,
		buttonColor: "white",
		animation: ["#00315C", "#005AA8"],
	};

	const [selection, setSelection] = useState({
		name: props.user.name,
		surname: props.user.surname,
		email: props.user.email,
		terms: false,
		birthday: props.user.birthday
			? new Date(props.user.birthday.iso)
			: undefined,
	});
	const [errors, setErrors] = useState<{
		name: string | undefined;
		surname: string | undefined;
		email: string | undefined;
		terms: string | undefined;
		birthday: string | undefined;
	}>({
		name: undefined,
		surname: undefined,
		email: undefined,
		terms: undefined,
		birthday: undefined,
	});
	const [loading, setLoading] = useState(false);

	const onNextClick = () => {
		if (
			selection.name &&
			selection.surname &&
			selection.email &&
			selection.terms
		) {
			if (props.showBirthday && !selection.birthday) {
				setErrors({
					...errors,
					birthday: !selection.birthday
						? translation.component_input_birth_error
						: undefined,
				});
			} else {
				const emailIsValid = Regex.email(selection.email);
				if (emailIsValid) {
					setErrors({
						name: undefined,
						surname: undefined,
						email: undefined,
						terms: undefined,
						birthday: undefined,
					});
					setLoading(true);
					User.set(props.user.objectId, {
						name: selection.name,
						surname: selection.surname,
						email: selection.email,
						birthday: selection.birthday,
					})
						.then((result) => {
							setLoading(false);
							props.onFinish({ data: result.data });
						})
						.catch((e: ErrorScheme) => {
							setLoading(false);
							props.onFinish({ error: e.message });
						});
				} else {
					if (!emailIsValid)
						setErrors({
							...errors,
							email: translation.component_input_email_error_02,
						});
					return undefined;
				}
			}
		} else {
			setErrors({
				name: !selection.name
					? translation.component_input_name_error
					: undefined,
				surname: !selection.surname
					? translation.component_input_surname_error
					: undefined,
				email: !selection.email
					? translation.component_input_email_error
					: undefined,
				terms: !selection.terms
					? translation.component_input_terms_error
					: undefined,
				birthday:
					props.showBirthday && !selection.birthday
						? translation.component_input_birth_error
						: undefined,
			});
			return undefined;
		}
	};

	const onInputChange = (input: string, value: string) => {
		if (input === "name") {
			setSelection({
				...selection,
				name: value,
			});
			setErrors({
				...errors,
				name: undefined,
			});
		} else if (input === "surname") {
			setSelection({
				...selection,
				surname: value,
			});
			setErrors({
				...errors,
				surname: undefined,
			});
		} else if (input === "email") {
			setSelection({
				...selection,
				email: value,
			});
			setErrors({
				...errors,
				email: undefined,
			});
		} else if (input === "birthday") {
			setSelection({
				...selection,
				birthday: new Date(value),
			});
			setErrors({
				...errors,
				birthday: undefined,
			});
		}
	};

	const onTermsChange = (check: Array<any>) => {
		setErrors({
			...errors,
			terms: undefined,
		});
		if (check.length > 0)
			setSelection({
				...selection,
				terms: true,
			});
		else
			setSelection({
				...selection,
				terms: false,
			});
	};

	return (
		<SectionContainer
			title="Tus datos"
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				loading: loading,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<Container>
				<Input
					type="text"
					defaultValue={selection.name}
					placeholder={translation.component_input_name_placeholder}
					error={errors.name}
					design={"secondary"}
					onChange={(e: any) => onInputChange("name", e.target.value)}
				/>
				<Input
					type="text"
					defaultValue={selection.surname}
					placeholder={
						translation.component_input_surname_placeholder
					}
					error={errors.surname}
					design={"secondary"}
					onChange={(e: any) =>
						onInputChange("surname", e.target.value)
					}
				/>
				<Input
					type="email"
					defaultValue={selection.email}
					placeholder={translation.component_input_email_placeholder}
					error={errors.email}
					icon={undefined}
					design={"secondary"}
					onChange={(e: any) =>
						onInputChange("email", e.target.value)
					}
				/>
				{props.showBirthday && (
					<Input
						type="date"
						defaultValue={
							selection.birthday
								? moment(selection.birthday).format(
										"DD/MM/YYYY"
								  )
								: undefined
						}
						placeholder={
							translation.component_input_birthday_placeholder
						}
						error={errors.birthday}
						icon={undefined}
						design={"secondary"}
						onChange={(e: any) => onInputChange("birthday", e)}
					/>
				)}
				<CheckboxView>
					<Checkbox
						elementStyle={{ padding: 0, margin: 0 }}
						options={[
							{
								id: "terms",
								Element: (
									<TermsText type="p">
										{
											translation.component_input_terms_text_01
										}
										<Link
											href="https://www.adoptaunabuelo.org/terminos-condiciones"
											target="_blank"
										>
											{
												translation.component_input_terms_text_02
											}
										</Link>{" "}
										{
											translation.component_input_terms_text_03
										}
										<Link
											href="https://www.adoptaunabuelo.org/politica-privacidad"
											target="_blank"
										>
											{
												translation.component_input_terms_text_04
											}
										</Link>
									</TermsText>
								),
								error: errors.terms ? true : false,
							},
						]}
						type={"multiple"}
						onChange={onTermsChange}
					/>
				</CheckboxView>
			</Container>
		</SectionContainer>
	);
};
export default Personal;
export interface PersonalProps {
	user: UserScheme;
	corporate?: CoorporateScheme;
	animated?: boolean;
	showBirthday?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
