import styled from "styled-components";
import media from "styled-media-query";

import { Player } from "@lottiefiles/react-lottie-player";
import { Text, Color } from "@adoptaunabuelo/react-components";
import animation from "../../../assets/animations/heart.json";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";
import { UserScheme } from "client-v2";
import { State } from "../../../redux";
import { useSelector } from "react-redux";
import Relief from "../../../components/section/donation/Relief";

const StepContainer = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
`;
const IconImage = styled.img`
	width: 135px;
	height: 40px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);
	const isMobile = useSelector((state: State) => state.screen.isMobile);

	const steps = [
		{
			title: "Descarga nuestra app",
			subtitle: "E iniciar sesión con tu número de teléfono.",
		},
		{
			title: "Completa la formación",
			subtitle:
				"Te enseñaremos todo lo necesario para que puedas acompañar a un mayor.",
		},
		{
			title: "¡Diviértete!",
			subtitle:
				"Encontraremos al mayor ideal para ti. Podrás llamarla y visitarla para hacerle compañía.",
		},
	];

	return (
		<LeftSectionContainer
			style={{ paddingTop: 0 }}
			childrenProps={{ style: { margin: 0 } }}
			animated={props.animated}
		>
			<Player
				style={{
					width: isMobile ? 300 : 400,
					height: isMobile ? 300 : 400,
					marginTop: isMobile ? -100 : -140,
					marginBottom: isMobile ? -100 : -140,
				}}
				keepLastFrame={true}
				autoplay={true}
				src={animation}
			/>
			<Text type="h3" weight="semibold" style={{ textAlign: "center" }}>
				¡Ya formas parte de Adopta Un Abuelo!
			</Text>
			<Text
				type="p"
				style={{
					marginTop: 12,
					textAlign: "center",
					marginBottom: 48,
				}}
			>
				Prepárate para disfrutar de una experiencia única en la mejor
				compañía
			</Text>
			<Text type="h5" weight="semibold" style={{ marginBottom: 24, textAlign: "center" }}>
				¿Y ahora qué?
			</Text>
			<StepContainer style={{ gap: 16 }}>
				{steps.map((step, index) => (
					<Row>
						<Text
							type="p"
							weight="semibold"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: Color.background.soft,
								height: 36,
								width: 36,
								borderRadius: 40,
							}}
						>
							{index + 1}
						</Text>
						<Col style={{ flex: 1 }}>
							<Text type="p" weight="medium">
								{step.title}
							</Text>
							<Text type="p2" style={{ color: Color.text.high }}>
								{step.subtitle}
							</Text>
						</Col>
					</Row>
				))}
				<Row style={{ justifyContent: "center", marginTop: 32 }}>
					<IconImage
						src={require("../../../assets/logo/android.png")}
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=com.adoptaunabuelo.app&hl=es_419&gl=ES&pli=1",
								"_blank"
							)
						}
					/>
					<IconImage
						src={require("../../../assets/logo/ios.png")}
						onClick={() =>
							window.open(
								"https://apps.apple.com/es/app/adopta-un-abuelo/id1559513523",
								"_blank"
							)
						}
					/>
				</Row>
			</StepContainer>
			{appLocation &&
				appLocation.country_code === "ES" &&
				props.didSubscribe &&
				props.user &&
				!props.user.idCard &&
				!props.user.zipCode && (
					<Relief style={{ marginTop: 32 }} user={props.user} />
				)}
		</LeftSectionContainer>
	);
};
export default Success;
export interface Props {
	didSubscribe?: boolean;
	user: UserScheme;
	animated?: boolean;
}
