import { useState, useRef } from "react";
import { ProductScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import media from "styled-media-query";

import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import Success from "./steps/SuccessStep";
import SuccessRight from "../../components/section/SuccessCrossSell";
import Price from "../../components/section/donation/Price";
import PriceLeft from "../../components/section/donation/PriceInfo";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import { ChevronDown } from "lucide-react";

const ArrowAnimationView = styled.div`
	display: none;
	position: sticky;
	bottom: 0px;
	right: 0px;
	left: 0px;
	width: 100%;
	padding: 52px 0px 16px;
	background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
	align-items: flex-end;
	justify-content: center;
	${media.lessThan("medium")`
		display: flex;
    `}
`;

const Checkout = (props: Props) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "donation";
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);

	const onBackClick = (step: number) => {
		if (step === 6) {
			wizard.current?.goBack(1, step);
		}
	};

	return (
		<WizardContainer
			ref={wizard}
			onBackClick={onBackClick}
			views={[
				//Select price view
				{
					containerStyle: { overflowY: "scroll" },
					leftView: (
						<Price
							options={["monthly", "annually", "unique"]}
							animated={true}
							view={view}
							defaultGrowthbook={{
								title: "Ningún mayor debería sentirse solo. ¿Nos ayudas?",
								title_en:
									"No grandpa should feel alone. Can you help us?",
								subtitle:
									"Estás regalando {{data}} horas de compañía a una persona mayor",
								subtitle_en:
									"You are giving away {{data}} hours of company to a grandpa",
								type_to_eur: 0.602,
							}}
							onFinish={(result) => {
								if (result.error) {
									setShowError({
										show: true,
										message: result.error,
									});
								} else if (result.data) {
									setProduct(result.data);
									wizard.current?.goNext();
								}
							}}
						/>
					),
					rightViewStyle: { overflowY: "scroll" },
					rightView: isMobile ? null : <PriceLeft />,
				},
				//Select payment method
				{
					leftView: <></>,
					centralView:
						product && currentUser ? (
							<PaymentMethod
								animated={true}
								product={product}
								user={currentUser}
								view={view}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										wizard.current?.goNext();
									}
								}}
								onProductChange={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
									}
								}}
							/>
						) : null,
					rightView: <></>,
				},
				//Success view
				{
					containerStyle: { overflowY: "scroll" },
					leftView: currentUser ? (
						<>
							<Success animated={true} user={currentUser}>
								{isMobile ? (
									<SuccessRight animated={true} />
								) : null}
							</Success>
							<ArrowAnimationView>
								<ChevronDown />
							</ArrowAnimationView>
						</>
					) : null,
					rightViewStyle: { position: "unset" },
					rightView: !isMobile ? (
						<SuccessRight animated={true} />
					) : null,
					hideBackButton: true,
				},
			]}
		/>
	);
};
export default Checkout;
export interface Props {}
