import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../redux";

import Loading from "../components/modal/Loading";
import IbanWizard from "../views/iban/IbanWizard";

const IbanLayout = (props: IbanLayoutProps) => {
	const loading = useSelector((state: State) => state.modals.loading);

	return (
		<>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route path="/" element={<IbanWizard />} />
			</Routes>
		</>
	);
};

export default IbanLayout;
export interface IbanLayoutProps {}
