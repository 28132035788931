import styled from "styled-components";
import moment from "moment";
import { ProductScheme, EventScheme } from "client-v2";

import { Calendar, MapPin } from "lucide-react";
import { Text, Color, Button, Label } from "@adoptaunabuelo/react-components";
import ResumeStyled from "../../../components/container/ResumeContainer";

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const Resume = (props: Props) => {
	const pastDue = props.product.startDate
		? moment() > moment(props.product.startDate.iso)
		: false;

	return (
		<ResumeStyled
			style={{
				flex: "unset",
				padding: "32px 24px",
				boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
				borderRadius: 12,
				minWidth: 380,
				marginLeft: 0,
			}}
		>
			<Row
				style={{
					paddingBottom: 24,
					borderBottom: "1px solid " + Color.line.soft,
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{props.product.price !== 0 ? (
					<Row style={{ alignItems: "center" }}>
						<Text type="h3" weight="semibold">
							{props.product.price}€
						</Text>
						<Text type="p" style={{ marginLeft: 8 }}>
							por persona
						</Text>
					</Row>
				) : props.product.data && props.product.data.corporate ? (
					<Text type="h5" weight="medium">
						Información del evento
					</Text>
				) : (
					<Text
						type="h3"
						weight="medium"
						style={{ color: Color.text.primary }}
					>
						Gratis
					</Text>
				)}
				{props.showLabel && (
					<Label
						text="Últimas plazas"
						color={Color.status.color.error}
						style={{
							backgroundColor: Color.status.color.errorDefault,
							borderRadius: 30,
							padding: "0px 12px",
						}}
					/>
				)}
				{pastDue && (
					<Label
						text="Evento finalizado"
						color={Color.status.color.error}
						style={{
							backgroundColor: Color.status.color.errorDefault,
							borderRadius: 30,
							padding: "0px 12px",
						}}
					/>
				)}
			</Row>
			<Row style={{ marginTop: 24 }}>
				<Calendar
					style={{ marginRight: 12 }}
					height={24}
					width={24}
					color={Color.text.full}
				/>
				<Column>
					<Text type="p" weight="medium">
						{moment(props.product.startDate?.iso).format(
							"ddd DD MMMM"
						)}
					</Text>
					<Text type="c1" style={{ color: Color.text.high }}>
						De{" "}
						{moment(props.product.startDate?.iso).format("HH:mm")} a{" "}
						{moment(props.product.endDate?.iso).format("HH:mm")}
					</Text>
				</Column>
			</Row>
			<Row style={{ marginTop: 16 }}>
				<MapPin
					style={{ marginRight: 12 }}
					height={24}
					width={24}
					color={Color.text.full}
				/>
				<Column>
					<Text type="p" weight="medium">
						{props.event.shortAddress}
					</Text>
					<Text type="c1" style={{ color: Color.text.high }}>
						{props.event.address}
					</Text>
				</Column>
			</Row>
			{props.onClick && (
				<Button
					style={{ marginTop: 32 }}
					disabled={pastDue}
					onClick={props.onClick}
				>
					{props.product.data && props.product.data.corporate
						? "Confirmar asistencia"
						: "Reservar plaza"}
				</Button>
			)}
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	product: ProductScheme;
	event: EventScheme;
	showLabel?: boolean;
	onClick?: () => void;
}
