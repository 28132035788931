import { Input, Form } from "@adoptaunabuelo/react-components";
import SectionContainer from "../../components/container/SectionContainer";
import styled from "styled-components";
import { State } from "../../redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { User, UserScheme } from "client-v2";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Profile = (props: ProfileProps) => {
	const currentUser = useSelector((state: State) => state.user.currentUser);

	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
	const [idCard, setIdCard] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState<LocationObjProps>({});

	useEffect(() => {
		if (currentUser) {
			setIdCard(currentUser.idCard);
		}
	}, [currentUser]);

	const onSaveClick = () => {
		if (currentUser && idCard) {
			if (location.route && location.routeNumber) {
				setLoading(true);
				User.set(currentUser.objectId, {
					idCard: idCard,
					internalData: {
						verifiedAddress: true,
					},
					...location,
				})
					.then((result) => {
						setLoading(false);
						props.onFinish({ data: result.data });
					})
					.catch((e) => {
						setLoading(false);
						props.onFinish({ error: e.message });
					});
			} else {
				props.onFinish({
					error: "Debes añadir tu dirección postal completa antes de continuar. Incluyendo el número de calle.",
				});
			}
		} else {
			props.onFinish({
				error: "Debes añadir tu DNI o NIE antes de continuar.",
			});
		}
	};

	return (
		<SectionContainer
			title="Completa tu perfil"
			subtitle="Es necesario que completes todos los datos para poder desgravar tu donación en la Declaración de la Renta."
			buttonProps={{
				style: { width: "100%" },
				children: "Guardar",
				loading: loading,
				onClick: onSaveClick,
			}}
		>
			<Container>
				<Input
					type="text"
					placeholder="DNI o NIE"
					design="secondary"
					defaultValue={idCard}
				/>
				<Form
					type="location"
					design={"secondary"}
					googleAPIKey={googleAPIKey}
					onSubmit={(result) => setLocation({ ...result.data })}
				/>
			</Container>
		</SectionContainer>
	);
};
export default Profile;
export interface ProfileProps {
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}

interface LocationObjProps {
	address?: string;
	sortAddress?: string;
	route?: string;
	routeNumber?: string;
	routeInfo?: string;
	city?: string;
	province?: string;
	zipCode?: string;
	country?: string;
	location?: google.maps.LatLngLiteral;
	timeZone?: string;
}
