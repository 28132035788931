import { useState } from "react";
import moment from "moment";

import LeftSectionContainer from "../container/LeftSectionContainer";
import { ColorV2, Input } from "@adoptaunabuelo/react-components";
import { UserScheme, User } from "client-v2";
import { State } from "../../redux";
import { useSelector } from "react-redux";

const BirthStep = (props: Props) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const colors = {
		buttonBackground: ColorV2.surface.primaryHard,
		buttonColor: "white",
		animation: ["#00315C", "#005AA8"],
	};

	const [date, setDate] = useState<Date | undefined>(undefined);
	const [inputError, setInputError] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const onInputChange = (date: any) => {
		setInputError(undefined);
		setDate(date);
	};

	const onKeyDown = (e: any) => {
		if (e.key === "Enter") {
			onNextClick();
		}
	};

	const onNextClick = () => {
		if (date) {
			//Check if volunteer is in the year margin
			const today = moment();
			const momentDate = moment(date);

			if (today.diff(momentDate, "years") < 15) {
				setInputError(
					"Para unirte a Adopta Un Abuelo debes ser mayor de 15"
				);
				return undefined;
			} else if (today.diff(momentDate, "years") > 1000) {
				setInputError(
					"Debes añadir una fecha válida antes de continuar"
				);
				return undefined;
			}

			setInputError(undefined);

			setLoading(true);
			User.set(props.user.objectId, {
				birthday: momentDate.toDate(),
			})
				.then((result) => {
					setLoading(false);
					props.onFinish({ data: result.data });
				})
				.catch((error) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		} else {
			setInputError("Introduce una fecha válida");
		}
	};

	return (
		<LeftSectionContainer
			title="Tu fecha de nacimiento"
			subtitle="Dinos tu fecha de cumpleaños 🎂"
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				loading: loading,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<Input
				type="date"
				placeholder={"DD/MM/AAAA"}
				max={moment().format("YYYY-MM-DD")}
				design={"secondary"}
				onKeyDown={onKeyDown}
				error={inputError}
				onChange={onInputChange}
			/>
		</LeftSectionContainer>
	);
};
export default BirthStep;
export interface Props {
	user: UserScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
