import styled from "styled-components";
import RightSectionContainer from "../../container/RightSectionContainer";
import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import { PieChart } from "react-minimal-pie-chart";
import media from "styled-media-query";
import LetterIcon from "../../../assets/images/letter/Icon";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const PieChartView = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`;
const PieChartData = styled.div`
	display: flex;
	flex-direction: row;
	gap: 24px;
	padding-bottom: 48px;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	${media.lessThan("medium")`
        flex-direction: column;
		margin-top: 32px;
    `}
`;
const PresentContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: 12px;
	background-color: ${ColorV2.surface.brown};
	${media.lessThan("medium")`
        flex-direction: column-reverse;
		align-items: center;
    `}
`;
const PresentData = styled.div`
	padding: 24px 0px 24px 24px;
	flex: 1;
	${media.lessThan("medium")`
		padding: 0px 24px 24px;
	`}
`;
const PresentImage = styled.img`
	width: 200px;
	object-fit: cover;
	object-position: left;
`;
const ChartImage = styled.img`
	position: absolute;
	top: 16px;
	right: calc(50% - 128 / 2px);
	height: 128px;
	width: 128px;
`;

const PriceInfo = (props: PriceInfoProps) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const chartOptions = [
		{
			value: 85,
			color: ColorV2.text.primary,
		},
		{
			value: 15,
			color: ColorV2.surface.primaryLow,
		},
	];
	const auaMeans = [
		{
			title: translation.payment_info_aua_title_01,
			subtitle: translation.payment_info_aua_subtitle_01,
			icon: <LetterIcon type="01" />,
		},
		{
			title: translation.payment_info_aua_title_02,
			subtitle: translation.payment_info_aua_subtitle_02,
			icon: <LetterIcon type="02" />,
		},
		{
			title: translation.payment_info_aua_title_03,
			subtitle: translation.payment_info_aua_subtitle_03,
			icon: <LetterIcon type="03" />,
		},
	];

	return (
		<RightSectionContainer
			style={{ backgroundColor: "white", padding: "0px 24px", borderRadius: "24px 24px 0px 0px" }}
			animated={true}
		>
			<Text
				type="h3"
				weight="semibold"
				style={{
					color: ColorV2.text.primary,
					textAlign: "center",
					lineHeight: "40px",
				}}
			>
				{translation.payment_info_title}
			</Text>
			<Text type="p" style={{ textAlign: "center", marginTop: 16 }}>
				{translation.payment_info_subtitle}
			</Text>
			<Text
				type="h4"
				weight="semibold"
				style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}
			>
				{translation.payment_info_title_section_01}
			</Text>
			<PieChartView>
				<PieChart
					lineWidth={12}
					style={{ height: 160, width: 160 }}
					data={chartOptions}
				/>
				<ChartImage
					src={require("../../../assets/images/letter/volunteer_grandpa.webp")}
				/>
			</PieChartView>
			<PieChartData>
				<Col style={{ textAlign: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{ color: ColorV2.text.primary }}
					>
						85%
					</Text>
					<Text type="h6" weight="medium" style={{ marginBottom: 8 }}>
						{translation.payment_info_amount_col_01_title}
					</Text>
					<Text type="c1">
						{translation.payment_info_amount_col_01_subtitle}
					</Text>
				</Col>
				<Col style={{ textAlign: "center" }}>
					<Text
						type="h2"
						weight="semibold"
						style={{ color: ColorV2.surface.primaryLow }}
					>
						15%
					</Text>
					<Text type="h6" weight="medium" style={{ marginBottom: 8 }}>
						{translation.payment_info_amount_col_02_title}
					</Text>
					<Text type="c1">
						{translation.payment_info_amount_col_02_subtitle}
					</Text>
				</Col>
			</PieChartData>
			<Col
				style={{
					padding: "48px 0px",
					borderBottom: "1px solid " + ColorV2.border.neutralSoft,
				}}
			>
				<Text
					type="h4"
					weight="semibold"
					style={{ textAlign: "center", marginBottom: 48 }}
				>
					{translation.payment_info_title_section_02}
				</Text>
				<Col style={{ gap: 16 }}>
					{auaMeans.map((item, index) => (
						<Row key={"aua-" + index} style={{ gap: 12 }}>
							{item.icon}
							<Col style={{ flex: 1 }}>
								<Text
									type="h6"
									weight="medium"
									style={{ marginBottom: 4 }}
								>
									{item.title}
								</Text>
								<Text type="p2">{item.subtitle}</Text>
							</Col>
						</Row>
					))}
				</Col>
			</Col>
			{props.showMug && (
				<Col
					style={{
						padding: "48px 0px 0px",
					}}
				>
					<Text
						type="h4"
						weight="semibold"
						style={{ textAlign: "center", marginBottom: 48 }}
					>
						{translation.payment_info_title_section_03}
					</Text>
					<PresentContainer>
						<PresentData>
							<Text
								type="h5"
								weight="semibold"
								style={{ marginBottom: 8 }}
							>
								{translation.payment_info_mug_title}
							</Text>
							<Text type="p2">
								Si donas{" "}
								{appLocation
									? new Intl.NumberFormat(
											appLocation.language,
											{
												style: "currency",
												currency:
													appLocation.currency.name,
												maximumFractionDigits: 0,
											}
									  ).format(
											appLocation.currency.price.monthly
												.gift
									  )
									: "20€"}
								{""} al mes o{" "}
								{appLocation
									? new Intl.NumberFormat(
											appLocation.language,
											{
												style: "currency",
												currency:
													appLocation.currency.name,
												maximumFractionDigits: 0,
											}
									  ).format(
											appLocation.currency.price.annually
												.gift
									  )
									: "200€"}{" "}
								al año, conseguirás de regalo una taza diseñada
								por Miki Noëlle en exclusiva para Adopta Un
								Abuelo.
							</Text>
							{/* <Button
							design="secondary"
							size="small"
							color={ColorV2.text.neutralHard}
							style={{
								width: "fit-content",
								height: 40,
								marginTop: 16,
								color: ColorV2.text.neutralHard,
							}}
						>
							Saber más
						</Button> */}
						</PresentData>
						<PresentImage
							src={require("../../../assets/images/letter/miki-noelle-mug.webp")}
						/>
					</PresentContainer>
				</Col>
			)}
		</RightSectionContainer>
	);
};
export default PriceInfo;
export interface PriceInfoProps {
	showMug?: boolean;
}
