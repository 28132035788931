import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Constant, CoorporateScheme, UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { Text, Modal, ColorV2 } from "@adoptaunabuelo/react-components";
import HobbiesStamp from "../../../components/stamp/HobbiesStamp";

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	background-color: white;
	padding: 8px;
	border-radius: 100px;
	margin-right: 12px;
`;
const ModalContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-bottom: 32px;
	padding-top: 32px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const UserImage = styled.img`
	width: 96px;
	aspect-ratio: 3/4;
	border-radius: 6px;
	margin-left: 24px;
	object-fit: cover;
`;
const UserImageLittle = styled.img`
	height: 36px;
	width: 36px;
	border-radius: 36px;
	object-fit: cover;
	border: 1px solid ${ColorV2.surface.neutralSoft};
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 8px;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
`;

const Footer = (props: Props) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const appLocation = useSelector((state: State) => state.location.location);
	const [hobbies, setHobbies] = useState<any[]>([]);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (
			props.grandpa.data &&
			props.grandpa.data.hobbies &&
			props.grandpa.data.hobbies.length > 0
		) {
			const hobbiesConstants = Constant.hobbies();
			const temp = props.grandpa.data.hobbies
				.map((item) => {
					const result = hobbiesConstants.filter(
						(i) => i.id === item
					);
					if (result.length > 0) {
						return {
							title:
								appLocation?.language === "en"
									? result[0].label_en
									: result[0].label,
							icon: result[0].icon,
							backgroundColor: result[0].backgroundColor,
						};
					}
				})
				.filter((i) => i)
				.slice(0, 5);
			setHobbies(temp);
		}
	}, [props.grandpa]);

	return (
		<>
			<Modal
				isVisible={showModal}
				type={"full-screen"}
				hideClose={true}
				hideHeader={true}
				shouldCloseOnOverlayClick={true}
				buttonProps={{
					children: translation.letter_footer_button_skip,
					size: "normal",
					style: {
						width: "100%",
						backgroundColor:
							props.corporate && props.corporate.color
								? props.corporate.color.main
								: ColorV2.surface.primaryHard,
					},
					onClick: () => setShowModal(false),
				}}
				onClose={() => setShowModal(false)}
			>
				<ModalContainer>
					<Row>
						<Col style={{ flex: 1, gap: 8 }}>
							<Cell>
								<Text
									type="h3"
									weight="semibold"
									style={{ textTransform: "capitalize" }}
								>
									{props.grandpa.name.toLowerCase()}
								</Text>
							</Cell>
							{props.grandpa.birthday && (
								<Cell>
									<Text type="p2">
										{moment().diff(
											moment(props.grandpa.birthday.iso),
											"years"
										)}
										{translation.years}
									</Text>
								</Cell>
							)}
							{props.grandpa.shortAddress && (
								<Cell>
									<Text type="p2">
										{props.grandpa.shortAddress}
									</Text>
								</Cell>
							)}
						</Col>
						<UserImage
							src={
								props.grandpa?.image
									? props.grandpa.image.url
									: props.grandpa.gender
									? props.grandpa.gender === "male"
										? require("../../../assets/images/icon/grandpa_icon.png")
										: props.grandpa.gender === "female"
										? require("../../../assets/images/icon/grandma_icon.png")
										: require("../../../assets/images/icon/user_icon.png")
									: require("../../../assets/images/icon/user_icon.png")
							}
						/>
					</Row>
					{props.grandpa.data?.hobbies && (
						<>
							<Text
								type="p"
								weight="medium"
								style={{ marginTop: 24, marginBottom: 12 }}
							>
								{translation.letter_stamp_title}
							</Text>
							<Row style={{ flexWrap: "wrap", gap: 8 }}>
								{hobbies.map((hobbie, index: number) => (
									<HobbiesStamp
										key={"stamp" + index}
										title={hobbie.title}
										icon={hobbie.icon}
										backgroundColor={hobbie.backgroundColor}
									/>
								))}
							</Row>
						</>
					)}
				</ModalContainer>
			</Modal>
			<Container onClick={() => setShowModal(true)}>
				<UserImageLittle
					src={
						props.grandpa?.image
							? props.grandpa.image.url
							: props.grandpa.gender
							? props.grandpa.gender === "male"
								? require("../../../assets/images/icon/grandpa_icon.png")
								: props.grandpa.gender === "female"
								? require("../../../assets/images/icon/grandma_icon.png")
								: require("../../../assets/images/icon/user_icon.png")
							: require("../../../assets/images/icon/user_icon.png")
					}
				/>
				<Text type="b2" weight="medium">
					{translation.letter_footer_button_main}
					{props.grandpa.name}
				</Text>
			</Container>
		</>
	);
};
export default Footer;
export interface Props {
	grandpa: UserScheme;
	corporate?: CoorporateScheme;
}
