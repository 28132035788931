import styled from "styled-components";

import { Text, Color } from "@adoptaunabuelo/react-components";
import { Heart } from "lucide-react";
import { UserScheme } from "client-v2";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";

const Section = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 48px;
`;
const HowWork = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Numbers = styled(Text)<{ color?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	margin-right: 16px !important;
	height: 40px;
	width: 40px;
	border-radius: 30px;
	color: ${(props) =>
		props.color ? props.color : Color.text.primary} !important;
`;

const SuccessStep = (props: Props) => {
	return (
		<LeftSectionContainer
			animated={props.animated}
			style={{ paddingTop: 0 }}
			childrenProps={{ style: { alignItems: "center" } }}
		>
			<Heart
				fill={Color.background.primary}
				color={"transparent"}
				style={{ marginBottom: 24, marginTop: 24 }}
				height={48}
				width={48}
				strokeWidth={1}
			/>
			<Text type="h3" weight="semibold" style={{ textAlign: "center" }}>
				¡Eres increíble!
			</Text>
			<Text
				type="p"
				style={{
					marginTop: 12,
					marginBottom: 12,
					textAlign: "center",
				}}
			>
				Hemos recibido tu firma. Gracias por ayudarnos a proteger los
				derechos de las personas mayores.
			</Text>
			<Section>
				<Text
					type="h4"
					weight="semibold"
					style={{ marginBottom: 24, textAlign: "center" }}
				>
					¿Y ahora qué?
				</Text>
				<HowWork>
					<Row>
						<Numbers type="p" weight="semibold">
							1
						</Numbers>
						<Col>
							<Text type="h6" weight="medium">
								Seguiremos haciendo ruido
							</Text>
							<Text
								type="p"
								style={{
									color: Color.text.high,
									marginTop: 4,
								}}
							>
								Para que la campaña llega a todo el mundo.
							</Text>
						</Col>
					</Row>
					<Row>
						<Numbers type="p" weight="semibold">
							2
						</Numbers>
						<Col>
							<Text type="h6" weight="medium">
								Llegaremos hasta el final
							</Text>
							<Text
								type="p"
								style={{
									color: Color.text.high,
									marginTop: 4,
								}}
							>
								En caso de conseguir todas las firmas lo
								llevaremos al organismo competente para
								conseguir el objetivo.
							</Text>
						</Col>
					</Row>
				</HowWork>
			</Section>
			{props.children}
		</LeftSectionContainer>
	);
};
export default SuccessStep;
export interface Props {
	animated?: boolean;
	user: UserScheme;
	children?: React.ReactNode;
	didSubscribe?: boolean;
	onNextClick?: () => void;
}
