import { useState } from "react";
import styled from "styled-components";
import { Challenge, ChallengeScheme, User, UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import {
	Text,
	Color,
	Avatar,
	Checkbox,
	Modal,
	Button,
	Input,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import SignatureConfirmationModal from "../../components/modal/SignatureConfirmationModal";
import LeftSectionContainer from "../container/LeftSectionContainer";

const Section = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: white;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	padding: 12px;
	border-bottom: 1px solid ${Color.line.soft};
	:last-child {
		border-bottom: none;
	}
`;

const SignatureStep = (props: SignatureStepProps) => {
	const colors = {
		buttonBackground: ColorV2.surface.primaryHard,
		buttonColor: "white",
		animation: ["#00315C", "#005AA8"],
	};
	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
	const isMobile = useSelector((state: State) => state.screen.isMobile);

	const [modalError, setModalError] = useState<string | undefined>(undefined);
	const [user, setUser] = useState<UserScheme>(props.user);
	const [anonymous, setAnonymous] = useState(false);
	const [showAddIdCard, setShowAddIdCard] = useState(false);
	const [showAddAddress, setShowAddAddress] = useState(false);
	const [loading, setLoading] = useState(false);
	const [idCard, setIdCard] = useState<string | undefined>(undefined);
	const [location, setLocation] = useState<LocationObjProps | undefined>(
		undefined
	);
	const [showSignatureConfirmation, setShowSignatureConfirmation] =
		useState(false);

	const setAddress = () => {
		setLoading(true);
		User.set(user.objectId, {
			...location,
		})
			.then((result) => {
				setUser(result.data);
				setLoading(false);
				setShowAddAddress(false);
			})
			.catch((e: string) => {
				setModalError(e);
				setLoading(false);
			});
	};

	const setCard = () => {
		if (idCard && validateCardId(idCard) && user) {
			setLoading(true);
			User.set(user.objectId, {
				idCard: idCard,
			})
				.then((result) => {
					setUser(result.data);
					setLoading(false);
					setShowAddIdCard(false);
				})
				.catch((error: string) => {
					setLoading(false);
					setModalError(error);
				});
		} else {
			setModalError("El número de documento introducido no es válido");
		}
	};

	const validateCardId = (id: string) => {
		var DNI_REGEX = /^(\d{8})([A-Z])$/;
		var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

		// Ensure upcase and remove whitespace
		const str = id.toUpperCase().replace(/\s/, "");

		//Check the id type
		if (str.match(DNI_REGEX)) {
			var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
			var letter = dni_letters.charAt(parseInt(str, 10) % 23);
			return letter == str.charAt(8);
		} else if (str.match(NIE_REGEX)) {
			// Change the initial letter for the corresponding number and validate as DNI
			var nie_prefix = str.charAt(0);
			switch (nie_prefix) {
				case "X":
					nie_prefix = "0";
					break;
				case "Y":
					nie_prefix = "1";
					break;
				case "Z":
					nie_prefix = "2";
					break;
			}
			const tempStr = nie_prefix + str.substr(1);

			//Validate NIE as DNI
			var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
			var letter = dni_letters.charAt(parseInt(tempStr, 10) % 23);
			return letter == tempStr.charAt(8);
		} else return false;
	};

	const onLocationChange = async (item: LocationObjProps) => {
		setModalError(undefined);
		setLocation(item);
	};

	const onNextClick = () => {
		Challenge.initSignature(props.challenge.objectId, {
			userId: props.user.objectId,
			anonymous: anonymous,
		});
		setShowSignatureConfirmation(true);
	};

	return (
		<LeftSectionContainer
			title="Tu firma"
			subtitle="Revisa que tus datos sean correctos."
			animated={props.animated}
			buttonProps={{
				sticky: true,
				loading: loading,
				children: "Firmar petición",
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				onClick: onNextClick,
			}}
		>
			<SignatureConfirmationModal
				isVisible={showSignatureConfirmation}
				challenge={props.challenge}
				user={props.user}
				onClose={() => {
					setShowSignatureConfirmation(false);
					props.onFinish({ data: true });
				}}
			/>
			<Modal
				isVisible={showAddIdCard}
				type={isMobile ? "full-screen" : "default"}
				title="Añade tu documento de identidad"
				subtitle="Esto permite certificar que eres tú quien firma la petición."
				error={modalError}
				buttonProps={{
					children: "Guardar",
					loading: loading,
					onClick: setCard,
				}}
				onClose={() => {
					setShowAddIdCard(false);
					setModalError(undefined);
				}}
			>
				<Input
					type={"text"}
					containerStyle={{ margin: "12px 0px" }}
					design={"secondary"}
					placeholder={"DNI o NIE"}
					onChange={(e) => {
						setModalError(undefined);
						setIdCard(e.target.value);
					}}
				/>
			</Modal>
			<Modal
				isVisible={showAddAddress}
				title="Añade tu ciudad"
				subtitle="Indica desde dónde firmas la petición."
				error={modalError}
				style={{
					overflow: "unset",
					overflowX: "unset",
					overflowY: "unset",
				}}
				type={isMobile ? "full-screen" : "default"}
				buttonProps={{
					children: "Guardar",
					loading: loading,
					onClick: setAddress,
				}}
				onClose={() => {
					setShowAddAddress(false);
					setModalError(undefined);
				}}
			>
				<Input
					type={"location"}
					containerStyle={{
						margin: isMobile ? "12px 0px 80px" : "2px 0px 12px",
					}}
					design={"secondary"}
					placeholder={"Tu ciudad"}
					googleAPIKey={googleAPIKey}
					onLocationChange={onLocationChange}
				/>
			</Modal>
			<Section>
				<Cell style={{ alignItems: "center", gap: 12 }}>
					<Avatar
						style={{ height: 48, width: 48 }}
						name={user.name}
						icon={user.image && user.image.url}
					/>
					<Text type="p">
						{user.name} {user.surname}
					</Text>
				</Cell>
				<Cell style={{ justifyContent: "space-between" }}>
					<Col>
						<Text type="c1" style={{ color: Color.text.high }}>
							Ciudad
						</Text>
						<Text type="p">{user.city ? user.city : "-"}</Text>
					</Col>
					<Button
						design="call-to-action"
						onClick={() => setShowAddAddress(true)}
					>
						{!user.city ? "Añadir" : "Modificar"}
					</Button>
				</Cell>
				<Cell style={{ justifyContent: "space-between" }}>
					<Col>
						<Text type="c1" style={{ color: Color.text.high }}>
							DNI/NIE
						</Text>
						<Text type="p">{user.idCard ? user.idCard : "-"}</Text>
					</Col>
					<Button
						design="call-to-action"
						onClick={() => setShowAddIdCard(true)}
					>
						{!user.idCard ? "Añadir" : "Modificar"}
					</Button>
				</Cell>
			</Section>
			<Checkbox
				style={{ marginTop: 32 }}
				type="multiple"
				options={[
					{
						id: "anonymous",
						label: "No mostrar mi firma públicamente en esta petición",
					},
				]}
				onChange={(op: Array<any>) =>
					setAnonymous(op.length > 0 ? true : false)
				}
			/>
		</LeftSectionContainer>
	);
};
export default SignatureStep;
export interface SignatureStepProps {
	user: UserScheme;
	challenge: ChallengeScheme;
	animated?: boolean;
	view: string;
	onFinish: (result: { data?: boolean; error?: string }) => void;
}

interface LocationObjProps {
	address?: string;
	sortAddress?: string;
	route?: string;
	routeNumber?: string;
	routeInfo?: string;
	city?: string;
	province?: string;
	zipCode?: string;
	country?: string;
	location?: google.maps.LatLngLiteral;
	timeZone?: string;
}
