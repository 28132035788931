import { Button, ColorV2, Menu } from "@adoptaunabuelo/react-components";
import { Auth, UserScheme } from "client-v2";
import { ArrowLeft, EllipsisVertical, Power, User } from "lucide-react";
import React, {
	CSSProperties,
	forwardRef,
	Ref,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import moment from "moment";

import Personal from "../section/login/Personal";
import Code from "../section/login/Code";
import Login from "../section/login/Login";

const isScreen01: any = "1400px";
const MainContainer = styled.div`
	display: flex;
	align-items: center;
	height: var(--doc-height);
	width: 100vw;
	overflow: hidden;
	flex-direction: column;
	background-color: ${ColorV2.surface.background};
`;
const NavBar = styled.div<{ $hideBackButton?: boolean }>`
	position: relative;
	display: flex;
	align-items: flex-start;
	height: 72px;
	width: calc(100% - 48px);
	padding: 0px 24px;
	align-items: center;
	${media.lessThan("medium")`
		width: calc(100% - 16px);
        padding: 0px 8px;
		height: 56px;
    `}
`;
const DataContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	width: 1276px;
	padding: 0px 40px;
	${media.lessThan(isScreen01)`
        width: 100%;
    `}
`;
const LeftContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 50%;
	${media.lessThan("medium")`
		max-width: 100%;
    `}
`;
const RightContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 50%;
	${media.lessThan("medium")`
		max-width: 100%;
    `}
`;
const CentralContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 388px;
	${media.lessThan("medium")`
		width: 100%;
    `}
`;
const NavigationLeftView = styled.div`
	display: flex;
`;
const NavigationRightView = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;
const MenuView = styled.div``;

const WizardContainer = forwardRef(
	(props: WizardContainerProps, ref: Ref<WizardContainerRef>) => {
		const [secondsRemaining, setSecondsRemaining] = useState(0);
		const [step, setStep] = useState(0);
		const [views, setViews] = useState<ViewsProps[]>([]);
		const [user, setUser] = useState<UserScheme | undefined>(undefined);

		const dispatch = useDispatch();
		const { setShowError, setCurrentUser } = bindActionCreators(
			actionsCreators,
			dispatch
		);
		const currentUser = useSelector(
			(state: State) => state.user.currentUser
		);
		const isMobile: boolean = useSelector(
			(state: State) => state.screen.isMobile
		);
		const loginViews = [
			//Login phone
			{
				hideBackButton: true,
				leftView: <></>,
				centralView: (
					<Login
						animated={true}
						onFinish={(result) => {
							if (result.error) {
								setShowError({
									show: true,
									message: result.error,
								});
							} else if (result.data) {
								setUser(result.data);
								setSecondsRemaining(60);
								onNextClick();
							}
						}}
					/>
				),
				rightView: <></>,
			},
			//SMS code
			{
				leftView: <></>,
				centralView: (
					<Code
						user={user}
						animated={true}
						countdown={secondsRemaining}
						onFinish={(result) => {
							if (result.error) {
								setShowError({
									show: true,
									message: result.error,
								});
							} else if (result.data) {
								setUser(result.data);
								setCurrentUser(result.data);
								const hasData = checkUserData(result.data);
								if (hasData) onNextClick(2);
								else onNextClick();
							}
						}}
					/>
				),
				rightView: <></>,
				hideBackButton: secondsRemaining <= 0 ? false : true,
			},
			//Personal data
			props.personalView
				? props.personalView
				: {
						leftView: <></>,
						centralViewStyle: isMobile
							? { alignItems: "unset" }
							: undefined,
						centralView: user ? (
							<Personal
								user={user}
								animated={true}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setUser(result.data);
										onNextClick();
									}
								}}
							/>
						) : null,
						rightView: <></>,
				  },
		];

		useEffect(() => {
			setViews([...loginViews, ...props.views]);
		}, [props.views, user]);

		useEffect(() => {
			if (currentUser) {
				//Check if user has all the data
				setUser(currentUser);
				const hasData = checkUserData(currentUser);
				if (hasData) {
					setStep(3);
				} else setStep(2);
			} else {
				//Check if go to code step because user tried to login few seconds ago
				const tempUserString = localStorage.getItem("temp-login");
				if (tempUserString) {
					const tempUser = JSON.parse(tempUserString);
					const diffTime = moment().diff(
						moment(tempUser.date),
						"second"
					);
					if (diffTime < 60) {
						setUser(tempUser.user);
						setSecondsRemaining(60 - diffTime);
						onNextClick();
					} else {
						localStorage.removeItem("temp-login");
					}
				}
			}
		}, []);

		useImperativeHandle(ref, () => ({
			goBack(steps?: number, currentStep?: number) {
				return onBackClick(steps, currentStep);
			},
			goNext(steps?: number) {
				return onNextClick(steps);
			},
			setStep(step: number) {
				setStep(step);
				return step;
			},
		}));

		const checkUserData = (user: UserScheme) => {
			if (user.name && user.surname && user.email) return true;
			else return false;
		};

		const onBackClick = (steps?: number, currentStep?: number) => {
			const newStep =
				(currentStep ? currentStep : step) - (steps ? steps : 1);
			if (newStep >= 0) {
				setStep(newStep);
				props.onBackClick && props.onBackClick(newStep);
				return newStep;
			} else return 0;
		};

		const onNextClick = (steps?: number) => {
			const newStep = step + (steps ? steps : 1);
			if (newStep <= views.length) {
				setStep(newStep);
				return newStep;
			} else return step;
		};

		const onOptionClick = async (option: any) => {
			if (option.id === "logout") {
				await Auth.logout();
				window.location.reload();
			}
		};

		return props.loading ? (
			<></>
		) : views.length > 0 ? (
			<MainContainer>
				{!views[step].hideNavBar && (
					<NavBar $hideBackButton={views[step].hideBackButton}>
						{!views[step].hideBackButton && (
							<Button
								design="image"
								icon={<ArrowLeft />}
								disabled={step === 0 || step === 3}
								onClick={() => onBackClick()}
							/>
						)}
						<NavigationLeftView>
							{views[step].navigationLeftView}
						</NavigationLeftView>
						<NavigationRightView>
							{views[step].navigationRightView}
							{currentUser && (
								<MenuView>
									<Menu
										id={"header-menu-list"}
										icon={
											<EllipsisVertical
												color={ColorV2.text.neutralHard}
											/>
										}
										menuStyle={{ width: "max-content" }}
										options={[
											{
												id: "logout",
												label: "Cerrar sesión",
												icon: <Power />,
											},
										]}
										position={"bottom-left"}
										onClick={onOptionClick}
									/>
								</MenuView>
							)}
						</NavigationRightView>
					</NavBar>
				)}
				<DataContainer style={views[step].containerStyle}>
					{views[step].leftView && (
						<LeftContainer
							style={{
								...views[step].leftViewStyle,
							}}
						>
							{views[step].leftView}
						</LeftContainer>
					)}
					{views[step].centralView && (
						<CentralContainer style={views[step].centralViewStyle}>
							{views[step].centralView}
						</CentralContainer>
					)}
					{views[step].rightView && (
						<RightContainer
							style={{
								...views[step].rightViewStyle,
							}}
						>
							{views[step].rightView}
						</RightContainer>
					)}
				</DataContainer>
			</MainContainer>
		) : null;
	}
);
export default WizardContainer;
export interface WizardContainerProps {
	loading?: boolean;
	views: ViewsProps[];
	personalView?: ViewsProps;
	onBackClick?: (step: number) => void;
}
interface ViewsProps {
	containerStyle?: CSSProperties;
	leftViewStyle?: CSSProperties;
	leftView?: React.ReactNode;
	centralViewStyle?: CSSProperties;
	centralView?: React.ReactNode;
	rightViewStyle?: CSSProperties;
	rightView?: React.ReactNode;
	navigationLeftView?: React.ReactNode;
	navigationRightView?: React.ReactNode;
	hideBackButton?: boolean;
	hideNavBar?: boolean;
}
export interface WizardContainerRef {
	goBack: (steps?: number, currentStep?: number) => number;
	goNext: (steps?: number) => number;
	setStep: (step: number) => number;
}
