import { useEffect } from "react";
import { ChallengeScheme } from "client-v2";

import "./feed.css";

const HomeView = (props: Props) => {
	useEffect(() => {
		const contenedor = document.getElementById("feed-content");
		let index = 0;

		if (!contenedor) return;
		fetch("https://devaua.b4a.app/v4/feed/web/?version=1")
			.then((response) => response.json())
			.then(
				(result: {
					data: { obj: ChallengeScheme; amount: number }[];
				}) => {
					//Main feed cell
					let html = `
						<div class="cell">
							<div class="column">
								<div class="data" style="margin-right: 0px;">
									<h2>Acompañamos a mayores que nos necesitan</h2>
									<p>
										Únete a la comunidad y conecta con miles de abuelos a través de los proyectos que impulsamos desde la fundación.
									</p>
									<div class="button-container">
										<button onclick="window.open('https://adoptaunabuelo.org/donacion')">Quiero ser socio</button>
										<button id="next-button" class="secondary">
											Ver proyectos
										</button>
									</div>
								</div>
							</div>
							<div class="column">
								<img class="main-image" src="https://adoptaunabuelo.org/wp-content/uploads/2025/03/column2-67c983c20796b.webp"/>
							</div>
						</div>
					`;
					result.data.forEach((challenge) => {
						const fechaObjetivo: any = new Date(
							challenge.obj.endDate.iso
						);
						const hoy: any = new Date();
						const days_between = Math.round(
							Math.abs(
								(fechaObjetivo - hoy) / (1000 * 60 * 60 * 24)
							)
						);
						//Challenge cell
						html += `
							<div class="cell">
								<div class="column">
									<div class="data">
										<div class="badge ${challenge.obj.type}">
											<p>${
												challenge.obj.type ===
												"birthday"
													? "Ayúdanos a celebrar su cumpleaños"
													: challenge.obj.type ===
													  "signature"
													? "Firma esta petición"
													: "Ayúdanos a cumplir su sueño"
											}</p>
										</div>
										<h2>${challenge.obj.title}</h2>
										${
											challenge.obj.type !== "birthday"
												? `
											<div class="progress">
												<div class="bar ${
													challenge.obj.amount >=
														challenge.obj.goal &&
													"completed"
												}" style="width: ${
														(challenge.obj.amount /
															challenge.obj
																.goal) *
														100
												  }%;"></div>
											</div>
										`
												: ""
										}
										<div class="row">
											<img
												class="lucide-icon"
												src="https://unpkg.com/lucide-static@latest/icons/hand-heart.svg"
											/>
											${
												challenge.obj.type ===
												"birthday"
													? `<p><span class="${
															challenge.obj
																.amount >=
															challenge.obj.goal
																? "success"
																: "primary"
													  }">${
															challenge.amount
													  } felicitaciones</span></p>`
													: `<p><span class="${
															challenge.obj
																.amount >=
															challenge.obj.goal
																? "success"
																: "primary"
													  }">${
															challenge.obj.amount
													  } €</span> de ${
															challenge.obj.goal
													  } €</p>`
											}
										</div>
										${
											challenge.obj.type !== "signature"
												? `
											<div class="row">
												<img
													class="lucide-icon"
													src="https://unpkg.com/lucide-static@latest/icons/users.svg"
												/>
												${
													challenge.obj.type ===
													"birthday"
														? `<p><span>${challenge.obj.supporters} personas</span> han colaborado en los regalos</p>`
														: `<p><span>${challenge.obj.supporters} personas</span> han colaborado</p>`
												}
											</div>
										`
												: ""
										}
										<div class="row">
											<img
												class="lucide-icon"
												src="https://unpkg.com/lucide-static@latest/icons/calendar.svg"
											/>
											${
												challenge.obj.type ===
												"birthday"
													? `<p><span>${days_between} días</span> para su cumpleaños</p>`
													: `<p><span>${days_between} días</span> para cerrar la campaña</p>`
											}
										</div>
										<div class="button-container">
											<button onclick="window.open('https://adoptaunabuelo.org/${
												challenge.obj.type ===
												"birthday"
													? "cumpleanios"
													: challenge.obj.type ===
													  "dream"
													? "reto"
													: "firma"
											}?id=${challenge.obj.objectId}')">${
							challenge.obj.type === "birthday"
								? `Quiero felicitar`
								: challenge.obj.type === "signature"
								? "Quiero firmar"
								: `Quiero ayudar`
						}</button>
										</div>
									</div>
								</div>
								<div class="column">
									<img class="challenge-image" src="${challenge.obj.image?.url}"/>
								</div>
							</div>
						`;
					});
					contenedor.innerHTML = html;

					// Lógica del carrusel
					const wrapper: any =
						document.querySelector(".carousel-wrapper");
					const totalSlides = result.data.length + 1;

					function moveSlide(step: any) {
						index = (index + step + totalSlides) % totalSlides;
						wrapper.style.transform = `translateX(-${
							index * 100
						}%)`;
					}

					// Botones de navegación
					document
						.getElementById("next-button")
						?.addEventListener("click", () => moveSlide(1));
					document
						.querySelector(".prev")
						?.addEventListener("click", () => moveSlide(-1));
					document
						.querySelector(".next")
						?.addEventListener("click", () => moveSlide(1));

					// Auto-slide cada 5 segundos
					//setInterval(() => moveSlide(1), 5000);
				}
			);
	}, []);

	return (
		<div className="feed-container">
			<div className="carousel-wrapper" id="feed-content"></div>
			<button className="prev">
				<img
					className="lucide-icon"
					src="https://unpkg.com/lucide-static@latest/icons/arrow-left.svg"
				/>
			</button>
			<button className="next">
				<img
					className="lucide-icon"
					src="https://unpkg.com/lucide-static@latest/icons/arrow-right.svg"
				/>
			</button>
		</div>
	);
};
export default HomeView;
export interface Props {}
