import styled from "styled-components";
import { useEffect, useState } from "react";
import { ChallengeScheme, ErrorScheme, Feed } from "client-v2";
import ChallengeCell from "../cell/ChallengeCell";
import { Button, Text } from "@adoptaunabuelo/react-components";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ChallengeLoadingAnimation from "../../assets/animations/challenge_loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import RightSectionContainer from "../container/RightSectionContainer";

const ChallengeContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;
	justify-content: space-between;
	align-items: center;
`;
const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const SuccessRight = (props: SuccessRightProps) => {
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const [loading, setLoading] = useState(false);
	const [challenges, setChallenges] = useState<ChallengeScheme[]>([]);
	const [index, setIndex] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);

	useEffect(() => {
		getChallenges();
	}, []);

	const getChallenges = async () => {
		setLoading(true);
		Feed.getForLetter({
			version: 1,
		})
			.then((result) => {
				const tempChallenge: ChallengeScheme[] = [];
				result.data.map((i) =>
					i.rendedData.map((j) => tempChallenge.push(j.challenge))
				);
				setChallenges(tempChallenge);
				setTotalAmount(
					result.pagination?.count ? result.pagination?.count : 0
				);
				setLoading(false);
			})
			.catch((e: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<RightSectionContainer
			title="Más iniciativas de Adopta Un Abuelo"
			subtitle="Queremos hacer felices a las personas mayores. Tu aportación, por pequeña que sea, tiene un gran impacto."
			animated={props.animated}
			animationDelay={props.animationDelay}
		>
			<ChallengeContainer>
				<Row>
					<Text type="p">
						{index + 1}/{totalAmount} iniciativas
					</Text>
					<ButtonContainer>
						<Button
							style={{
								backgroundColor: "white",
								borderRadius: 100,
							}}
							design="image"
							icon={<ChevronLeft />}
							disabled={index <= 0 || loading}
							onClick={() => {
								if (index > 0) setIndex(index - 1);
							}}
						/>
						<Button
							style={{
								backgroundColor: "white",
								borderRadius: 100,
							}}
							design="image"
							icon={<ChevronRight />}
							disabled={index >= totalAmount - 1 || loading}
							onClick={() => {
								if (index < totalAmount - 1)
									setIndex(index + 1);
							}}
						/>
					</ButtonContainer>
				</Row>
				{loading || challenges.length <= 0 ? (
					<Player
						style={{
							width: isMobile ? "100%" : 388,
							height: 315,
						}}
						loop={true}
						autoplay={true}
						src={ChallengeLoadingAnimation}
					/>
				) : (
					<ChallengeCell
						challenge={challenges[index]}
						amount={challenges[index].amount}
					/>
				)}
			</ChallengeContainer>
		</RightSectionContainer>
	);
};
export default SuccessRight;
export interface SuccessRightProps {
	animated?: boolean;
	animationDelay?: number;
}
