import styled from "styled-components";
import moment from "moment";
import media from "styled-media-query";

import { Text, Color, ColorV2 } from "@adoptaunabuelo/react-components";
import ResumeStyled from "../../../components/container/ResumeContainer";
import { Calendar, MapPin } from "lucide-react";

const screenSize2: any = "1150px";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	margin-bottom: 8px;
`;
const EventImage = styled.img`
	height: 109px;
	width: 109px;
	background-color: ${Color.background.soft};
	border-radius: 12px;
	object-fit: cover;
	${media.lessThan(screenSize2)`
        display: none;
    `}
`;

const Resume = (props: Props) => {
	return (
		<ResumeStyled>
			<Container>
				<Row style={{ justifyContent: "space-between", marginBottom: 24 }}>
					<Text type="h5" weight="semibold">
						{props.title}
					</Text>
					<EventImage src={props.image} />
				</Row>
				<Row>
					<Calendar
						height={20}
						width={20}
						color={ColorV2.text.neutralHard}
					/>
					<Text type="p2">
						{moment(props.startDate).format("ddd DD MMMM HH:mm")} -{" "}
						{moment(props.endDate).format("HH:mm")}
					</Text>
				</Row>
				<Row>
					<MapPin
						height={20}
						width={20}
						color={ColorV2.text.neutralHard}
					/>
					<Text type="p2">{props.address}</Text>
				</Row>
			</Container>
		</ResumeStyled>
	);
};
export default Resume;
export interface Props {
	title: string;
	price: number;
	startDate?: string;
	endDate?: string;
	address: string;
	image: string;
	corporate?: boolean;
	onClick?: () => void;
}
