import { ColorV2, Text } from "@adoptaunabuelo/react-components";
import SectionContainer from "../../components/container/SectionContainer";
import styled from "styled-components";
import media from "styled-media-query";
import { Heart } from "lucide-react";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
`;

const Success = (props: Props) => {
    return (
        <SectionContainer>
            <Container>
				<Heart
					height={48}
					width={48}
					fill={ColorV2.text.primary}
					color={"transparent"}
				/>
				<Text type="h3" weight="semibold" style={{ marginTop: 12 }}>
					¡Gracias por tu ayuda!
				</Text>
				<Text
					type="p"
					style={{
						marginTop: 12,
						marginBottom: 12,
						textAlign: "center",
					}}
				>
					Hemos actualizado tu perfil con éxito.
				</Text>
			</Container>
        </SectionContainer>
    )
};
export default Success;
export interface Props {}
