import { useState, useEffect } from "react";
import styled from "styled-components";

import LeftSectionContainer from "../container/LeftSectionContainer";
import { CellSelector, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { UserScheme, User } from "client-v2";
import { State } from "../../redux";
import { useSelector } from "react-redux";
import { Shuffle } from "lucide-react";

const Icon = styled.img`
	height: 24px;
	width: 24px;
`;

const GenderStep = (props: GenderStepProps) => {
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const colors = {
		buttonBackground: ColorV2.surface.primaryHard,
		buttonColor: "white",
		animation: ["#00315C", "#005AA8"],
	};

	const [badInput, setBadInput] = useState(false);
	const [gender, setGender] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const onGenderChange = (item: any) => {
		setGender(item.id);
		setBadInput(false);
	};

	const onNextClick = () => {
		if (gender) {
			setLoading(true);
			User.set(props.user.objectId, {
				gender: gender,
			})
				.then((result) => {
					setLoading(false);
					props.onFinish({ data: result.data });
				})
				.catch((error) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		} else {
			setBadInput(true);
		}
	};

	return (
		<LeftSectionContainer
			title="Tu género"
			subtitle="¡Queremos conocerte mejor! Esto nos ayudará a conectarte con tu nuevo abuelo/a"
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: colors.buttonBackground,
					color: colors.buttonColor,
				},
				loading: loading,
				children: translation.component_button_next,
				onClick: onNextClick,
			}}
		>
			<CellSelector
				style={{}}
				options={[
					{
						id: "male",
						title: "Hombre",
						icon: (
							<Icon
								src={require("../../assets/images/icon/Man.png")}
							/>
						),
					},
					{
						id: "female",
						title: "Mujer",
						icon: (
							<Icon
								src={require("../../assets/images/icon/Woman.png")}
							/>
						),
					},
					{
						id: "none",
						title: "Otro",
						icon: <Shuffle />,
					},
				]}
				onClick={onGenderChange}
			/>
			{badInput && (
				<Text
					type="p"
					style={{
						marginTop: 12,
						color: ColorV2.text.redHard,
						fontSize: 14,
					}}
				>
					Debes seleccionar al menos una de las opciones.
				</Text>
			)}
		</LeftSectionContainer>
	);
};
export default GenderStep;
export interface GenderStepProps {
	user: UserScheme;
	animated?: boolean;
	onFinish: (result: { data?: UserScheme; error?: string }) => void;
}
