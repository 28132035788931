import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChallengeScheme, Track, ProductScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import ResumeCheckout from "../challenge/section/ResumeCheckout";
import Success from "./section/Success";
import SkipModal from "../../components/modal/SkipPaymentModal";
import WizardContainer, {
	WizardContainerRef,
} from "../../components/container/WizardContainer";
import PaymentMethod from "../../components/section/donation/PaymentMethod";
import Price from "../../components/section/donation/Price";
import SuccessRight from "../../components/section/SuccessCrossSell";
import Signature from "../../components/section/Signature";
import PriceLeft from "../../components/section/donation/PriceInfo";
import { ColorV2 } from "@adoptaunabuelo/react-components";

const SkipDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${ColorV2.border.neutralHigh};
	font-family: "Poppins";
	font-size: 14px;
	cursor: pointer;
	gap: 4px;
	:hover {
		text-decoration: underline;
	}
`;

const Checkout = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const isMobile: boolean = useSelector(
		(state: State) => state.screen.isMobile
	);
	const currentUser = useSelector((state: State) => state.user.currentUser);
	const translation = useSelector(
		(state: State) => state.location.translation
	);

	const wizard = useRef<WizardContainerRef>(null);

	const view = "signature";

	const [challenge, setChallenge] = useState<ChallengeScheme | undefined>(
		undefined
	);
	const [didSubscribe, setDidSubscribe] = useState(false);
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [completed, setCompleted] = useState(false);
	const [showSkipModal, setShowSkipModal] = useState(false);

	useEffect(() => {
		//init
		if (location.state && location.state.challenge) {
			setChallenge(location.state.challenge);
			const restAmount =
				location.state.challenge.goal - location.state.challenge.amount;
			if (restAmount <= 0) setCompleted(true);
		} else {
			navigate("/");
		}
	}, []);

	const renderSkipDonationButton = () => (
		<SkipDiv
			onClick={() => {
				setShowSkipModal(true);

				//Track on Mixpanel
				Track.event({
					event: "skip donation modal show",
					user: currentUser,
					params: {
						view: view,
					},
				});
			}}
		>
			{translation.component_modal_skip_payment_button_skip}
		</SkipDiv>
	);

	return (
		<>
			<SkipModal
				isVisible={showSkipModal}
				type={isMobile ? "full-screen" : "default"}
				video={
					"https://parsefiles-spain.back4app.com/QY5gVZmeai8ug19nHGA9AoFmuPU26k04tGJeV1wT/a49c9c56caa6ddd4b1502f2f14fce17b_welcome_01-mp4.mp4"
				}
				text={translation.component_modal_skip_payment_text}
				onVideoEnded={() =>{
					Track.event({
						event: "donation video finish",
						user: currentUser,
						params: {
							view: view,
						},
					});
				}}
				onClick={() => {
					Track.event({
						event: "skip donation modal click",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
				}}
				onClose={() => {
					Track.event({
						event: "skip donation modal close",
						user: currentUser,
						params: {
							view: view,
						},
					});
					setShowSkipModal(false);
					wizard.current?.goNext(2);
				}}
			/>
			<WizardContainer
				ref={wizard}
				views={[
					//Signature
					{
						leftView: currentUser && challenge && (
							<Signature
								animated={true}
								view={view}
								user={currentUser}
								challenge={challenge}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightView: challenge && (
							<ResumeCheckout
								challenge={challenge}
								amount={
									location.state && location.state.supporters
										? location.state.supporters
										: 0
								}
							/>
						),
						rightViewStyle: {
							display: isMobile ? "none" : "flex",
						},
					},
					//Price
					{
						navigationRightView: isMobile
							? renderSkipDonationButton()
							: undefined,
						containerStyle: { overflowY: "scroll" },
						leftView: challenge && (
							<Price
								animated={true}
								challenge={challenge}
								view={view}
								defaultGrowthbook={{
									title: `Gracias por firmar ${currentUser?.name}. ¿Podrías colaborar con algo más?`,
									title_en: `Thanks for signing ${currentUser?.name}. Could you help with anything else?`,
									subtitle:
										"Estás regalando {{data}} horas de compañía a una persona mayor",
									subtitle_en:
										"You are giving away {{data}} hours of company to a grandpa",
									type_to_eur: 0.602,
								}}
								options={["monthly", "annually", "unique"]}
								onSkipClick={() => setShowSkipModal(true)}
								onFinish={(result) => {
									if (result.error) {
										setShowError({
											show: true,
											message: result.error,
										});
									} else if (result.data) {
										setProduct(result.data);
										wizard.current?.goNext();
									}
								}}
							/>
						),
						rightViewStyle: { overflowY: "scroll" },
						rightView: isMobile ? null : <PriceLeft />,
					},
					//Select payment method
					{
						leftViewStyle: { alignItems: "center" },
						leftView:
							product && currentUser && challenge ? (
								<PaymentMethod
									animated={true}
									challenge={challenge}
									product={product}
									user={currentUser}
									view={view}
									onFinish={(result) => {
										if (result.error) {
											setShowError({
												show: true,
												message: result.error,
											});
										} else if (result.data) {
											setDidSubscribe(true);
											wizard.current?.goNext();
										}
									}}
								/>
							) : null,
						rightView: null,
					},
					//Success view
					{
						containerStyle: { overflowY: "scroll" },
						leftView: currentUser ? (
							<>
								<Success
									animated={true}
									user={currentUser}
									didSubscribe={didSubscribe}
								>
									{isMobile ? (
										<SuccessRight animated={true} />
									) : null}
								</Success>
							</>
						) : null,
						rightViewStyle: { position: "unset" },
						rightView: !isMobile ? (
							<SuccessRight animated={true} />
						) : null,
						hideBackButton: true,
					},
				]}
			/>
		</>
	);
};
export default Checkout;
export interface Props {}
