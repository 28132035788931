import { useEffect, useState } from "react";
import { Product, ProductScheme, Subscription } from "client-v2";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { bindActionCreators } from "redux";

import Checkout from "../views/sb/Checkout";

import Loading from "../components/modal/Loading";
import { Feedback } from "@adoptaunabuelo/react-components";

const FeedbackStyled = styled(Feedback)`
	right: 24px !important;
	left: unset !important;
	${media.lessThan("small")`
        top: 64px;
        left: 24px !important;
        bottom: unset !important;
	`}
`;

const ChallengeLayout = (props: PayoutLayoutProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const error = useSelector((state: State) => state.modals.error);

	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [loading, setLoading] = useState(false);
	const [adminId, setAdminId] = useState<string | undefined>(undefined);

	useEffect(() => {
		const productId = searchParams.get("p");
		const adminId = searchParams.get("a");
		if (adminId) setAdminId(adminId);
		if (productId) {
			setLoading(true);
			Product.getById(productId)
				.then((result) => {
					setProduct(result.data);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					setShowError({
						show: true,
						message: "El parámetro id no es válido",
					});
				});
		}
	}, []);

	return (
		<>
			<FeedbackStyled
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<Loading isVisible={loading ? true : false} />
			<Routes>
				<Route
					path="/"
					element={<Checkout product={product} adminId={adminId} />}
				/>
			</Routes>
		</>
	);
};

export default ChallengeLayout;
export interface PayoutLayoutProps {}
