import styled from "styled-components";
import { UserScheme } from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { Color, Text } from "@adoptaunabuelo/react-components";
import Relief from "../../../components/section/donation/Relief";
import { Heart } from "lucide-react";
import LeftSectionContainer from "../../../components/container/LeftSectionContainer";

const DataView = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Success = (props: Props) => {
	const appLocation = useSelector((state: State) => state.location.location);

	return (
		<LeftSectionContainer animated={props.animated} style={{paddingTop: 0}}>
			<DataView>
				<Heart
					fill={Color.background.primary}
					color={"transparent"}
					style={{ marginBottom: 24, marginTop: 24 }}
					height={48}
					width={48}
					strokeWidth={1}
				/>
				<Text
					type="h3"
					weight="semibold"
					style={{ textAlign: "center" }}
				>
					¡Gracias por tu donación, {props.user?.name}!
				</Text>
				<Text type="p" style={{ marginTop: 12, textAlign: "center" }}>
					Con tu ayuda y la de otras muchas personas podremos acabar
					con la soledad de nuestros abuelos. ¡Sigamos así!
				</Text>
				{appLocation &&
					appLocation.country_code === "ES" &&
					props.user &&
					!props.user.idCard &&
					!props.user.zipCode && (
						<Relief style={{ marginTop: 32 }} user={props.user} />
					)}
				{props.children}
			</DataView>
		</LeftSectionContainer>
	);
};
export default Success;
export interface Props {
	children?: React.ReactNode;
	animated?: boolean;
	user?: UserScheme;
	onNextClick?: () => void;
}
