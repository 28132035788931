import styled from "styled-components";
import { ErrorScheme, Product, ProductScheme, UserScheme } from "client-v2";

import { Color, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { State } from "../../redux";
import { useState } from "react";
import LeftSectionContainer from "../container/LeftSectionContainer";

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const CellSeparator = styled.div`
	display: flex;
	flex: 1;
	border-bottom: 1px solid ${Color.line.soft};
	margin: 0px 8px 4px;
`;

const Ticket = (props: Props) => {
	const isMobile = useSelector((state: State) => state.screen.isMobile);
	const translation = useSelector(
		(state: State) => state.location.translation
	);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const onNextClick = () => {
		if (props.product.price > 0) {
			props.onFinish({ data: 1 });
		} else {
			setLoading(true);
			Product.initRegister(props.product.objectId, {
				userId: props.user.objectId,
			})
				.then((result) => {
					setLoading(false);
					setSuccess(true);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					props.onFinish({ error: error.message });
				});
		}
	};

	return (
		<LeftSectionContainer
			title="Reserva tu plaza"
			animated={props.animated}
			buttonProps={{
				style: {
					backgroundColor: ColorV2.surface.primaryHard,
					width: isMobile ? "100%" : "fit-content",
				},
				children: translation.component_button_next,
				loading: loading,
				success: success,
				onSuccess: () => props.onFinish({ data: 2 }),
				onClick: onNextClick,
			}}
		>
			<Row>
				<Text type="p2" style={{ color: Color.text.high }}>
					x1 Entrada
				</Text>
				<CellSeparator />
				<Text type="p2" style={{ color: Color.text.high }}>
					{props.product.price.toFixed(2)} €
				</Text>
			</Row>
			<Row style={{ marginTop: 8 }}>
				<Text type="p2" style={{ color: Color.text.high }}>
					Impuestos
				</Text>
				<CellSeparator />
				<Text type="p2" style={{ color: Color.text.high }}>
					0.00 €
				</Text>
			</Row>
			<Row style={{ marginTop: 18 }}>
				<Text type="h6" weight="medium">
					Total
				</Text>
				<CellSeparator style={{ border: "none" }} />
				<Text type="h5" weight="medium">
					{props.product.price !== 0
						? props.product.price.toFixed(2) + " €"
						: "Gratis"}
				</Text>
			</Row>
		</LeftSectionContainer>
	);
};
export default Ticket;
export interface Props {
	product: ProductScheme;
	user: UserScheme;
	animated?: boolean;
	onFinish: (result: { data?: number; error?: string }) => void;
}
