import { Route, Routes } from "react-router-dom";

import Profile from "../views/profile/ProfileWizard";
import { Feedback } from "@adoptaunabuelo/react-components";
import { useSelector } from "react-redux";
import { actionsCreators, State } from "../redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

const ProfileLayout = (props: ProfileLayoutProps) => {
	const dispatch = useDispatch();
	const { setShowError } = bindActionCreators(actionsCreators, dispatch);
	const error = useSelector((state: State) => state.modals.error);
	return (
		<>
			<Feedback
				isVisible={error.show}
				type="error"
				text={error.message}
				onClose={() => setShowError({ show: false })}
			/>
			<Routes>
				<Route path="/" element={<Profile />} />
			</Routes>
		</>
	);
};

export default ProfileLayout;
export interface ProfileLayoutProps {}
